export const mapFiltersOptions = {
  "connectors": [
    "CCS",
    "CHADEMO",
    "TYPE_1",
    "TYPE_2",
    "OTHER"
  ],
  "capabilities": [
    "CARD",
    "EJN_APP",
    "EJN_APP_NEVER",
    "PLUG_AND_CHARGE",
    "CONTACTLESS",
    "FREE",
    "OPERATOR_APP",
    "QR_CODE",
    "RFID",
    "TESLA_CAR",
    "WEB"
  ],
  "operators": [
    {
      "pk": 22371,
      "name": "50five",
      "isEjn": true
    },
    {
      "pk": 24250,
      "name": "50five BE Exploitatie",
      "isEjn": true
    },
    {
      "pk": 24632,
      "name": "50five by ENGIE",
      "isEjn": true
    },
    {
      "pk": 24907,
      "name": "50five UK",
      "isEjn": true
    },
    {
      "pk": 18095,
      "name": "800 Volt Technologies GmbH",
      "isEjn": true
    },
    {
      "pk": 3730,
      "name": "A2A E-mobility S.r.l.",
      "isEjn": true
    },
    {
      "pk": 3389,
      "name": "Abel&Co",
      "isEjn": true
    },
    {
      "pk": 26302,
      "name": "Acciona Recarga S.L.",
      "isEjn": true
    },
    {
      "pk": 3715,
      "name": "Acea Innovation S.r.l.",
      "isEjn": true
    },
    {
      "pk": 4178,
      "name": "Advance Soluciones De Negocio",
      "isEjn": true
    },
    {
      "pk": 3540,
      "name": "Aéroports de Paris",
      "isEjn": true
    },
    {
      "pk": 25459,
      "name": "Aerovolt",
      "isEjn": true
    },
    {
      "pk": 25867,
      "name": "AggerEnergie GmbH",
      "isEjn": true
    },
    {
      "pk": 36843,
      "name": "Agriopale Services",
      "isEjn": true
    },
    {
      "pk": 3839,
      "name": "Agrisnellaad",
      "isEjn": true
    },
    {
      "pk": 14835,
      "name": "AGSM",
      "isEjn": true
    },
    {
      "pk": 23337,
      "name": "Aldi Nord",
      "isEjn": true
    },
    {
      "pk": 26174,
      "name": "Alewijnse",
      "isEjn": true
    },
    {
      "pk": 25859,
      "name": "Alexander Bürkle GmbH & Co. KG",
      "isEjn": true
    },
    {
      "pk": 3187,
      "name": "Alexela",
      "isEjn": true
    },
    {
      "pk": 30691,
      "name": "Alfa Energia",
      "isEjn": true
    },
    {
      "pk": 652,
      "name": "Alfa Power",
      "isEjn": true
    },
    {
      "pk": 3155,
      "name": "Alizé Liberté",
      "isEjn": true
    },
    {
      "pk": 2,
      "name": "Allego",
      "isEjn": true
    },
    {
      "pk": 20051,
      "name": "Allenergies",
      "isEjn": true
    },
    {
      "pk": 21597,
      "name": "Allgäuer Kraftwerke GmbH",
      "isEjn": true
    },
    {
      "pk": 21596,
      "name": "Allgäuer Überlandwerk GmbH",
      "isEjn": true
    },
    {
      "pk": 41309,
      "name": "Altensys",
      "isEjn": true
    },
    {
      "pk": 35182,
      "name": "Alva Charging Services",
      "isEjn": true
    },
    {
      "pk": 3244,
      "name": "AMAG Import AG",
      "isEjn": true
    },
    {
      "pk": 14988,
      "name": "Ampera",
      "isEjn": true
    },
    {
      "pk": 22221,
      "name": "ANYOS - Securecharge",
      "isEjn": true
    },
    {
      "pk": 32405,
      "name": "APCOA Parking",
      "isEjn": true
    },
    {
      "pk": 19096,
      "name": "Applegreen Electric",
      "isEjn": true
    },
    {
      "pk": 26828,
      "name": "Apro Kassensysteme GmbH",
      "isEjn": true
    },
    {
      "pk": 12953,
      "name": "Aral Pulse",
      "isEjn": true
    },
    {
      "pk": 13093,
      "name": "Atlante",
      "isEjn": true
    },
    {
      "pk": 19460,
      "name": "Atomis Hotel Dachau GmbH & Co. KG",
      "isEjn": true
    },
    {
      "pk": 25872,
      "name": "AUDI Deutschland",
      "isEjn": true
    },
    {
      "pk": 2081,
      "name": "AUDI Hungaria",
      "isEjn": true
    },
    {
      "pk": 26085,
      "name": "AUTEL EUROPE GmbH",
      "isEjn": true
    },
    {
      "pk": 11294,
      "name": "Autobahn Tank & Rast GmbH",
      "isEjn": true
    },
    {
      "pk": 2620,
      "name": "Autorecharge",
      "isEjn": true
    },
    {
      "pk": 23819,
      "name": "autoSense AG",
      "isEjn": true
    },
    {
      "pk": 2390,
      "name": "Autostrom Einert GmbH & Co. KG.",
      "isEjn": true
    },
    {
      "pk": 3696,
      "name": "avency GmbH",
      "isEjn": true
    },
    {
      "pk": 3702,
      "name": "AVIA Picoty",
      "isEjn": true
    },
    {
      "pk": 3544,
      "name": "AVIA Plus GmbH & Co. KG",
      "isEjn": true
    },
    {
      "pk": 34661,
      "name": "AVIA Steil",
      "isEjn": true
    },
    {
      "pk": 3474,
      "name": "AVIA Thevenin Ducrot",
      "isEjn": true
    },
    {
      "pk": 27868,
      "name": "AVIA Volt",
      "isEjn": true
    },
    {
      "pk": 18749,
      "name": "AVIA VOLT Suisse AG",
      "isEjn": true
    },
    {
      "pk": 13269,
      "name": "AVP E-Mobility",
      "isEjn": true
    },
    {
      "pk": 15495,
      "name": "AW Energy",
      "isEjn": true
    },
    {
      "pk": 3663,
      "name": "Axpo Energy Solutions Italia S.p.A.",
      "isEjn": true
    },
    {
      "pk": 35189,
      "name": "Azienda Dimostrativa",
      "isEjn": true
    },
    {
      "pk": 22813,
      "name": "Backcharge GmbH (Schmees)",
      "isEjn": true
    },
    {
      "pk": 4106,
      "name": "badenova",
      "isEjn": true
    },
    {
      "pk": 26172,
      "name": "BAM",
      "isEjn": true
    },
    {
      "pk": 35178,
      "name": "Batenburg",
      "isEjn": true
    },
    {
      "pk": 17348,
      "name": "bayernwerk e-mobil",
      "isEjn": true
    },
    {
      "pk": 16967,
      "name": "BayWa AG",
      "isEjn": true
    },
    {
      "pk": 1278,
      "name": "Be Cablé",
      "isEjn": true
    },
    {
      "pk": 3693,
      "name": "Be Charge",
      "isEjn": true
    },
    {
      "pk": 14375,
      "name": "Be Green Mobility",
      "isEjn": true
    },
    {
      "pk": 19063,
      "name": "BE Solution GmbH",
      "isEjn": true
    },
    {
      "pk": 26504,
      "name": "Be United",
      "isEjn": true
    },
    {
      "pk": 7160,
      "name": "BE Vertrieb GmbH & Co KG",
      "isEjn": true
    },
    {
      "pk": 12854,
      "name": "Be.EV",
      "isEjn": true
    },
    {
      "pk": 3539,
      "name": "BEC Construction Champagne",
      "isEjn": true
    },
    {
      "pk": 26173,
      "name": "Beev",
      "isEjn": true
    },
    {
      "pk": 3311,
      "name": "BELIB",
      "isEjn": true
    },
    {
      "pk": 781,
      "name": "Believ",
      "isEjn": true
    },
    {
      "pk": 24874,
      "name": "Berliner Stadtwerke GmbH",
      "isEjn": true
    },
    {
      "pk": 3938,
      "name": "Berner Elektrotechnik GmbH",
      "isEjn": true
    },
    {
      "pk": 3126,
      "name": "Bidirex GmbH",
      "isEjn": true
    },
    {
      "pk": 25854,
      "name": "BIGGE ENERGIE GmbH & Co. KG",
      "isEjn": true
    },
    {
      "pk": 19130,
      "name": "Billund Kommune",
      "isEjn": true
    },
    {
      "pk": 27688,
      "name": "Bio Company SE",
      "isEjn": true
    },
    {
      "pk": 25863,
      "name": "BKW Smart Energy & Mobility AG",
      "isEjn": true
    },
    {
      "pk": 19459,
      "name": "Black Falcon Betriebs-GmbH",
      "isEjn": true
    },
    {
      "pk": 1662,
      "name": "Blink Charging",
      "isEjn": true
    },
    {
      "pk": 28210,
      "name": "Blue Charge",
      "isEjn": true
    },
    {
      "pk": 71,
      "name": "Blue Corner",
      "isEjn": true
    },
    {
      "pk": 30828,
      "name": "Bluint - Engenharia e Tecnologias Integradas, Unipessoal, Lda",
      "isEjn": true
    },
    {
      "pk": 3435,
      "name": "Blulinc",
      "isEjn": true
    },
    {
      "pk": 25849,
      "name": "Bocholter Energie- und Wasserversorgung GmbH",
      "isEjn": true
    },
    {
      "pk": 4289,
      "name": "Boostcharge",
      "isEjn": true
    },
    {
      "pk": 24627,
      "name": "Borne-Elec.fr",
      "isEjn": true
    },
    {
      "pk": 3492,
      "name": "BornEco",
      "isEjn": true
    },
    {
      "pk": 3408,
      "name": "Bornes Solutions",
      "isEjn": true
    },
    {
      "pk": 18992,
      "name": "Bornevo Connect",
      "isEjn": true
    },
    {
      "pk": 3648,
      "name": "Bosch Charging Solutions GmbH",
      "isEjn": true
    },
    {
      "pk": 34241,
      "name": "BP Pulse France",
      "isEjn": true
    },
    {
      "pk": 3243,
      "name": "BP Pulse Germany",
      "isEjn": true
    },
    {
      "pk": 25321,
      "name": "BP Pulse Netherlands",
      "isEjn": true
    },
    {
      "pk": 34240,
      "name": "BP Pulse Poland",
      "isEjn": true
    },
    {
      "pk": 28236,
      "name": "BP Pulse Portugal",
      "isEjn": true
    },
    {
      "pk": 25848,
      "name": "Braunschweiger Versorgungs-Aktiengesellschaft & Co. KG",
      "isEjn": true
    },
    {
      "pk": 40397,
      "name": "Bremis Energy GmbH",
      "isEjn": true
    },
    {
      "pk": 3414,
      "name": "Bump Charge",
      "isEjn": true
    },
    {
      "pk": 3692,
      "name": "Burgenland Energie",
      "isEjn": true
    },
    {
      "pk": 36456,
      "name": "BV Smart parking Solutions",
      "isEjn": true
    },
    {
      "pk": 12959,
      "name": "ByWatt",
      "isEjn": true
    },
    {
      "pk": 20188,
      "name": "C4Energies",
      "isEjn": true
    },
    {
      "pk": 28109,
      "name": "Cable Exanergia Portugal",
      "isEjn": true
    },
    {
      "pk": 25857,
      "name": "cablex AG",
      "isEjn": true
    },
    {
      "pk": 4098,
      "name": "Calix",
      "isEjn": true
    },
    {
      "pk": 3387,
      "name": "Camping du Lac",
      "isEjn": true
    },
    {
      "pk": 15209,
      "name": "Canary Central Apartments",
      "isEjn": true
    },
    {
      "pk": 22847,
      "name": "Cap'bornes",
      "isEjn": true
    },
    {
      "pk": 28162,
      "name": "Capwatt Services",
      "isEjn": true
    },
    {
      "pk": 3409,
      "name": "Car2Plug",
      "isEjn": true
    },
    {
      "pk": 1121,
      "name": "CARF - Bornes Publiques",
      "isEjn": true
    },
    {
      "pk": 28194,
      "name": "Cargga Inteligente",
      "isEjn": true
    },
    {
      "pk": 13830,
      "name": "Carra (Ireland) Limited",
      "isEjn": true
    },
    {
      "pk": 15819,
      "name": "Carrefour Energies",
      "isEjn": true
    },
    {
      "pk": 35192,
      "name": "Carrefour Market Cugnaux",
      "isEjn": true
    },
    {
      "pk": 3419,
      "name": "Carrefour Property Network",
      "isEjn": true
    },
    {
      "pk": 28192,
      "name": "Cascais Proxima",
      "isEjn": true
    },
    {
      "pk": 26176,
      "name": "CEC mobility",
      "isEjn": true
    },
    {
      "pk": 4100,
      "name": "CenEnergy",
      "isEjn": true
    },
    {
      "pk": 13274,
      "name": "Censo",
      "isEjn": true
    },
    {
      "pk": 28164,
      "name": "Cepsa",
      "isEjn": true
    },
    {
      "pk": 3899,
      "name": "Certipower",
      "isEjn": true
    },
    {
      "pk": 19740,
      "name": "CEZ, a. s.",
      "isEjn": true
    },
    {
      "pk": 36233,
      "name": "Charge at Friends",
      "isEjn": true
    },
    {
      "pk": 3473,
      "name": "Charge Construct GmbH",
      "isEjn": true
    },
    {
      "pk": 3699,
      "name": "Charge My Street",
      "isEjn": true
    },
    {
      "pk": 35175,
      "name": "Charge-R8",
      "isEjn": true
    },
    {
      "pk": 13232,
      "name": "Charge&Go",
      "isEjn": true
    },
    {
      "pk": 15961,
      "name": "chargecloud GmbH",
      "isEjn": true
    },
    {
      "pk": 7984,
      "name": "Chargee eMobility a.s.",
      "isEjn": true
    },
    {
      "pk": 3254,
      "name": "ChargeGuru",
      "isEjn": true
    },
    {
      "pk": 30678,
      "name": "ChargeHere GmbH",
      "isEjn": true
    },
    {
      "pk": 27758,
      "name": "ChargeIn Poland",
      "isEjn": true
    },
    {
      "pk": 3685,
      "name": "chargeIQ GmbH",
      "isEjn": true
    },
    {
      "pk": 20235,
      "name": "Chargemaker GmbH",
      "isEjn": true
    },
    {
      "pk": 660,
      "name": "ChargeOne (Claus Heinemann Elektroanlagen GmbH)",
      "isEjn": true
    },
    {
      "pk": 26166,
      "name": "Chargepark",
      "isEjn": true
    },
    {
      "pk": 7554,
      "name": "ChargePlace Scotland",
      "isEjn": true
    },
    {
      "pk": 26157,
      "name": "Chargepoint",
      "isEjn": true
    },
    {
      "pk": 16321,
      "name": "ChargePoint",
      "isEjn": true
    },
    {
      "pk": 23125,
      "name": "ChargePoint Austria GmbH",
      "isEjn": true
    },
    {
      "pk": 3442,
      "name": "Chargepoly",
      "isEjn": true
    },
    {
      "pk": 3706,
      "name": "ChargeUp Services CZ s.r.o.",
      "isEjn": true
    },
    {
      "pk": 27942,
      "name": "Chargewell – Vlaamse Overheid",
      "isEjn": true
    },
    {
      "pk": 3392,
      "name": "Chargewell Austria",
      "isEjn": true
    },
    {
      "pk": 3349,
      "name": "Chargewell Netherlands",
      "isEjn": true
    },
    {
      "pk": 26185,
      "name": "Charginev",
      "isEjn": true
    },
    {
      "pk": 3463,
      "name": "Chargy",
      "isEjn": true
    },
    {
      "pk": 4119,
      "name": "CHM Mobility",
      "isEjn": true
    },
    {
      "pk": 28217,
      "name": "Circuitos de Inovação",
      "isEjn": true
    },
    {
      "pk": 3186,
      "name": "CITEOS",
      "isEjn": true
    },
    {
      "pk": 2189,
      "name": "City Concept E-Ladestationen GmbH",
      "isEjn": true
    },
    {
      "pk": 3714,
      "name": "CityCharging B.V.",
      "isEjn": true
    },
    {
      "pk": 13006,
      "name": "Citywatt GmbH",
      "isEjn": true
    },
    {
      "pk": 756,
      "name": "Clem'",
      "isEjn": true
    },
    {
      "pk": 21025,
      "name": "Clenergy EV",
      "isEjn": true
    },
    {
      "pk": 3620,
      "name": "Clever A/S",
      "isEjn": true
    },
    {
      "pk": 3742,
      "name": "CleverPower a.s.",
      "isEjn": true
    },
    {
      "pk": 4278,
      "name": "CME",
      "isEjn": true
    },
    {
      "pk": 24175,
      "name": "Collectric",
      "isEjn": true
    },
    {
      "pk": 2435,
      "name": "Columbus Energy S.A.",
      "isEjn": true
    },
    {
      "pk": 931,
      "name": "Comfort Charge",
      "isEjn": true
    },
    {
      "pk": 3406,
      "name": "Communauté de Communes Vallée des Baux-Alpilles",
      "isEjn": true
    },
    {
      "pk": 3579,
      "name": "Communauté de la Riviera Française",
      "isEjn": true
    },
    {
      "pk": 34969,
      "name": "Community by Shell Recharge",
      "isEjn": true
    },
    {
      "pk": 35183,
      "name": "Company",
      "isEjn": true
    },
    {
      "pk": 35869,
      "name": "Company Reseller",
      "isEjn": true
    },
    {
      "pk": 3739,
      "name": "Compleo Charging Technologies GmbH",
      "isEjn": true
    },
    {
      "pk": 697,
      "name": "Connected Kerb",
      "isEjn": true
    },
    {
      "pk": 40,
      "name": "ConnectNed",
      "isEjn": true
    },
    {
      "pk": 19283,
      "name": "COOP Supermarket",
      "isEjn": true
    },
    {
      "pk": 3651,
      "name": "Cooperatie AVIA Nederland U.A.",
      "isEjn": true
    },
    {
      "pk": 28184,
      "name": "Cooperativa Elétrica de Vale d´Este",
      "isEjn": true
    },
    {
      "pk": 35597,
      "name": "CoopStroom",
      "isEjn": true
    },
    {
      "pk": 26181,
      "name": "COPERNIC GO",
      "isEjn": true
    },
    {
      "pk": 12941,
      "name": "CPO Italia",
      "isEjn": true
    },
    {
      "pk": 23817,
      "name": "CUBOS Service GmbH",
      "isEjn": true
    },
    {
      "pk": 3390,
      "name": "CutPower",
      "isEjn": true
    },
    {
      "pk": 3133,
      "name": "da emobil GmbH & Co KG",
      "isEjn": true
    },
    {
      "pk": 3809,
      "name": "DAEN Mobility",
      "isEjn": true
    },
    {
      "pk": 4030,
      "name": "DATS 24",
      "isEjn": true
    },
    {
      "pk": 28913,
      "name": "De Groene Baron",
      "isEjn": true
    },
    {
      "pk": 4293,
      "name": "Decat",
      "isEjn": true
    },
    {
      "pk": 28879,
      "name": "Deconinck Mobility",
      "isEjn": true
    },
    {
      "pk": 29191,
      "name": "Demo Provider",
      "isEjn": true
    },
    {
      "pk": 17918,
      "name": "Den Hartog Charging B.V.",
      "isEjn": true
    },
    {
      "pk": 22571,
      "name": "Dessauer Stromversorgung",
      "isEjn": true
    },
    {
      "pk": 3844,
      "name": "Deurloo Elektro",
      "isEjn": true
    },
    {
      "pk": 4265,
      "name": "Diego Luxembourg",
      "isEjn": true
    },
    {
      "pk": 33459,
      "name": "Distrifrio Supermercados, Lda",
      "isEjn": true
    },
    {
      "pk": 28810,
      "name": "DKV Mobility Services",
      "isEjn": true
    },
    {
      "pk": 21595,
      "name": "Dortmunder Energie- und Wasserversorgung GmbH",
      "isEjn": true
    },
    {
      "pk": 28232,
      "name": "Douromobe – Comercializadora de Energia, S.A.",
      "isEjn": true
    },
    {
      "pk": 782,
      "name": "Dragon Charging",
      "isEjn": true
    },
    {
      "pk": 12952,
      "name": "Dream Energy",
      "isEjn": true
    },
    {
      "pk": 35186,
      "name": "Driessen",
      "isEjn": true
    },
    {
      "pk": 19282,
      "name": "DRIVECO",
      "isEjn": true
    },
    {
      "pk": 3711,
      "name": "drivee ApS",
      "isEjn": true
    },
    {
      "pk": 3558,
      "name": "DropNPlug",
      "isEjn": true
    },
    {
      "pk": 31101,
      "name": "DTE, Instalacoes Especiais",
      "isEjn": true
    },
    {
      "pk": 23266,
      "name": "Duferco Mobility Srl",
      "isEjn": true
    },
    {
      "pk": 4004,
      "name": "Dutch Charge",
      "isEjn": true
    },
    {
      "pk": 26175,
      "name": "Dynaf (USD)",
      "isEjn": true
    },
    {
      "pk": 3437,
      "name": "e-buddie",
      "isEjn": true
    },
    {
      "pk": 24284,
      "name": "e-con AG",
      "isEjn": true
    },
    {
      "pk": 18679,
      "name": "e-jin",
      "isEjn": true
    },
    {
      "pk": 18750,
      "name": "e-laden Tirol GmbH",
      "isEjn": true
    },
    {
      "pk": 22183,
      "name": "e-mobilio GmbH",
      "isEjn": true
    },
    {
      "pk": 26829,
      "name": "e-mops GmbH",
      "isEjn": true
    },
    {
      "pk": 13687,
      "name": "e-MOTION.world Gmbh",
      "isEjn": true
    },
    {
      "pk": 28177,
      "name": "e-Plug",
      "isEjn": true
    },
    {
      "pk": 25568,
      "name": "e-regio",
      "isEjn": true
    },
    {
      "pk": 4288,
      "name": "e-RS",
      "isEjn": true
    },
    {
      "pk": 3345,
      "name": "e-Totem",
      "isEjn": true
    },
    {
      "pk": 3538,
      "name": "e-Vadea",
      "isEjn": true
    },
    {
      "pk": 15475,
      "name": "E-Werk Mittelbaden",
      "isEjn": true
    },
    {
      "pk": 3353,
      "name": "E.Leclerc",
      "isEjn": true
    },
    {
      "pk": 19422,
      "name": "E.ON Drive Infrastructure GmbH",
      "isEjn": true
    },
    {
      "pk": 40322,
      "name": "E+DRIVE",
      "isEjn": true
    },
    {
      "pk": 3524,
      "name": "EA Energiearchitektur GmbH",
      "isEjn": true
    },
    {
      "pk": 19425,
      "name": "EAAZE GmbH",
      "isEjn": true
    },
    {
      "pk": 3426,
      "name": "Easy Charge",
      "isEjn": true
    },
    {
      "pk": 3749,
      "name": "Easy Volt Brasil",
      "isEjn": true
    },
    {
      "pk": 16216,
      "name": "Eaton Industries (Green Motion)",
      "isEjn": true
    },
    {
      "pk": 52,
      "name": "eCarUp AG",
      "isEjn": true
    },
    {
      "pk": 13763,
      "name": "ECdrive",
      "isEjn": true
    },
    {
      "pk": 3391,
      "name": "Eco-Pi",
      "isEjn": true
    },
    {
      "pk": 26171,
      "name": "Ecocharge",
      "isEjn": true
    },
    {
      "pk": 28231,
      "name": "Ecoinside",
      "isEjn": true
    },
    {
      "pk": 16925,
      "name": "econetiQ Public",
      "isEjn": true
    },
    {
      "pk": 1015,
      "name": "Ecotap",
      "isEjn": true
    },
    {
      "pk": 3667,
      "name": "EDEKA Versorgungsgesellschaft mbH",
      "isEjn": true
    },
    {
      "pk": 40578,
      "name": "Edison Plug & Go",
      "isEjn": true
    },
    {
      "pk": 705,
      "name": "EDP Comercial",
      "isEjn": true
    },
    {
      "pk": 3194,
      "name": "EFFIA Stationnement",
      "isEjn": true
    },
    {
      "pk": 3677,
      "name": "EG Retail - France",
      "isEjn": true
    },
    {
      "pk": 32986,
      "name": "EGT Energy Solutions",
      "isEjn": true
    },
    {
      "pk": 940,
      "name": "Eichsfelder Energie- und Wasserversorgungs-GmbH",
      "isEjn": true
    },
    {
      "pk": 19131,
      "name": "Einride",
      "isEjn": true
    },
    {
      "pk": 21671,
      "name": "eins energie in sachsen GmbH & Co. KG",
      "isEjn": true
    },
    {
      "pk": 22711,
      "name": "EKOEN Sp. z o.o.",
      "isEjn": true
    },
    {
      "pk": 26160,
      "name": "Elbizz",
      "isEjn": true
    },
    {
      "pk": 18062,
      "name": "Elchies Estates",
      "isEjn": true
    },
    {
      "pk": 21415,
      "name": "Elec2go",
      "isEjn": true
    },
    {
      "pk": 23614,
      "name": "Electra",
      "isEjn": true
    },
    {
      "pk": 18990,
      "name": "ElectraClear",
      "isEjn": true
    },
    {
      "pk": 3169,
      "name": "Electric 55 Charging",
      "isEjn": true
    },
    {
      "pk": 24419,
      "name": "Electric by D’Ieteren",
      "isEjn": true
    },
    {
      "pk": 26170,
      "name": "Electric Motorcycles",
      "isEjn": true
    },
    {
      "pk": 3833,
      "name": "Electricea",
      "isEjn": true
    },
    {
      "pk": 23263,
      "name": "Electriease srl",
      "isEjn": true
    },
    {
      "pk": 31400,
      "name": "Electrip Italy S.r.l.",
      "isEjn": true
    },
    {
      "pk": 21632,
      "name": "Electro-Mob",
      "isEjn": true
    },
    {
      "pk": 2625,
      "name": "Electromaps",
      "isEjn": true
    },
    {
      "pk": 3130,
      "name": "Elektro Keßler GmbH",
      "isEjn": true
    },
    {
      "pk": 26830,
      "name": "Elektro Ullmann",
      "isEjn": true
    },
    {
      "pk": 12795,
      "name": "Elektrum",
      "isEjn": true
    },
    {
      "pk": 3655,
      "name": "Elen",
      "isEjn": true
    },
    {
      "pk": 28148,
      "name": "Elergone Energia",
      "isEjn": true
    },
    {
      "pk": 28226,
      "name": "Eletec",
      "isEjn": true
    },
    {
      "pk": 35179,
      "name": "elexon",
      "isEjn": true
    },
    {
      "pk": 20187,
      "name": "eliso GmbH",
      "isEjn": true
    },
    {
      "pk": 3626,
      "name": "Elli",
      "isEjn": true
    },
    {
      "pk": 3703,
      "name": "Elocity Sp z o. o.",
      "isEjn": true
    },
    {
      "pk": 4049,
      "name": "EMACOM",
      "isEjn": true
    },
    {
      "pk": 1485,
      "name": "EMEL",
      "isEjn": true
    },
    {
      "pk": 17176,
      "name": "Emobil SNC d.o.o. (CPO)",
      "isEjn": true
    },
    {
      "pk": 19132,
      "name": "emobil.link",
      "isEjn": true
    },
    {
      "pk": 1713,
      "name": "Emobility - East Thomas Walther",
      "isEjn": true
    },
    {
      "pk": 25602,
      "name": "EMobility Netz",
      "isEjn": true
    },
    {
      "pk": 3635,
      "name": "EMOBITALY",
      "isEjn": true
    },
    {
      "pk": 28215,
      "name": "Emobtec",
      "isEjn": true
    },
    {
      "pk": 17344,
      "name": "emosS",
      "isEjn": true
    },
    {
      "pk": 26683,
      "name": "Emotion",
      "isEjn": true
    },
    {
      "pk": 30403,
      "name": "Emovili Fast Parent",
      "isEjn": true
    },
    {
      "pk": 3264,
      "name": "emyon",
      "isEjn": true
    },
    {
      "pk": 28235,
      "name": "ENAT Energias",
      "isEjn": true
    },
    {
      "pk": 3697,
      "name": "EnBW mobility+ AG & Co. KG",
      "isEjn": true
    },
    {
      "pk": 26831,
      "name": "EnBW ODR",
      "isEjn": true
    },
    {
      "pk": 21857,
      "name": "Eneco eMobility",
      "isEjn": true
    },
    {
      "pk": 3705,
      "name": "Enel X Way Italia",
      "isEjn": true
    },
    {
      "pk": 3658,
      "name": "enercity AG",
      "isEjn": true
    },
    {
      "pk": 3690,
      "name": "Energie AG OÖ Vertrieb GmbH",
      "isEjn": true
    },
    {
      "pk": 21669,
      "name": "Energie Calw GmbH",
      "isEjn": true
    },
    {
      "pk": 3429,
      "name": "Energie Eure-et-Loir",
      "isEjn": true
    },
    {
      "pk": 1261,
      "name": "Energie SaarLorLux",
      "isEjn": true
    },
    {
      "pk": 13230,
      "name": "Energie Steiermark Kunden GmbH",
      "isEjn": true
    },
    {
      "pk": 25847,
      "name": "Energie und Wasser Potsdam GmbH",
      "isEjn": true
    },
    {
      "pk": 25852,
      "name": "Energie und Wasserversorgung Bonn/Rhein-Sieg GmbH (EnW Bonn/Rhein-Sieg)",
      "isEjn": true
    },
    {
      "pk": 21559,
      "name": "Energie- und Wasserversorgung Bruchsal GmbH",
      "isEjn": true
    },
    {
      "pk": 89,
      "name": "Energiedienst Holding AG",
      "isEjn": true
    },
    {
      "pk": 2139,
      "name": "Energiedienste der Landeshauptstadt Stuttgart GmbH",
      "isEjn": true
    },
    {
      "pk": 1475,
      "name": "Energiegewinner",
      "isEjn": true
    },
    {
      "pk": 30680,
      "name": "Energielenker Service GmbH",
      "isEjn": true
    },
    {
      "pk": 12760,
      "name": "energielösung GmbH",
      "isEjn": true
    },
    {
      "pk": 594,
      "name": "EnergieSüdwest AG",
      "isEjn": true
    },
    {
      "pk": 21685,
      "name": "Energieversorgung Kleinwalsertal Gesmbh",
      "isEjn": true
    },
    {
      "pk": 3975,
      "name": "Energieversorgung Leverkusen",
      "isEjn": true
    },
    {
      "pk": 35359,
      "name": "ENERGU GmbH",
      "isEjn": true
    },
    {
      "pk": 14233,
      "name": "Energytix Solutions",
      "isEjn": true
    },
    {
      "pk": 22365,
      "name": "EnergyVision",
      "isEjn": true
    },
    {
      "pk": 3654,
      "name": "Enerhub Srl",
      "isEjn": true
    },
    {
      "pk": 3361,
      "name": "Enermia",
      "isEjn": true
    },
    {
      "pk": 25862,
      "name": "Enerstock",
      "isEjn": true
    },
    {
      "pk": 2706,
      "name": "Engie",
      "isEjn": true
    },
    {
      "pk": 3952,
      "name": "ENGIE Belgium",
      "isEjn": true
    },
    {
      "pk": 15999,
      "name": "ENGIE Vianeo",
      "isEjn": true
    },
    {
      "pk": 3716,
      "name": "ENIO",
      "isEjn": true
    },
    {
      "pk": 573,
      "name": "ENNAGY",
      "isEjn": true
    },
    {
      "pk": 16,
      "name": "ENTEGA",
      "isEjn": true
    },
    {
      "pk": 30049,
      "name": "Enviem",
      "isEjn": true
    },
    {
      "pk": 20119,
      "name": "éo liberty",
      "isEjn": true
    },
    {
      "pk": 27170,
      "name": "Eoliberty",
      "isEjn": true
    },
    {
      "pk": 31365,
      "name": "EPOCH",
      "isEjn": true
    },
    {
      "pk": 3723,
      "name": "Eponet AG",
      "isEjn": true
    },
    {
      "pk": 3283,
      "name": "ePower",
      "isEjn": true
    },
    {
      "pk": 12,
      "name": "EQUANS",
      "isEjn": true
    },
    {
      "pk": 4253,
      "name": "Equans Amsterdam",
      "isEjn": true
    },
    {
      "pk": 19840,
      "name": "Equans Brier 59",
      "isEjn": true
    },
    {
      "pk": 25255,
      "name": "Equans De Vaalt",
      "isEjn": true
    },
    {
      "pk": 3828,
      "name": "EQUANS Den Haag",
      "isEjn": true
    },
    {
      "pk": 26156,
      "name": "EQUANS EV Gelderland",
      "isEjn": true
    },
    {
      "pk": 4133,
      "name": "EQUANS Fletcher",
      "isEjn": true
    },
    {
      "pk": 30275,
      "name": "Equans France",
      "isEjn": true
    },
    {
      "pk": 22437,
      "name": "Equans Groningen Drenthe",
      "isEjn": true
    },
    {
      "pk": 17869,
      "name": "Equans Mobility Belux",
      "isEjn": true
    },
    {
      "pk": 3825,
      "name": "EQUANS SGZH",
      "isEjn": true
    },
    {
      "pk": 3903,
      "name": "EQUAY",
      "isEjn": true
    },
    {
      "pk": 12740,
      "name": "Eranovum",
      "isEjn": true
    },
    {
      "pk": 32652,
      "name": "Erenja AG & Co. KG",
      "isEjn": true
    },
    {
      "pk": 24107,
      "name": "Erfurt Automatisierungstechnik",
      "isEjn": true
    },
    {
      "pk": 4202,
      "name": "ESB Energy",
      "isEjn": true
    },
    {
      "pk": 27557,
      "name": "Esso Auto Saligny",
      "isEjn": true
    },
    {
      "pk": 3336,
      "name": "Établissement Public d’Aménagement de Paris-Saclay",
      "isEjn": true
    },
    {
      "pk": 3724,
      "name": "Eulektro",
      "isEjn": true
    },
    {
      "pk": 15753,
      "name": "EV Charging",
      "isEjn": true
    },
    {
      "pk": 3843,
      "name": "EV Company",
      "isEjn": true
    },
    {
      "pk": 3335,
      "name": "EV Maestro",
      "isEjn": true
    },
    {
      "pk": 37844,
      "name": "EV On The Move",
      "isEjn": true
    },
    {
      "pk": 651,
      "name": "EV Plus",
      "isEjn": true
    },
    {
      "pk": 16640,
      "name": "EV Point",
      "isEjn": true
    },
    {
      "pk": 3112,
      "name": "EV Power",
      "isEjn": true
    },
    {
      "pk": 64,
      "name": "EV Wise",
      "isEjn": true
    },
    {
      "pk": 4281,
      "name": "EV4All",
      "isEjn": true
    },
    {
      "pk": 21418,
      "name": "EVBox",
      "isEjn": true
    },
    {
      "pk": 4015,
      "name": "EVC",
      "isEjn": true
    },
    {
      "pk": 28179,
      "name": "EVCE Power",
      "isEjn": true
    },
    {
      "pk": 1828,
      "name": "evd energieversorgung dormagen gmbh",
      "isEjn": true
    },
    {
      "pk": 26188,
      "name": "EVDH GmbH",
      "isEjn": true
    },
    {
      "pk": 29192,
      "name": "EVECHARGE",
      "isEjn": true
    },
    {
      "pk": 3834,
      "name": "evensnelladen",
      "isEjn": true
    },
    {
      "pk": 5700,
      "name": "Evergo",
      "isEjn": true
    },
    {
      "pk": 28203,
      "name": "EVIO - Electrical Mobility",
      "isEjn": true
    },
    {
      "pk": 15062,
      "name": "EVMap",
      "isEjn": true
    },
    {
      "pk": 3736,
      "name": "EVN Energieservices GmbH",
      "isEjn": true
    },
    {
      "pk": 17174,
      "name": "EVN North Macedonia",
      "isEjn": true
    },
    {
      "pk": 1,
      "name": "EVnetNL",
      "isEjn": true
    },
    {
      "pk": 24072,
      "name": "Evolo",
      "isEjn": true
    },
    {
      "pk": 22644,
      "name": "Evology Charging",
      "isEjn": true
    },
    {
      "pk": 689,
      "name": "eVolt Charging",
      "isEjn": true
    },
    {
      "pk": 40576,
      "name": "Evonity",
      "isEjn": true
    },
    {
      "pk": 1666,
      "name": "evpass",
      "isEjn": true
    },
    {
      "pk": 17879,
      "name": "EVTechnik",
      "isEjn": true
    },
    {
      "pk": 30998,
      "name": "EVU der Stadtgemeinde Mureck",
      "isEjn": true
    },
    {
      "pk": 4284,
      "name": "Evybel",
      "isEjn": true
    },
    {
      "pk": 4199,
      "name": "Evyve",
      "isEjn": true
    },
    {
      "pk": 3466,
      "name": "EVzen",
      "isEjn": true
    },
    {
      "pk": 3378,
      "name": "EVZIIN",
      "isEjn": true
    },
    {
      "pk": 19165,
      "name": "EWII",
      "isEjn": true
    },
    {
      "pk": 3573,
      "name": "Ewiva S.r.l.",
      "isEjn": true
    },
    {
      "pk": 20886,
      "name": "eww Anlagentechnik",
      "isEjn": true
    },
    {
      "pk": 3464,
      "name": "Exadys",
      "isEjn": true
    },
    {
      "pk": 19281,
      "name": "Extendas Online Informatica B.V.",
      "isEjn": true
    },
    {
      "pk": 29779,
      "name": "EZ Charge",
      "isEjn": true
    },
    {
      "pk": 2648,
      "name": "Ez-Charg3",
      "isEjn": true
    },
    {
      "pk": 5867,
      "name": "EZ-Charge",
      "isEjn": true
    },
    {
      "pk": 4066,
      "name": "EZdrive",
      "isEjn": true
    },
    {
      "pk": 19030,
      "name": "EZdrive-Guyane",
      "isEjn": true
    },
    {
      "pk": 28245,
      "name": "Ezu Energia",
      "isEjn": true
    },
    {
      "pk": 38666,
      "name": "EZV Energie- und Service GmbH & Co. KG Untermain",
      "isEjn": true
    },
    {
      "pk": 3623,
      "name": "EZVolt",
      "isEjn": true
    },
    {
      "pk": 35357,
      "name": "Facilicom",
      "isEjn": true
    },
    {
      "pk": 28190,
      "name": "Factor Energia",
      "isEjn": true
    },
    {
      "pk": 22,
      "name": "Fastned",
      "isEjn": true
    },
    {
      "pk": 3312,
      "name": "Fédération Départementale d’Energie de la Somme (FDE80)",
      "isEjn": true
    },
    {
      "pk": 3375,
      "name": "Fédération Départementale d'Energies des Ardennes (FDEA08)",
      "isEjn": true
    },
    {
      "pk": 32777,
      "name": "Ferratec Technics AG",
      "isEjn": true
    },
    {
      "pk": 1125,
      "name": "Fines Charging",
      "isEjn": true
    },
    {
      "pk": 4152,
      "name": "FIRMENLADEN",
      "isEjn": true
    },
    {
      "pk": 35174,
      "name": "Fleet Charge Services",
      "isEjn": true
    },
    {
      "pk": 26187,
      "name": "FLEETCOR",
      "isEjn": true
    },
    {
      "pk": 3647,
      "name": "Floading",
      "isEjn": true
    },
    {
      "pk": 14235,
      "name": "Flowbird France",
      "isEjn": true
    },
    {
      "pk": 26161,
      "name": "Fluctis",
      "isEjn": true
    },
    {
      "pk": 20284,
      "name": "Flughafen Düsseldorf",
      "isEjn": true
    },
    {
      "pk": 22572,
      "name": "Flughafen Stuttgart Gesellschaft mit beschränkter Haftung",
      "isEjn": true
    },
    {
      "pk": 26183,
      "name": "Fluvius",
      "isEjn": true
    },
    {
      "pk": 3733,
      "name": "flyElectric",
      "isEjn": true
    },
    {
      "pk": 16574,
      "name": "FOR EV",
      "isEjn": true
    },
    {
      "pk": 34517,
      "name": "FORD",
      "isEjn": true
    },
    {
      "pk": 3140,
      "name": "Fraunhofer",
      "isEjn": true
    },
    {
      "pk": 3644,
      "name": "Free to X",
      "isEjn": true
    },
    {
      "pk": 27673,
      "name": "Freisinger Stadtwerke",
      "isEjn": true
    },
    {
      "pk": 21133,
      "name": "Freshmile",
      "isEjn": true
    },
    {
      "pk": 35191,
      "name": "Friwigo",
      "isEjn": true
    },
    {
      "pk": 28248,
      "name": "Frontrow, LDA",
      "isEjn": true
    },
    {
      "pk": 662,
      "name": "Fuuse",
      "isEjn": true
    },
    {
      "pk": 3454,
      "name": "G&V",
      "isEjn": true
    },
    {
      "pk": 27870,
      "name": "Gabriëls Power",
      "isEjn": true
    },
    {
      "pk": 3456,
      "name": "Gaia Green Tech",
      "isEjn": true
    },
    {
      "pk": 667,
      "name": "Galp Power",
      "isEjn": true
    },
    {
      "pk": 40748,
      "name": "Garage Boo et Vanhee",
      "isEjn": true
    },
    {
      "pk": 28560,
      "name": "Garage LEPROUST",
      "isEjn": true
    },
    {
      "pk": 22332,
      "name": "Garage Leroyer",
      "isEjn": true
    },
    {
      "pk": 28237,
      "name": "Gasfomento",
      "isEjn": true
    },
    {
      "pk": 3528,
      "name": "Gelsenwasser AG",
      "isEjn": true
    },
    {
      "pk": 14830,
      "name": "Generation Journey",
      "isEjn": true
    },
    {
      "pk": 693,
      "name": "GeniePoint",
      "isEjn": true
    },
    {
      "pk": 27315,
      "name": "Gesellschaft des Kreises Coesfeld zur Förderung Regenerativer Energien mbH",
      "isEjn": true
    },
    {
      "pk": 462,
      "name": "GGEW",
      "isEjn": true
    },
    {
      "pk": 3656,
      "name": "GiC - Gestion Inteligente de Cargas",
      "isEjn": true
    },
    {
      "pk": 3735,
      "name": "Gilbarco Veeder-Root Ltd.",
      "isEjn": true
    },
    {
      "pk": 8912,
      "name": "gmob",
      "isEjn": true
    },
    {
      "pk": 4283,
      "name": "Go Watts",
      "isEjn": true
    },
    {
      "pk": 32321,
      "name": "Go-Blue",
      "isEjn": true
    },
    {
      "pk": 3752,
      "name": "Go-E",
      "isEjn": true
    },
    {
      "pk": 18819,
      "name": "go2zero gmbh",
      "isEjn": true
    },
    {
      "pk": 18716,
      "name": "GOFAST",
      "isEjn": true
    },
    {
      "pk": 28233,
      "name": "Gold Energy",
      "isEjn": true
    },
    {
      "pk": 3691,
      "name": "GOLDBECK Parking GmbH",
      "isEjn": true
    },
    {
      "pk": 29190,
      "name": "Goodmoovs BV",
      "isEjn": true
    },
    {
      "pk": 391,
      "name": "GP JOULE Connect GmbH",
      "isEjn": true
    },
    {
      "pk": 3403,
      "name": "Grand-Reims",
      "isEjn": true
    },
    {
      "pk": 28181,
      "name": "Grcapp, Unipessoal Lda",
      "isEjn": true
    },
    {
      "pk": 18375,
      "name": "Green Caravan",
      "isEjn": true
    },
    {
      "pk": 28224,
      "name": "GREEN CHARGE - MOBILIDADE ELÉTRICA, LDA",
      "isEjn": true
    },
    {
      "pk": 28249,
      "name": "Green Evolut, LDA",
      "isEjn": true
    },
    {
      "pk": 3698,
      "name": "Green Motion SA",
      "isEjn": true
    },
    {
      "pk": 40948,
      "name": "Greenbox Mobile Energy",
      "isEjn": true
    },
    {
      "pk": 35188,
      "name": "Greenchoice B.V.",
      "isEjn": true
    },
    {
      "pk": 3342,
      "name": "GREENETIK Retail Kft.",
      "isEjn": true
    },
    {
      "pk": 26169,
      "name": "Greenflash Charge",
      "isEjn": true
    },
    {
      "pk": 3393,
      "name": "Greenspot",
      "isEjn": true
    },
    {
      "pk": 3458,
      "name": "Greenway",
      "isEjn": true
    },
    {
      "pk": 24181,
      "name": "Greenyellow",
      "isEjn": true
    },
    {
      "pk": 3343,
      "name": "Grenoble-Alpes Métropole",
      "isEjn": true
    },
    {
      "pk": 3650,
      "name": "Grid & Co.",
      "isEjn": true
    },
    {
      "pk": 15608,
      "name": "Groen-Eco",
      "isEjn": true
    },
    {
      "pk": 26832,
      "name": "Gröller GmbH",
      "isEjn": true
    },
    {
      "pk": 28238,
      "name": "Grupo Easycharger, SL",
      "isEjn": true
    },
    {
      "pk": 29915,
      "name": "Hafro Energy",
      "isEjn": true
    },
    {
      "pk": 17343,
      "name": "Hako GmbH",
      "isEjn": true
    },
    {
      "pk": 26184,
      "name": "HAPPY CHARGER",
      "isEjn": true
    },
    {
      "pk": 35173,
      "name": "Heijmans",
      "isEjn": true
    },
    {
      "pk": 28173,
      "name": "Helexia",
      "isEjn": true
    },
    {
      "pk": 33698,
      "name": "Helios Group",
      "isEjn": true
    },
    {
      "pk": 22367,
      "name": "Henkel AG & Co. KGaA",
      "isEjn": true
    },
    {
      "pk": 21672,
      "name": "HEOS Object GmbH & Co. KG",
      "isEjn": true
    },
    {
      "pk": 26190,
      "name": "Hepp-Schwamborn",
      "isEjn": true
    },
    {
      "pk": 3637,
      "name": "Hera Comm",
      "isEjn": true
    },
    {
      "pk": 21824,
      "name": "heristo AG",
      "isEjn": true
    },
    {
      "pk": 3119,
      "name": "Hexagonal Ocean",
      "isEjn": true
    },
    {
      "pk": 26163,
      "name": "Hey! Charging",
      "isEjn": true
    },
    {
      "pk": 3114,
      "name": "High Green Power",
      "isEjn": true
    },
    {
      "pk": 36165,
      "name": "HOBACA d.o.o.",
      "isEjn": true
    },
    {
      "pk": 2975,
      "name": "Hochtief Ladepartner GmbH",
      "isEjn": true
    },
    {
      "pk": 33496,
      "name": "Hölzl GmbH",
      "isEjn": true
    },
    {
      "pk": 30677,
      "name": "Home of Mobility GmbH",
      "isEjn": true
    },
    {
      "pk": 41306,
      "name": "HPLT",
      "isEjn": true
    },
    {
      "pk": 3669,
      "name": "Hrvatski Telekom d.d.",
      "isEjn": true
    },
    {
      "pk": 18660,
      "name": "Hymes Energy GmbH",
      "isEjn": true
    },
    {
      "pk": 15210,
      "name": "i-Protech Technology",
      "isEjn": true
    },
    {
      "pk": 24594,
      "name": "Iberdrola | bp pulse",
      "isEjn": true
    },
    {
      "pk": 3621,
      "name": "Iberdrola Clientes",
      "isEjn": true
    },
    {
      "pk": 3750,
      "name": "IBIL Gestor de Carga de VE S.A.",
      "isEjn": true
    },
    {
      "pk": 37911,
      "name": "ICNC Demo",
      "isEjn": true
    },
    {
      "pk": 31918,
      "name": "ICS",
      "isEjn": true
    },
    {
      "pk": 18993,
      "name": "Idex",
      "isEjn": true
    },
    {
      "pk": 3503,
      "name": "IE Charge",
      "isEjn": true
    },
    {
      "pk": 28157,
      "name": "iHome",
      "isEjn": true
    },
    {
      "pk": 3519,
      "name": "IKEA France",
      "isEjn": true
    },
    {
      "pk": 3633,
      "name": "illwerke vkw AG (AT)",
      "isEjn": true
    },
    {
      "pk": 3638,
      "name": "illwerke vkw AG (CPO DE)",
      "isEjn": true
    },
    {
      "pk": 3710,
      "name": "iLumen",
      "isEjn": true
    },
    {
      "pk": 28219,
      "name": "Image 4 all",
      "isEjn": true
    },
    {
      "pk": 3989,
      "name": "Immari",
      "isEjn": true
    },
    {
      "pk": 820,
      "name": "InAS - Infrastruktur Amberg-Sulzbach GmbH",
      "isEjn": true
    },
    {
      "pk": 26154,
      "name": "INCCGROUP",
      "isEjn": true
    },
    {
      "pk": 3836,
      "name": "Indelec",
      "isEjn": true
    },
    {
      "pk": 3377,
      "name": "INDIGO Parkings",
      "isEjn": true
    },
    {
      "pk": 3684,
      "name": "inno2grid GmbH",
      "isEjn": true
    },
    {
      "pk": 27625,
      "name": "Innogy Energo",
      "isEjn": true
    },
    {
      "pk": 12442,
      "name": "InstaVolt",
      "isEjn": true
    },
    {
      "pk": 35225,
      "name": "Intermarché Privas",
      "isEjn": true
    },
    {
      "pk": 12419,
      "name": "Interparking Belgium",
      "isEjn": true
    },
    {
      "pk": 3410,
      "name": "Interparking France",
      "isEjn": true
    },
    {
      "pk": 41308,
      "name": "Interparking Spain",
      "isEjn": true
    },
    {
      "pk": 28244,
      "name": "Intervilapraia",
      "isEjn": true
    },
    {
      "pk": 4203,
      "name": "IONITY",
      "isEjn": true
    },
    {
      "pk": 13233,
      "name": "IPlanet",
      "isEjn": true
    },
    {
      "pk": 4096,
      "name": "IZEN",
      "isEjn": true
    },
    {
      "pk": 3321,
      "name": "Izivia",
      "isEjn": true
    },
    {
      "pk": 22778,
      "name": "J.W. Zander GmbH & Co. KG Freiburg i.Br.",
      "isEjn": true
    },
    {
      "pk": 20956,
      "name": "JAM-M SRL CPO",
      "isEjn": true
    },
    {
      "pk": 17275,
      "name": "Jere Charge",
      "isEjn": true
    },
    {
      "pk": 23898,
      "name": "JET Strom",
      "isEjn": true
    },
    {
      "pk": 28239,
      "name": "JH Ornelas",
      "isEjn": true
    },
    {
      "pk": 28225,
      "name": "JJTOME EIFFAGE",
      "isEjn": true
    },
    {
      "pk": 1531,
      "name": "Jöchle Mobility GmbH",
      "isEjn": true
    },
    {
      "pk": 1872,
      "name": "JOINON",
      "isEjn": true
    },
    {
      "pk": 3470,
      "name": "JOLT Energy GmbH.",
      "isEjn": true
    },
    {
      "pk": 24177,
      "name": "JOLT The Netherlands B.V.",
      "isEjn": true
    },
    {
      "pk": 10,
      "name": "Joulz",
      "isEjn": true
    },
    {
      "pk": 38699,
      "name": "JUCR",
      "isEjn": true
    },
    {
      "pk": 18954,
      "name": "Jupiter Marathon",
      "isEjn": true
    },
    {
      "pk": 18920,
      "name": "Jupiter Seafield",
      "isEjn": true
    },
    {
      "pk": 3695,
      "name": "K-Auto Oy",
      "isEjn": true
    },
    {
      "pk": 3496,
      "name": "Kaufland Dienstleistung GmbH & Co. KG",
      "isEjn": true
    },
    {
      "pk": 27348,
      "name": "KAUFLAND HRVATSKA k.d.",
      "isEjn": true
    },
    {
      "pk": 26827,
      "name": "Kaufland Romania",
      "isEjn": true
    },
    {
      "pk": 3689,
      "name": "KELAG-Kärntner Elektrizitäts-AG (CPO)",
      "isEjn": true
    },
    {
      "pk": 25426,
      "name": "KfW Bankengruppe",
      "isEjn": true
    },
    {
      "pk": 13229,
      "name": "KIA Bulgaria",
      "isEjn": true
    },
    {
      "pk": 3309,
      "name": "KIA France",
      "isEjn": true
    },
    {
      "pk": 35360,
      "name": "Kia Nederland B.V.",
      "isEjn": true
    },
    {
      "pk": 28152,
      "name": "Kilometer Low Cost",
      "isEjn": true
    },
    {
      "pk": 35358,
      "name": "KION ITS EMEA",
      "isEjn": true
    },
    {
      "pk": 32883,
      "name": "kiwih srl",
      "isEjn": true
    },
    {
      "pk": 24628,
      "name": "Kolektivolt",
      "isEjn": true
    },
    {
      "pk": 981,
      "name": "Kreiswerke Main-Kinzig GmbH",
      "isEjn": true
    },
    {
      "pk": 38564,
      "name": "KSO Duurzaam",
      "isEjn": true
    },
    {
      "pk": 27624,
      "name": "Kuwait Petroleum (Belgium) N.V. (CPO)",
      "isEjn": true
    },
    {
      "pk": 25866,
      "name": "KWMS GmbH",
      "isEjn": true
    },
    {
      "pk": 3734,
      "name": "Laadkompas B.V.",
      "isEjn": true
    },
    {
      "pk": 24417,
      "name": "Laadkracht B.V.",
      "isEjn": true
    },
    {
      "pk": 4093,
      "name": "Laadpaal24",
      "isEjn": true
    },
    {
      "pk": 31296,
      "name": "labore sit dignissimos",
      "isEjn": true
    },
    {
      "pk": 20887,
      "name": "LADE Operate GmbH",
      "isEjn": true
    },
    {
      "pk": 17004,
      "name": "Lade.ZEIT",
      "isEjn": true
    },
    {
      "pk": 25567,
      "name": "Ladenetz Barnim",
      "isEjn": true
    },
    {
      "pk": 25861,
      "name": "LAN1 Hotspots GmbH",
      "isEjn": true
    },
    {
      "pk": 35187,
      "name": "Lanova Laadpalen",
      "isEjn": true
    },
    {
      "pk": 17835,
      "name": "Larecharge",
      "isEjn": true
    },
    {
      "pk": 27282,
      "name": "Last Mile Solutions",
      "isEjn": true
    },
    {
      "pk": 15493,
      "name": "Leap24",
      "isEjn": true
    },
    {
      "pk": 3712,
      "name": "Leipziger Stadtwerke",
      "isEjn": true
    },
    {
      "pk": 21416,
      "name": "Leitner Energy",
      "isEjn": true
    },
    {
      "pk": 23083,
      "name": "Leroy Merlin",
      "isEjn": true
    },
    {
      "pk": 39951,
      "name": "Let's Charge",
      "isEjn": true
    },
    {
      "pk": 12972,
      "name": "LichtBlick eMobility GmbH",
      "isEjn": true
    },
    {
      "pk": 3248,
      "name": "Lidl",
      "isEjn": true
    },
    {
      "pk": 2030,
      "name": "Liechtensteinische Kraftwerke",
      "isEjn": true
    },
    {
      "pk": 3688,
      "name": "LINZ STROM GAS WAERME GmbH",
      "isEjn": true
    },
    {
      "pk": 3743,
      "name": "LINZ STROM GmbH",
      "isEjn": true
    },
    {
      "pk": 2700,
      "name": "LM Energy",
      "isEjn": true
    },
    {
      "pk": 24180,
      "name": "Load Stations",
      "isEjn": true
    },
    {
      "pk": 26189,
      "name": "Loadl",
      "isEjn": true
    },
    {
      "pk": 9407,
      "name": "Lodmi",
      "isEjn": true
    },
    {
      "pk": 28214,
      "name": "Loulé Concelho Global, EM",
      "isEjn": true
    },
    {
      "pk": 3417,
      "name": "LUMI'IN",
      "isEjn": true
    },
    {
      "pk": 3926,
      "name": "Luminus",
      "isEjn": true
    },
    {
      "pk": 3694,
      "name": "Lumosa Energy B.V.",
      "isEjn": true
    },
    {
      "pk": 28153,
      "name": "Luzigás",
      "isEjn": true
    },
    {
      "pk": 25533,
      "name": "m & i-Parkland-Klinik GmbH",
      "isEjn": true
    },
    {
      "pk": 3660,
      "name": "m8mit",
      "isEjn": true
    },
    {
      "pk": 4029,
      "name": "Magenta",
      "isEjn": true
    },
    {
      "pk": 3687,
      "name": "MAHLE chargeBIG GmbH",
      "isEjn": true
    },
    {
      "pk": 3664,
      "name": "Maingau Energie GmbH",
      "isEjn": true
    },
    {
      "pk": 21675,
      "name": "Mainova Aktiengesellschaft",
      "isEjn": true
    },
    {
      "pk": 1484,
      "name": "Maksu",
      "isEjn": true
    },
    {
      "pk": 3535,
      "name": "Margez Automobile",
      "isEjn": true
    },
    {
      "pk": 30999,
      "name": "Marktgemeinde Straden",
      "isEjn": true
    },
    {
      "pk": 26404,
      "name": "Marktgemeinde Wattens",
      "isEjn": true
    },
    {
      "pk": 23158,
      "name": "mateco GmbH",
      "isEjn": true
    },
    {
      "pk": 17914,
      "name": "Maxem",
      "isEjn": true
    },
    {
      "pk": 3236,
      "name": "MaxSolar GmbH",
      "isEjn": true
    },
    {
      "pk": 3307,
      "name": "MAZDA",
      "isEjn": true
    },
    {
      "pk": 2377,
      "name": "me energy GmbH",
      "isEjn": true
    },
    {
      "pk": 3413,
      "name": "MEA Energies",
      "isEjn": true
    },
    {
      "pk": 14520,
      "name": "MeCharge",
      "isEjn": true
    },
    {
      "pk": 3097,
      "name": "MegaCharge",
      "isEjn": true
    },
    {
      "pk": 3722,
      "name": "MegaTel",
      "isEjn": true
    },
    {
      "pk": 2207,
      "name": "Mennekes",
      "isEjn": true
    },
    {
      "pk": 28183,
      "name": "MEO Energia",
      "isEjn": true
    },
    {
      "pk": 4131,
      "name": "MER",
      "isEjn": true
    },
    {
      "pk": 30690,
      "name": "Mercedes-Benz HPC Europe GmbH",
      "isEjn": true
    },
    {
      "pk": 764,
      "name": "Métropole du Grand Nancy",
      "isEjn": true
    },
    {
      "pk": 28738,
      "name": "Métropole Européenne de Lille",
      "isEjn": true
    },
    {
      "pk": 3337,
      "name": "Métropole Nice Côte D'Azure",
      "isEjn": true
    },
    {
      "pk": 3424,
      "name": "Métropole Rouen-Normandie",
      "isEjn": true
    },
    {
      "pk": 3269,
      "name": "Métropolis Recharge",
      "isEjn": true
    },
    {
      "pk": 19668,
      "name": "Meurthe-et-Moselle (S54)",
      "isEjn": true
    },
    {
      "pk": 3445,
      "name": "MFG EV Power",
      "isEjn": true
    },
    {
      "pk": 20363,
      "name": "Migrol AG CPO",
      "isEjn": true
    },
    {
      "pk": 25022,
      "name": "Mitsubishi Electric Automotive",
      "isEjn": true
    },
    {
      "pk": 3520,
      "name": "Mobelec",
      "isEjn": true
    },
    {
      "pk": 28202,
      "name": "MOBI A",
      "isEjn": true
    },
    {
      "pk": 1146,
      "name": "Mobi.E",
      "isEjn": true
    },
    {
      "pk": 3790,
      "name": "Mobiflow",
      "isEjn": true
    },
    {
      "pk": 17172,
      "name": "MobileCharge",
      "isEjn": true
    },
    {
      "pk": 28154,
      "name": "Mobiletric",
      "isEjn": true
    },
    {
      "pk": 13513,
      "name": "Mobilitics",
      "isEjn": true
    },
    {
      "pk": 26168,
      "name": "Mobility Pulse",
      "isEjn": true
    },
    {
      "pk": 1379,
      "name": "MobilityPlus",
      "isEjn": true
    },
    {
      "pk": 24179,
      "name": "Mobilize Fast Charge",
      "isEjn": true
    },
    {
      "pk": 20438,
      "name": "MobilyGreen",
      "isEjn": true
    },
    {
      "pk": 3449,
      "name": "MObiVE",
      "isEjn": true
    },
    {
      "pk": 3518,
      "name": "Modulo",
      "isEjn": true
    },
    {
      "pk": 36166,
      "name": "MOL Plugee",
      "isEjn": true
    },
    {
      "pk": 254,
      "name": "Monta",
      "isEjn": true
    },
    {
      "pk": 12947,
      "name": "Moon Power",
      "isEjn": true
    },
    {
      "pk": 3404,
      "name": "Morbihan Énergies",
      "isEjn": true
    },
    {
      "pk": 26186,
      "name": "MotionBOX",
      "isEjn": true
    },
    {
      "pk": 3405,
      "name": "Move in Pure (CNR Ènergie)",
      "isEjn": true
    },
    {
      "pk": 3622,
      "name": "MOVE Mobility AG (CCI)",
      "isEjn": true
    },
    {
      "pk": 35180,
      "name": "Moveyou",
      "isEjn": true
    },
    {
      "pk": 28228,
      "name": "Município de Santa Cruz",
      "isEjn": true
    },
    {
      "pk": 28221,
      "name": "MUVEXT",
      "isEjn": true
    },
    {
      "pk": 16284,
      "name": "MVM Mobiliti Kft.",
      "isEjn": true
    },
    {
      "pk": 21670,
      "name": "MVV Energie AG",
      "isEjn": true
    },
    {
      "pk": 25566,
      "name": "MWEnergy GmbH",
      "isEjn": true
    },
    {
      "pk": 731,
      "name": "My WAAT",
      "isEjn": true
    },
    {
      "pk": 25601,
      "name": "N-ERGIE",
      "isEjn": true
    },
    {
      "pk": 12801,
      "name": "NaturEnergie Community Ladestation",
      "isEjn": true
    },
    {
      "pk": 68,
      "name": "NATURSTROM AG",
      "isEjn": true
    },
    {
      "pk": 24521,
      "name": "NENAG GmbH",
      "isEjn": true
    },
    {
      "pk": 3636,
      "name": "Neogy srl",
      "isEjn": true
    },
    {
      "pk": 24940,
      "name": "NEST",
      "isEjn": true
    },
    {
      "pk": 28187,
      "name": "Neureifen",
      "isEjn": true
    },
    {
      "pk": 25741,
      "name": "NEW",
      "isEjn": true
    },
    {
      "pk": 3497,
      "name": "NEXTENEO",
      "isEjn": true
    },
    {
      "pk": 26165,
      "name": "Nieuw-Hollands Energiebedrijf",
      "isEjn": true
    },
    {
      "pk": 24383,
      "name": "Nima Energy",
      "isEjn": true
    },
    {
      "pk": 35,
      "name": "Nissan",
      "isEjn": true
    },
    {
      "pk": 2067,
      "name": "Njudung Energi",
      "isEjn": true
    },
    {
      "pk": 107,
      "name": "NLcharge",
      "isEjn": true
    },
    {
      "pk": 25850,
      "name": "nonoxx pro GmbH",
      "isEjn": true
    },
    {
      "pk": 2624,
      "name": "Norauto - Bornes publiques",
      "isEjn": true
    },
    {
      "pk": 27491,
      "name": "Norfolk County Council",
      "isEjn": true
    },
    {
      "pk": 310,
      "name": "Norlys",
      "isEjn": true
    },
    {
      "pk": 26826,
      "name": "Norlys Charging",
      "isEjn": true
    },
    {
      "pk": 24418,
      "name": "Nova Energie",
      "isEjn": true
    },
    {
      "pk": 17924,
      "name": "novoeco | recharge (BayWa)",
      "isEjn": true
    },
    {
      "pk": 18680,
      "name": "novoeco FR",
      "isEjn": true
    },
    {
      "pk": 1643,
      "name": "Noxo Energy",
      "isEjn": true
    },
    {
      "pk": 16927,
      "name": "NRG",
      "isEjn": true
    },
    {
      "pk": 36232,
      "name": "NRG InCharge",
      "isEjn": true
    },
    {
      "pk": 12948,
      "name": "Numbat GmbH",
      "isEjn": true
    },
    {
      "pk": 17916,
      "name": "NUMOBI",
      "isEjn": true
    },
    {
      "pk": 21485,
      "name": "NVH",
      "isEjn": true
    },
    {
      "pk": 35170,
      "name": "NXT Charging Solutions",
      "isEjn": true
    },
    {
      "pk": 35190,
      "name": "NXT50Five",
      "isEjn": true
    },
    {
      "pk": 3235,
      "name": "ÖAMTC Verbandsbetriebe GmbH",
      "isEjn": true
    },
    {
      "pk": 17175,
      "name": "OK CPO",
      "isEjn": true
    },
    {
      "pk": 35176,
      "name": "Omes",
      "isEjn": true
    },
    {
      "pk": 16706,
      "name": "OMV eMotion",
      "isEjn": true
    },
    {
      "pk": 3394,
      "name": "On Charge GmbH",
      "isEjn": true
    },
    {
      "pk": 20439,
      "name": "On Electric Charge CPO",
      "isEjn": true
    },
    {
      "pk": 35172,
      "name": "Opcharge",
      "isEjn": true
    },
    {
      "pk": 4290,
      "name": "Optiload",
      "isEjn": true
    },
    {
      "pk": 3149,
      "name": "Optimile Network",
      "isEjn": true
    },
    {
      "pk": 1659,
      "name": "Optimum Way",
      "isEjn": true
    },
    {
      "pk": 3835,
      "name": "Orange Charging",
      "isEjn": true
    },
    {
      "pk": 39160,
      "name": "Oreve",
      "isEjn": true
    },
    {
      "pk": 13436,
      "name": "ORLEN Deutschland GmbH",
      "isEjn": true
    },
    {
      "pk": 643,
      "name": "Osprey",
      "isEjn": true
    },
    {
      "pk": 3362,
      "name": "Ouest Charge",
      "isEjn": true
    },
    {
      "pk": 25851,
      "name": "ovag Energie AG",
      "isEjn": true
    },
    {
      "pk": 30401,
      "name": "Ozecar",
      "isEjn": true
    },
    {
      "pk": 33530,
      "name": "PACORP, LDA",
      "isEjn": true
    },
    {
      "pk": 16931,
      "name": "Paragon Mobility",
      "isEjn": true
    },
    {
      "pk": 3436,
      "name": "Parasol Elite Power",
      "isEjn": true
    },
    {
      "pk": 15350,
      "name": "Pariamus GmbH",
      "isEjn": true
    },
    {
      "pk": 3826,
      "name": "Park n Charge",
      "isEjn": true
    },
    {
      "pk": 3737,
      "name": "Parkraumgesellschaft Baden-Württemberg",
      "isEjn": true
    },
    {
      "pk": 19704,
      "name": "Parkraumgesellschaft Baden-Württemberg mbH",
      "isEjn": true
    },
    {
      "pk": 2482,
      "name": "Parkstrom GmbH",
      "isEjn": true
    },
    {
      "pk": 3838,
      "name": "Partage Ma Borne",
      "isEjn": true
    },
    {
      "pk": 22880,
      "name": "Partino",
      "isEjn": true
    },
    {
      "pk": 3490,
      "name": "Pass Pass Electrique",
      "isEjn": true
    },
    {
      "pk": 2514,
      "name": "PC-TANK Sàrl",
      "isEjn": true
    },
    {
      "pk": 18029,
      "name": "pcharge (Petrolina)",
      "isEjn": true
    },
    {
      "pk": 1298,
      "name": "Petrol",
      "isEjn": true
    },
    {
      "pk": 28240,
      "name": "PETROTERMICA ENERGIA, S.A.",
      "isEjn": true
    },
    {
      "pk": 3308,
      "name": "Pfalzwerke",
      "isEjn": true
    },
    {
      "pk": 32287,
      "name": "PHOENIX POWER",
      "isEjn": true
    },
    {
      "pk": 3506,
      "name": "Picoty",
      "isEjn": true
    },
    {
      "pk": 30683,
      "name": "PION Technology AG",
      "isEjn": true
    },
    {
      "pk": 2379,
      "name": "PitPoint",
      "isEjn": true
    },
    {
      "pk": 19248,
      "name": "PKE Electronics GmbH",
      "isEjn": true
    },
    {
      "pk": 37258,
      "name": "Plug Four the World",
      "isEjn": true
    },
    {
      "pk": 4167,
      "name": "Plug In Suffolk",
      "isEjn": true
    },
    {
      "pk": 121,
      "name": "Plug-N-Go",
      "isEjn": true
    },
    {
      "pk": 3634,
      "name": "Plug'n Roll",
      "isEjn": true
    },
    {
      "pk": 15211,
      "name": "PlugFuse",
      "isEjn": true
    },
    {
      "pk": 24452,
      "name": "PluginCompany",
      "isEjn": true
    },
    {
      "pk": 3991,
      "name": "Pluginvest",
      "isEjn": true
    },
    {
      "pk": 13056,
      "name": "Plugit Finland Oy",
      "isEjn": true
    },
    {
      "pk": 3319,
      "name": "Plugpoint",
      "isEjn": true
    },
    {
      "pk": 933,
      "name": "Plugsurfing",
      "isEjn": true
    },
    {
      "pk": 35867,
      "name": "Pluq",
      "isEjn": true
    },
    {
      "pk": 26191,
      "name": "PMO",
      "isEjn": true
    },
    {
      "pk": 10414,
      "name": "PoGo Charge",
      "isEjn": true
    },
    {
      "pk": 3732,
      "name": "Polarstern GmbH",
      "isEjn": true
    },
    {
      "pk": 4260,
      "name": "Porsche",
      "isEjn": true
    },
    {
      "pk": 30932,
      "name": "Porsche Croatia d.o.o.",
      "isEjn": true
    },
    {
      "pk": 3268,
      "name": "Porsche Slovenia",
      "isEjn": true
    },
    {
      "pk": 35496,
      "name": "Post NL",
      "isEjn": true
    },
    {
      "pk": 31951,
      "name": "Power Flash",
      "isEjn": true
    },
    {
      "pk": 3315,
      "name": "Powerdale",
      "isEjn": true
    },
    {
      "pk": 23544,
      "name": "Powerdot",
      "isEjn": true
    },
    {
      "pk": 26180,
      "name": "Powered by EnergyZero",
      "isEjn": true
    },
    {
      "pk": 2436,
      "name": "Powered By Zeno",
      "isEjn": true
    },
    {
      "pk": 13389,
      "name": "PowerGo",
      "isEjn": true
    },
    {
      "pk": 3802,
      "name": "Powerland",
      "isEjn": true
    },
    {
      "pk": 4287,
      "name": "Powerlink21",
      "isEjn": true
    },
    {
      "pk": 35177,
      "name": "Powerstation",
      "isEjn": true
    },
    {
      "pk": 38807,
      "name": "PowerUp",
      "isEjn": true
    },
    {
      "pk": 3709,
      "name": "Powy (TheF Charging S.r.l.)",
      "isEjn": true
    },
    {
      "pk": 21680,
      "name": "Präg Strom & Gas GmbH & Co. KG",
      "isEjn": true
    },
    {
      "pk": 19950,
      "name": "PRE BIEL",
      "isEjn": true
    },
    {
      "pk": 3700,
      "name": "PRE Mobilita",
      "isEjn": true
    },
    {
      "pk": 28167,
      "name": "Prio Energias",
      "isEjn": true
    },
    {
      "pk": 3574,
      "name": "ProjectEV",
      "isEjn": true
    },
    {
      "pk": 28213,
      "name": "PROPEL",
      "isEjn": true
    },
    {
      "pk": 3443,
      "name": "Properphi",
      "isEjn": true
    },
    {
      "pk": 6009,
      "name": "Protergia Charge",
      "isEjn": true
    },
    {
      "pk": 3428,
      "name": "Proviridis",
      "isEjn": true
    },
    {
      "pk": 35427,
      "name": "PureCharged",
      "isEjn": true
    },
    {
      "pk": 4272,
      "name": "Q-Park France",
      "isEjn": true
    },
    {
      "pk": 4286,
      "name": "Q.Ev. Solutions",
      "isEjn": true
    },
    {
      "pk": 3482,
      "name": "Qelo",
      "isEjn": true
    },
    {
      "pk": 3388,
      "name": "Qovoltis",
      "isEjn": true
    },
    {
      "pk": 3486,
      "name": "QoWatt",
      "isEjn": true
    },
    {
      "pk": 3665,
      "name": "Queenergy S.r.l.",
      "isEjn": true
    },
    {
      "pk": 31295,
      "name": "quod ratione quisquam",
      "isEjn": true
    },
    {
      "pk": 16932,
      "name": "Qwello",
      "isEjn": true
    },
    {
      "pk": 23264,
      "name": "R-ev",
      "isEjn": true
    },
    {
      "pk": 17873,
      "name": "R3",
      "isEjn": true
    },
    {
      "pk": 3446,
      "name": "Rambouillet Territoires",
      "isEjn": true
    },
    {
      "pk": 16772,
      "name": "Raw Charging",
      "isEjn": true
    },
    {
      "pk": 134,
      "name": "Recharge",
      "isEjn": true
    },
    {
      "pk": 13383,
      "name": "Recharger Mon Auto",
      "isEjn": true
    },
    {
      "pk": 3643,
      "name": "reev GmbH",
      "isEjn": true
    },
    {
      "pk": 3440,
      "name": "Régie Services Energie",
      "isEjn": true
    },
    {
      "pk": 21170,
      "name": "Remo Sustainable Mobility",
      "isEjn": true
    },
    {
      "pk": 28175,
      "name": "Renewing",
      "isEjn": true
    },
    {
      "pk": 19807,
      "name": "Reno.Energy",
      "isEjn": true
    },
    {
      "pk": 3640,
      "name": "Renovatio Asset Management",
      "isEjn": true
    },
    {
      "pk": 3653,
      "name": "Repower Vendita Italia spa",
      "isEjn": true
    },
    {
      "pk": 119,
      "name": "Repsol",
      "isEjn": true
    },
    {
      "pk": 3141,
      "name": "Réseau eborn",
      "isEjn": true
    },
    {
      "pk": 3382,
      "name": "Réseau Saint Étienne Métropole",
      "isEjn": true
    },
    {
      "pk": 3460,
      "name": "Réseau Sarthe IRVE",
      "isEjn": true
    },
    {
      "pk": 3270,
      "name": "Réseau SEY ma Borne",
      "isEjn": true
    },
    {
      "pk": 3351,
      "name": "Réseau Wiiiz",
      "isEjn": true
    },
    {
      "pk": 21091,
      "name": "Révéo Charge",
      "isEjn": true
    },
    {
      "pk": 27678,
      "name": "Rewag",
      "isEjn": true
    },
    {
      "pk": 3420,
      "name": "Rexel Netherlands",
      "isEjn": true
    },
    {
      "pk": 27687,
      "name": "Richter + Frenzel",
      "isEjn": true
    },
    {
      "pk": 1787,
      "name": "Richter ElektroCom GmbH",
      "isEjn": true
    },
    {
      "pk": 3515,
      "name": "Rirodo",
      "isEjn": true
    },
    {
      "pk": 3526,
      "name": "Riviera Mobilites",
      "isEjn": true
    },
    {
      "pk": 35168,
      "name": "Road B.V.",
      "isEjn": true
    },
    {
      "pk": 3383,
      "name": "Robo Charge BV",
      "isEjn": true
    },
    {
      "pk": 28201,
      "name": "Rolear",
      "isEjn": true
    },
    {
      "pk": 3318,
      "name": "Rossini Energy",
      "isEjn": true
    },
    {
      "pk": 16930,
      "name": "ROUTE220 Srl S.B.",
      "isEjn": true
    },
    {
      "pk": 3355,
      "name": "Rungis Marché International",
      "isEjn": true
    },
    {
      "pk": 3713,
      "name": "Saascharge International GmbH",
      "isEjn": true
    },
    {
      "pk": 1812,
      "name": "Saemes",
      "isEjn": true
    },
    {
      "pk": 3728,
      "name": "SalzachSonne",
      "isEjn": true
    },
    {
      "pk": 1506,
      "name": "SalzachSonne GmbH",
      "isEjn": true
    },
    {
      "pk": 3576,
      "name": "Salzburg AG für Energie, Verkehr und Telekommunikation",
      "isEjn": true
    },
    {
      "pk": 3461,
      "name": "SAP Labs Mougins",
      "isEjn": true
    },
    {
      "pk": 30310,
      "name": "SARL AUMONT",
      "isEjn": true
    },
    {
      "pk": 28487,
      "name": "SAS ISADIS",
      "isEjn": true
    },
    {
      "pk": 18591,
      "name": "SCDB srl",
      "isEjn": true
    },
    {
      "pk": 23815,
      "name": "Schneider Electric GmbH",
      "isEjn": true
    },
    {
      "pk": 19352,
      "name": "Schneider Solar",
      "isEjn": true
    },
    {
      "pk": 25499,
      "name": "Schouten Energy",
      "isEjn": true
    },
    {
      "pk": 23865,
      "name": "Scopius EV Charging",
      "isEjn": true
    },
    {
      "pk": 1250,
      "name": "Scottish Power",
      "isEjn": true
    },
    {
      "pk": 3455,
      "name": "SDE18 (Syndicat Départemental d’Energie du Cher)",
      "isEjn": true
    },
    {
      "pk": 3339,
      "name": "SDE76 (Syndicat Départemental d'Énergies de la Seine-Maritime)",
      "isEjn": true
    },
    {
      "pk": 3756,
      "name": "SEAK",
      "isEjn": true
    },
    {
      "pk": 3323,
      "name": "SeeYouSun",
      "isEjn": true
    },
    {
      "pk": 28170,
      "name": "Segma",
      "isEjn": true
    },
    {
      "pk": 3889,
      "name": "Seijsener",
      "isEjn": true
    },
    {
      "pk": 3344,
      "name": "SÉOLIS",
      "isEjn": true
    },
    {
      "pk": 13864,
      "name": "Service4Charger",
      "isEjn": true
    },
    {
      "pk": 25868,
      "name": "ServiceSTADTwerke GmbH & Co. KG",
      "isEjn": true
    },
    {
      "pk": 3397,
      "name": "Sevdec Mobility",
      "isEjn": true
    },
    {
      "pk": 15063,
      "name": "SGA Mobility",
      "isEjn": true
    },
    {
      "pk": 8,
      "name": "Shell Recharge",
      "isEjn": true
    },
    {
      "pk": 4299,
      "name": "Shell Ubitricity",
      "isEjn": true
    },
    {
      "pk": 24702,
      "name": "SIA Mobilly",
      "isEjn": true
    },
    {
      "pk": 20436,
      "name": "Siliceo",
      "isEjn": true
    },
    {
      "pk": 28166,
      "name": "Silver Ridge - Asset Management",
      "isEjn": true
    },
    {
      "pk": 18486,
      "name": "Simone - CCVBA",
      "isEjn": true
    },
    {
      "pk": 18487,
      "name": "Simone - SMED13",
      "isEjn": true
    },
    {
      "pk": 3376,
      "name": "Sipperec",
      "isEjn": true
    },
    {
      "pk": 28229,
      "name": "Siva - Sociedade de Importação de Veículos Automóveis",
      "isEjn": true
    },
    {
      "pk": 39158,
      "name": "SJS Services",
      "isEjn": true
    },
    {
      "pk": 35185,
      "name": "SMA eMobility Portal",
      "isEjn": true
    },
    {
      "pk": 3789,
      "name": "Smappee",
      "isEjn": true
    },
    {
      "pk": 40575,
      "name": "Smart Charging Solutions",
      "isEjn": true
    },
    {
      "pk": 35184,
      "name": "Smart-E-Grid",
      "isEjn": true
    },
    {
      "pk": 18520,
      "name": "Smartchargers X-Charge",
      "isEjn": true
    },
    {
      "pk": 3720,
      "name": "SMATRICS GmbH & Co KG",
      "isEjn": true
    },
    {
      "pk": 13982,
      "name": "smopi® - Multi Chargepoint Solution",
      "isEjn": true
    },
    {
      "pk": 3630,
      "name": "SMOYS",
      "isEjn": true
    },
    {
      "pk": 28185,
      "name": "SodiBraga - Supermercados Lda",
      "isEjn": true
    },
    {
      "pk": 19670,
      "name": "SOLA",
      "isEjn": true
    },
    {
      "pk": 36638,
      "name": "Solarhome",
      "isEjn": true
    },
    {
      "pk": 30573,
      "name": "Solutions for Carfleetmanagement Gm",
      "isEjn": true
    },
    {
      "pk": 28227,
      "name": "SOLX",
      "isEjn": true
    },
    {
      "pk": 2667,
      "name": "SOMIMAR - Bornes publiques",
      "isEjn": true
    },
    {
      "pk": 21791,
      "name": "Soregies",
      "isEjn": true
    },
    {
      "pk": 759,
      "name": "Sorégies",
      "isEjn": true
    },
    {
      "pk": 18373,
      "name": "Sorel Energies",
      "isEjn": true
    },
    {
      "pk": 7555,
      "name": "Source London",
      "isEjn": true
    },
    {
      "pk": 3542,
      "name": "Sowatt Solutions",
      "isEjn": true
    },
    {
      "pk": 2644,
      "name": "Sparki",
      "isEjn": true
    },
    {
      "pk": 112,
      "name": "Spirii",
      "isEjn": true
    },
    {
      "pk": 20240,
      "name": "SSE",
      "isEjn": true
    },
    {
      "pk": 16857,
      "name": "STACJOMAT",
      "isEjn": true
    },
    {
      "pk": 27757,
      "name": "Stadt Rotenburg a. d. Fulda",
      "isEjn": true
    },
    {
      "pk": 375,
      "name": "Stadtgemeinde Tulln an der Donau",
      "isEjn": true
    },
    {
      "pk": 14951,
      "name": "Stadtwerk am See GmbH & Co. KG",
      "isEjn": true
    },
    {
      "pk": 27671,
      "name": "Stadtwerke Ahrensburg",
      "isEjn": true
    },
    {
      "pk": 1181,
      "name": "Stadtwerke Amberg Bäder und Park GmbH",
      "isEjn": true
    },
    {
      "pk": 85,
      "name": "Stadtwerke Annaberg-Buchholz Energie Aktiengesellschaft",
      "isEjn": true
    },
    {
      "pk": 25871,
      "name": "Stadtwerke Bad Nauheim GmbH",
      "isEjn": true
    },
    {
      "pk": 27684,
      "name": "Stadtwerke Bad Wörishofen",
      "isEjn": true
    },
    {
      "pk": 21682,
      "name": "Stadtwerke Baden-Baden",
      "isEjn": true
    },
    {
      "pk": 16956,
      "name": "Stadtwerke Bietigheim-Bissingen",
      "isEjn": true
    },
    {
      "pk": 23049,
      "name": "Stadtwerke Bochum GmbH",
      "isEjn": true
    },
    {
      "pk": 27686,
      "name": "Stadtwerke Bogen",
      "isEjn": true
    },
    {
      "pk": 21561,
      "name": "Stadtwerke Bretten GmbH",
      "isEjn": true
    },
    {
      "pk": 1392,
      "name": "Stadtwerke Bühl GmbH",
      "isEjn": true
    },
    {
      "pk": 3547,
      "name": "Stadtwerke Castrop-Rauxel GmbH",
      "isEjn": true
    },
    {
      "pk": 40289,
      "name": "Stadtwerke Celle GmbH",
      "isEjn": true
    },
    {
      "pk": 27672,
      "name": "Stadtwerke Ditzingen",
      "isEjn": true
    },
    {
      "pk": 21560,
      "name": "Stadtwerke Ettlingen GmbH",
      "isEjn": true
    },
    {
      "pk": 27675,
      "name": "Stadtwerke Eutin",
      "isEjn": true
    },
    {
      "pk": 26300,
      "name": "Stadtwerke Frankenberg GmbH",
      "isEjn": true
    },
    {
      "pk": 973,
      "name": "Stadtwerke Frankenthal GmbH",
      "isEjn": true
    },
    {
      "pk": 12782,
      "name": "Stadtwerke Gifhorn GmbH",
      "isEjn": true
    },
    {
      "pk": 21676,
      "name": "Stadtwerke Göttingen Aktiengesellschaft",
      "isEjn": true
    },
    {
      "pk": 25856,
      "name": "Stadtwerke Hamm Gesellschaft mit beschränkter Haftung",
      "isEjn": true
    },
    {
      "pk": 21681,
      "name": "Stadtwerke Heidelberg Energie GmbH",
      "isEjn": true
    },
    {
      "pk": 30682,
      "name": "Stadtwerke Hürth",
      "isEjn": true
    },
    {
      "pk": 21673,
      "name": "Stadtwerke Ingolstadt Beteiligungen GmbH",
      "isEjn": true
    },
    {
      "pk": 2984,
      "name": "Stadtwerke Kaarst GmbH",
      "isEjn": true
    },
    {
      "pk": 27685,
      "name": "Stadtwerke Kelheim",
      "isEjn": true
    },
    {
      "pk": 25865,
      "name": "Stadtwerke Kempen GmbH",
      "isEjn": true
    },
    {
      "pk": 20750,
      "name": "Stadtwerke Kerpen GmbH & Co KG",
      "isEjn": true
    },
    {
      "pk": 21674,
      "name": "Stadtwerke Kiel Aktiengesellschaft",
      "isEjn": true
    },
    {
      "pk": 14939,
      "name": "Stadtwerke Klagenfurt AG",
      "isEjn": true
    },
    {
      "pk": 1992,
      "name": "Stadtwerke Kufstein GmbH",
      "isEjn": true
    },
    {
      "pk": 27682,
      "name": "Stadtwerke Landshut",
      "isEjn": true
    },
    {
      "pk": 19209,
      "name": "Stadtwerke Leinefelde-Worbis",
      "isEjn": true
    },
    {
      "pk": 1231,
      "name": "Stadtwerke Lindau (B) GmbH & Co. KG",
      "isEjn": true
    },
    {
      "pk": 25288,
      "name": "Stadtwerke Lingen GmbH",
      "isEjn": true
    },
    {
      "pk": 979,
      "name": "Stadtwerke Lippstadt",
      "isEjn": true
    },
    {
      "pk": 27681,
      "name": "Stadtwerke Ludwigsburg-Kornwestheim GmbH",
      "isEjn": true
    },
    {
      "pk": 1582,
      "name": "Stadtwerke Ludwigslust-Grabow",
      "isEjn": true
    },
    {
      "pk": 1075,
      "name": "Stadtwerke Lutherstadt Eisleben",
      "isEjn": true
    },
    {
      "pk": 27680,
      "name": "Stadtwerke Marburg",
      "isEjn": true
    },
    {
      "pk": 21684,
      "name": "Stadtwerke Münster GmbH",
      "isEjn": true
    },
    {
      "pk": 76,
      "name": "Stadtwerke Nortorf",
      "isEjn": true
    },
    {
      "pk": 37677,
      "name": "Stadtwerke Oerlinghausen GmbH",
      "isEjn": true
    },
    {
      "pk": 1032,
      "name": "Stadtwerke Pirna Energie GmbH",
      "isEjn": true
    },
    {
      "pk": 21679,
      "name": "Stadtwerke Rastatt GmbH",
      "isEjn": true
    },
    {
      "pk": 25858,
      "name": "Stadtwerke Ratingen GmbH",
      "isEjn": true
    },
    {
      "pk": 27350,
      "name": "Stadtwerke Recklinghausen GmbH",
      "isEjn": true
    },
    {
      "pk": 27683,
      "name": "Stadtwerke Saarlouis",
      "isEjn": true
    },
    {
      "pk": 27679,
      "name": "Stadtwerke Schwäbisch Hall",
      "isEjn": true
    },
    {
      "pk": 25843,
      "name": "Stadtwerke Schwarzenberg GmbH",
      "isEjn": true
    },
    {
      "pk": 1757,
      "name": "Stadtwerke Schwedt",
      "isEjn": true
    },
    {
      "pk": 3128,
      "name": "Stadtwerke Schweinfurt",
      "isEjn": true
    },
    {
      "pk": 27677,
      "name": "Stadtwerke Sindelfingen",
      "isEjn": true
    },
    {
      "pk": 25845,
      "name": "Stadtwerke Soest Energiedienstleistungs GmbH",
      "isEjn": true
    },
    {
      "pk": 21562,
      "name": "Stadtwerke Solingen GmbH",
      "isEjn": true
    },
    {
      "pk": 27674,
      "name": "Stadtwerke Stralsund",
      "isEjn": true
    },
    {
      "pk": 27,
      "name": "Stadtwerke Stuttgart",
      "isEjn": true
    },
    {
      "pk": 25635,
      "name": "Stadtwerke Troisdorf GmbH",
      "isEjn": true
    },
    {
      "pk": 21677,
      "name": "Stadtwerke Tübingen GmbH",
      "isEjn": true
    },
    {
      "pk": 26299,
      "name": "Stadtwerke Warendorf GmbH",
      "isEjn": true
    },
    {
      "pk": 27676,
      "name": "Stadtwerke Wasserburg am Inn",
      "isEjn": true
    },
    {
      "pk": 25870,
      "name": "Stadtwerke Weinheim GmbH",
      "isEjn": true
    },
    {
      "pk": 25844,
      "name": "Stadtwerke Wernigerode GmbH",
      "isEjn": true
    },
    {
      "pk": 3146,
      "name": "Stadtwerke Witten GmbH",
      "isEjn": true
    },
    {
      "pk": 30689,
      "name": "Station Marchand",
      "isEjn": true
    },
    {
      "pk": 32362,
      "name": "Station Service Medine",
      "isEjn": true
    },
    {
      "pk": 3415,
      "name": "Stations-e",
      "isEjn": true
    },
    {
      "pk": 37435,
      "name": "STAWAG",
      "isEjn": true
    },
    {
      "pk": 32884,
      "name": "Stener CPO",
      "isEjn": true
    },
    {
      "pk": 2698,
      "name": "Streetplug",
      "isEjn": true
    },
    {
      "pk": 27906,
      "name": "Strom + Gut GmbH",
      "isEjn": true
    },
    {
      "pk": 3727,
      "name": "Stromquelle Energietechnik GmbH",
      "isEjn": true
    },
    {
      "pk": 3827,
      "name": "Stroohm",
      "isEjn": true
    },
    {
      "pk": 22570,
      "name": "SÜC Energie und H2O",
      "isEjn": true
    },
    {
      "pk": 28211,
      "name": "Sunenergy",
      "isEjn": true
    },
    {
      "pk": 727,
      "name": "Super U",
      "isEjn": true
    },
    {
      "pk": 28242,
      "name": "Superareosa Supermercados, Lda",
      "isEjn": true
    },
    {
      "pk": 28199,
      "name": "Superfafe - Supermercados, Lda.",
      "isEjn": true
    },
    {
      "pk": 28207,
      "name": "Superguimerães",
      "isEjn": true
    },
    {
      "pk": 25864,
      "name": "Süwag Vertrieb AG & Co. KG",
      "isEjn": true
    },
    {
      "pk": 39429,
      "name": "Swarco Traffic Systems",
      "isEjn": true
    },
    {
      "pk": 12969,
      "name": "SWIO",
      "isEjn": true
    },
    {
      "pk": 3265,
      "name": "Swisscharge",
      "isEjn": true
    },
    {
      "pk": 40577,
      "name": "Switch EV GmbH",
      "isEjn": true
    },
    {
      "pk": 25855,
      "name": "SWK Energie GmbH",
      "isEjn": true
    },
    {
      "pk": 25869,
      "name": "SWN Stadtwerke Northeim GmbH",
      "isEjn": true
    },
    {
      "pk": 21678,
      "name": "SWP Stadtwerke Pforzheim GmbH & Co. KG",
      "isEjn": true
    },
    {
      "pk": 3306,
      "name": "SyDEV",
      "isEjn": true
    },
    {
      "pk": 3326,
      "name": "Syndicat d'Energie de l'Oise (SE60)",
      "isEjn": true
    },
    {
      "pk": 31297,
      "name": "Syndicat d’Energie du Val d’Oise (SDEVO)",
      "isEjn": true
    },
    {
      "pk": 3400,
      "name": "Syndicat d'Energie et des Déchets de Haute Marne (SDED52)",
      "isEjn": true
    },
    {
      "pk": 3670,
      "name": "Syndicat d'Energie Vauclusien (SEV84)",
      "isEjn": true
    },
    {
      "pk": 3381,
      "name": "Syndicat Départemental d'Énergie 35 (SDE35)",
      "isEjn": true
    },
    {
      "pk": 3314,
      "name": "Syndicat Départemental d'Energie de l’Aube (SDEA 10)",
      "isEjn": true
    },
    {
      "pk": 3333,
      "name": "Syndicat Départemental d'Energie de l'Yonne (SDEY89)",
      "isEjn": true
    },
    {
      "pk": 3368,
      "name": "Syndicat Départemental d'Energie de la Haute Garonne (SDEHG)",
      "isEjn": true
    },
    {
      "pk": 3363,
      "name": "Syndicat Départemental d'Energie de Loire-Atlantique (SYDELA 44)",
      "isEjn": true
    },
    {
      "pk": 3369,
      "name": "Syndicat Départemental d'Énergie de Tarn-et-Garonne (SDE82)",
      "isEjn": true
    },
    {
      "pk": 3325,
      "name": "Syndicat Départemental d'Énergie Des Côtes d'Armor (SDE22)",
      "isEjn": true
    },
    {
      "pk": 23124,
      "name": "Syndicat Départemental d'Énergie du Tarn (SDET)",
      "isEjn": true
    },
    {
      "pk": 3320,
      "name": "Syndicat Départemental d’Énergie et d’Équipement du Finistère (SDEF)",
      "isEjn": true
    },
    {
      "pk": 3330,
      "name": "Syndicat Départemental d'Energies de l'Indre (SDEI)",
      "isEjn": true
    },
    {
      "pk": 3366,
      "name": "Syndicat Départemental d'Énergies de la Manche (SDEM50)",
      "isEjn": true
    },
    {
      "pk": 3370,
      "name": "Syndicat Départemental d'Énergies du Gers (SDEG)",
      "isEjn": true
    },
    {
      "pk": 3480,
      "name": "Syndicat Départemental d'Énergies du Rhône (SYDER)",
      "isEjn": true
    },
    {
      "pk": 3412,
      "name": "Syndicat Départemental Des Énergies De Seine-Et-Marne (SDESM)",
      "isEjn": true
    },
    {
      "pk": 3416,
      "name": "Syndicat Intercommunal d'Electricité et de Gaz du puy-de-Dôme (SIEG)",
      "isEjn": true
    },
    {
      "pk": 3407,
      "name": "Syndicat intercommunal d'énergie du département de la Haute-Saône (SIED70)",
      "isEjn": true
    },
    {
      "pk": 3371,
      "name": "Syndicat Intercommunal de l'Electricité et du Gaz de l'Eure (SIEGE27)",
      "isEjn": true
    },
    {
      "pk": 3411,
      "name": "Syndicat intercommunal pour le gaz et l'électricité en Île-de-France (SIGEIF)",
      "isEjn": true
    },
    {
      "pk": 3328,
      "name": "Syndicat mixte d'Électricité du Doubs (SYDED)",
      "isEjn": true
    },
    {
      "pk": 3427,
      "name": "Syndicat Mixte D'Energie Du Département Des Bouches Du Rhone (SMED13)",
      "isEjn": true
    },
    {
      "pk": 3338,
      "name": "Syndicat Mixte Départemental d'Énergies du Calvados (SDEC Énergie)",
      "isEjn": true
    },
    {
      "pk": 3468,
      "name": "Syndicat Mixte des Transports du Bassin de Briey (ST2B)",
      "isEjn": true
    },
    {
      "pk": 3340,
      "name": "Système U",
      "isEjn": true
    },
    {
      "pk": 4088,
      "name": "T-Electric",
      "isEjn": true
    },
    {
      "pk": 17769,
      "name": "Tamoil",
      "isEjn": true
    },
    {
      "pk": 73,
      "name": "TankE GmbH",
      "isEjn": true
    },
    {
      "pk": 35171,
      "name": "Tanx",
      "isEjn": true
    },
    {
      "pk": 26933,
      "name": "Tap Electric",
      "isEjn": true
    },
    {
      "pk": 3681,
      "name": "Taubert Consulting GmbH",
      "isEjn": true
    },
    {
      "pk": 123,
      "name": "TEA",
      "isEjn": true
    },
    {
      "pk": 24976,
      "name": "Teag Mobil GmbH",
      "isEjn": true
    },
    {
      "pk": 25853,
      "name": "Team Emobility GmbH",
      "isEjn": true
    },
    {
      "pk": 3551,
      "name": "Techem Energy Services GmbH",
      "isEjn": true
    },
    {
      "pk": 21683,
      "name": "Technische Werke Ludwigshafen am Rhein Aktiengesellschaft",
      "isEjn": true
    },
    {
      "pk": 3527,
      "name": "Territoire d'Energie Alsace",
      "isEjn": true
    },
    {
      "pk": 3399,
      "name": "Territoire d'énergie Mayenne (SDE53)",
      "isEjn": true
    },
    {
      "pk": 3402,
      "name": "Territoire d'Energie Orne (TE61)",
      "isEjn": true
    },
    {
      "pk": 3425,
      "name": "Territoire de Belfort (TE90)",
      "isEjn": true
    },
    {
      "pk": 3565,
      "name": "Time2Plug",
      "isEjn": true
    },
    {
      "pk": 3662,
      "name": "TIWAG-Tiroler Wasserkraft AG",
      "isEjn": true
    },
    {
      "pk": 19129,
      "name": "Tønder Kommune",
      "isEjn": true
    },
    {
      "pk": 687,
      "name": "Total Energies",
      "isEjn": true
    },
    {
      "pk": 24176,
      "name": "TotalEnergies Charging Solutions Deutschland GmbH",
      "isEjn": true
    },
    {
      "pk": 21452,
      "name": "TotalEnergies Retail Nederland",
      "isEjn": true
    },
    {
      "pk": 1940,
      "name": "Toyota",
      "isEjn": true
    },
    {
      "pk": 28189,
      "name": "True-Kare",
      "isEjn": true
    },
    {
      "pk": 3990,
      "name": "TSG Polska",
      "isEjn": true
    },
    {
      "pk": 26162,
      "name": "TSG TEMA",
      "isEjn": true
    },
    {
      "pk": 383,
      "name": "Tulln Energie GmbH",
      "isEjn": true
    },
    {
      "pk": 15615,
      "name": "TÜV NORD Immobilien GmbH & Co. KG",
      "isEjn": true
    },
    {
      "pk": 20189,
      "name": "TÜV NORD Immobilien GmbH & Co. KG Hamburg",
      "isEjn": true
    },
    {
      "pk": 20325,
      "name": "TÜV NORD Immobilien GmbH & Co. KG Hannover",
      "isEjn": true
    },
    {
      "pk": 20262,
      "name": "Überlandwerk Groß-Gerau GmbH",
      "isEjn": true
    },
    {
      "pk": 28182,
      "name": "uCharge",
      "isEjn": true
    },
    {
      "pk": 3646,
      "name": "Ultra-Fast Charging Venture Scandinavia ApS",
      "isEjn": true
    },
    {
      "pk": 20328,
      "name": "Uno-X",
      "isEjn": true
    },
    {
      "pk": 392,
      "name": "Unternehmensverbund Schleswiger Stadtwerke",
      "isEjn": true
    },
    {
      "pk": 30757,
      "name": "Urban Fox",
      "isEjn": true
    },
    {
      "pk": 3313,
      "name": "USEDA",
      "isEjn": true
    },
    {
      "pk": 37578,
      "name": "V-CITY",
      "isEjn": true
    },
    {
      "pk": 19774,
      "name": "Vallée Carburants",
      "isEjn": true
    },
    {
      "pk": 35169,
      "name": "Van Dorp",
      "isEjn": true
    },
    {
      "pk": 23123,
      "name": "Van Kessel",
      "isEjn": true
    },
    {
      "pk": 3832,
      "name": "Van Leeuwen",
      "isEjn": true
    },
    {
      "pk": 28349,
      "name": "Vattenfall InCharge",
      "isEjn": true
    },
    {
      "pk": 28198,
      "name": "Veimonte",
      "isEjn": true
    },
    {
      "pk": 1314,
      "name": "Venema",
      "isEjn": true
    },
    {
      "pk": 1503,
      "name": "Versorgungsbetriebe Hann. Münden GmbH",
      "isEjn": true
    },
    {
      "pk": 25144,
      "name": "Versorgungsservice Main-Kinzig GmbH",
      "isEjn": true
    },
    {
      "pk": 30400,
      "name": "Viggo Energy",
      "isEjn": true
    },
    {
      "pk": 3678,
      "name": "Ville Garenne-Colombes",
      "isEjn": true
    },
    {
      "pk": 3625,
      "name": "Virta",
      "isEjn": true
    },
    {
      "pk": 4052,
      "name": "VISACASA",
      "isEjn": true
    },
    {
      "pk": 26158,
      "name": "VitaeMobility",
      "isEjn": true
    },
    {
      "pk": 16320,
      "name": "Vivavis Schweiz AG CPO",
      "isEjn": true
    },
    {
      "pk": 41307,
      "name": "Volt-Up",
      "isEjn": true
    },
    {
      "pk": 17878,
      "name": "Volta Charging France",
      "isEjn": true
    },
    {
      "pk": 3530,
      "name": "Volta Charging Germany",
      "isEjn": true
    },
    {
      "pk": 27670,
      "name": "VOLTI",
      "isEjn": true
    },
    {
      "pk": 19527,
      "name": "Voltic d.o.o. (CPO)",
      "isEjn": true
    },
    {
      "pk": 185,
      "name": "Voltiva",
      "isEjn": true
    },
    {
      "pk": 41050,
      "name": "VOLTIX",
      "isEjn": true
    },
    {
      "pk": 199,
      "name": "Volvo",
      "isEjn": true
    },
    {
      "pk": 38873,
      "name": "Volvo Charging",
      "isEjn": true
    },
    {
      "pk": 40542,
      "name": "Vourity",
      "isEjn": true
    },
    {
      "pk": 21167,
      "name": "VWEW-energie",
      "isEjn": true
    },
    {
      "pk": 33632,
      "name": "WAAT Umbrella-Copro",
      "isEjn": true
    },
    {
      "pk": 14991,
      "name": "Walter Müller GmbH & Co. KG",
      "isEjn": true
    },
    {
      "pk": 35069,
      "name": "Watèa by Michelin",
      "isEjn": true
    },
    {
      "pk": 16934,
      "name": "Wattif Europe GmbH",
      "isEjn": true
    },
    {
      "pk": 3423,
      "name": "Wattify",
      "isEjn": true
    },
    {
      "pk": 3469,
      "name": "WattzHub",
      "isEjn": true
    },
    {
      "pk": 4147,
      "name": "We Drive Solar",
      "isEjn": true
    },
    {
      "pk": 31035,
      "name": "We-Go",
      "isEjn": true
    },
    {
      "pk": 13863,
      "name": "WeDom.io",
      "isEjn": true
    },
    {
      "pk": 1030,
      "name": "WEMAG",
      "isEjn": true
    },
    {
      "pk": 22100,
      "name": "Wenea",
      "isEjn": true
    },
    {
      "pk": 30277,
      "name": "Wernsing Feinkost GmbH",
      "isEjn": true
    },
    {
      "pk": 28633,
      "name": "Wewise",
      "isEjn": true
    },
    {
      "pk": 28844,
      "name": "Whalesborough",
      "isEjn": true
    },
    {
      "pk": 816,
      "name": "Wien Energie",
      "isEjn": true
    },
    {
      "pk": 3661,
      "name": "Wirelane GmbH",
      "isEjn": true
    },
    {
      "pk": 1737,
      "name": "WISAG",
      "isEjn": true
    },
    {
      "pk": 28186,
      "name": "WOWPLUG",
      "isEjn": true
    },
    {
      "pk": 3472,
      "name": "Würth Elektrogroßhandel GmbH & Co.KG",
      "isEjn": true
    },
    {
      "pk": 26119,
      "name": "Würth Phönix",
      "isEjn": true
    },
    {
      "pk": 483,
      "name": "WVV Energie",
      "isEjn": true
    },
    {
      "pk": 1859,
      "name": "WWZ Energie AG",
      "isEjn": true
    },
    {
      "pk": 14241,
      "name": "YES55",
      "isEjn": true
    },
    {
      "pk": 3649,
      "name": "Západoslovenská energetika, a.s.",
      "isEjn": true
    },
    {
      "pk": 13764,
      "name": "Zapp",
      "isEjn": true
    },
    {
      "pk": 3317,
      "name": "Ze-Watt",
      "isEjn": true
    },
    {
      "pk": 3151,
      "name": "ZEborne Mobility Services",
      "isEjn": true
    },
    {
      "pk": 17802,
      "name": "ZEENCO e-mobility",
      "isEjn": true
    },
    {
      "pk": 26716,
      "name": "Zemovi",
      "isEjn": true
    },
    {
      "pk": 1397,
      "name": "Zephyre",
      "isEjn": true
    },
    {
      "pk": 19667,
      "name": "Zeppelin GmbH",
      "isEjn": true
    },
    {
      "pk": 19493,
      "name": "Zeppelin Rental GmbH",
      "isEjn": true
    },
    {
      "pk": 26164,
      "name": "Ziedewij",
      "isEjn": true
    },
    {
      "pk": 664,
      "name": "Zunder",
      "isEjn": true
    },
    {
      "pk": 41152,
      "name": " begin",
      "isEjn": false
    },
    {
      "pk": 32398,
      "name": " Régie Services Energie",
      "isEjn": false
    },
    {
      "pk": 37990,
      "name": " Stadtwerke Kufstein GmbH",
      "isEjn": false
    },
    {
      "pk": 14831,
      "name": " ZAK Energie GmbH",
      "isEjn": false
    },
    {
      "pk": 16434,
      "name": "17er Oberlandenergie",
      "isEjn": false
    },
    {
      "pk": 20050,
      "name": "3W Energy Solutions",
      "isEjn": false
    },
    {
      "pk": 13618,
      "name": "7-Charge (7-Eleven)",
      "isEjn": false
    },
    {
      "pk": 31151,
      "name": "A CASA Sölden Schrofenweg 2 6450 Sölden Österreich",
      "isEjn": false
    },
    {
      "pk": 40463,
      "name": "A&C Systems",
      "isEjn": false
    },
    {
      "pk": 20259,
      "name": "A&T2000",
      "isEjn": false
    },
    {
      "pk": 36026,
      "name": "A&W Real Estate Consulting GmbH",
      "isEjn": false
    },
    {
      "pk": 31034,
      "name": "Aalberts Duurzaam",
      "isEjn": false
    },
    {
      "pk": 5848,
      "name": "ABC Lataus",
      "isEjn": false
    },
    {
      "pk": 32557,
      "name": "ABC-lataus",
      "isEjn": false
    },
    {
      "pk": 36011,
      "name": "Aberg-Hinterthal-Bergbahnen AG",
      "isEjn": false
    },
    {
      "pk": 14952,
      "name": "ABF",
      "isEjn": false
    },
    {
      "pk": 20294,
      "name": "ABL",
      "isEjn": false
    },
    {
      "pk": 39498,
      "name": "ABM",
      "isEjn": false
    },
    {
      "pk": 32617,
      "name": "AC Stromtankstelle Rechts 22kW Links 22kW",
      "isEjn": false
    },
    {
      "pk": 32658,
      "name": "Acemo GmbH",
      "isEjn": false
    },
    {
      "pk": 25063,
      "name": "Acer Computer GmbH",
      "isEjn": false
    },
    {
      "pk": 40680,
      "name": "Achtung Schrankenanlage vorhanden, Parkplätze nur für Kunden OÖ Versicherung",
      "isEjn": false
    },
    {
      "pk": 41157,
      "name": "Acon-eco",
      "isEjn": false
    },
    {
      "pk": 37995,
      "name": "activiteitenboerderij Hammerslag",
      "isEjn": false
    },
    {
      "pk": 15574,
      "name": "ADNOC",
      "isEjn": false
    },
    {
      "pk": 20295,
      "name": "Adolf Weber",
      "isEjn": false
    },
    {
      "pk": 31134,
      "name": "Advantest Europe",
      "isEjn": false
    },
    {
      "pk": 36013,
      "name": "ADWALL Chalets GmbH",
      "isEjn": false
    },
    {
      "pk": 789,
      "name": "AE",
      "isEjn": false
    },
    {
      "pk": 16806,
      "name": "AE Charge Point",
      "isEjn": false
    },
    {
      "pk": 14487,
      "name": "AEW Energie AG",
      "isEjn": false
    },
    {
      "pk": 15467,
      "name": "Afcon",
      "isEjn": false
    },
    {
      "pk": 38733,
      "name": "Affärsverken",
      "isEjn": false
    },
    {
      "pk": 36049,
      "name": "Agentur Stirner GmbH",
      "isEjn": false
    },
    {
      "pk": 37187,
      "name": "AgeVolt",
      "isEjn": false
    },
    {
      "pk": 37329,
      "name": "AGI",
      "isEjn": false
    },
    {
      "pk": 13981,
      "name": "Ägidius Keferloh GmbH",
      "isEjn": false
    },
    {
      "pk": 12839,
      "name": "AGORA  Dhr. E. Boogaard",
      "isEjn": false
    },
    {
      "pk": 38152,
      "name": "AGRAVIS Ost GmbH & Co.KG",
      "isEjn": false
    },
    {
      "pk": 32623,
      "name": "AGRAVIS Raiffeisen AG",
      "isEjn": false
    },
    {
      "pk": 39151,
      "name": "Agravis Raiffeisen Tankstelle",
      "isEjn": false
    },
    {
      "pk": 37084,
      "name": "AGRIOPALE SERVICES",
      "isEjn": false
    },
    {
      "pk": 33911,
      "name": "AGROLA",
      "isEjn": false
    },
    {
      "pk": 1544,
      "name": "Ahorramas",
      "isEjn": false
    },
    {
      "pk": 32410,
      "name": "Ahrtal-Werke",
      "isEjn": false
    },
    {
      "pk": 34102,
      "name": "Aimo Charge",
      "isEjn": false
    },
    {
      "pk": 34018,
      "name": "Aimo Park",
      "isEjn": false
    },
    {
      "pk": 16007,
      "name": "Air Liquide",
      "isEjn": false
    },
    {
      "pk": 1916,
      "name": "Alabama Power",
      "isEjn": false
    },
    {
      "pk": 32627,
      "name": "Albin Knoflach und Mitbesitzer",
      "isEjn": false
    },
    {
      "pk": 16979,
      "name": "ALD AutoLeasing",
      "isEjn": false
    },
    {
      "pk": 13309,
      "name": "Alexander Haß",
      "isEjn": false
    },
    {
      "pk": 36387,
      "name": "Alexander Lechner",
      "isEjn": false
    },
    {
      "pk": 13057,
      "name": "Alexander Soyk e.K.",
      "isEjn": false
    },
    {
      "pk": 36120,
      "name": "All.Glemm Sky Suite",
      "isEjn": false
    },
    {
      "pk": 17456,
      "name": "AllElectric",
      "isEjn": false
    },
    {
      "pk": 12823,
      "name": "Allgaier Kunststoffverarbeitung GmbH & Co.KG",
      "isEjn": false
    },
    {
      "pk": 32486,
      "name": "Allgäuer Kraftwerke",
      "isEjn": false
    },
    {
      "pk": 32422,
      "name": "Allgäuer Überlandwerk",
      "isEjn": false
    },
    {
      "pk": 36076,
      "name": "Almdorf Bauträger GmbH",
      "isEjn": false
    },
    {
      "pk": 16811,
      "name": "Alnedeko Freiraum GmbH",
      "isEjn": false
    },
    {
      "pk": 12767,
      "name": "ALP EV",
      "isEjn": false
    },
    {
      "pk": 36129,
      "name": "Alp-Estate GmbH",
      "isEjn": false
    },
    {
      "pk": 36395,
      "name": "AlpenGast GmbH West & Co KG",
      "isEjn": false
    },
    {
      "pk": 34698,
      "name": "Alpengasthof Köfels",
      "isEjn": false
    },
    {
      "pk": 36042,
      "name": "Alpengasthof Kopphütte",
      "isEjn": false
    },
    {
      "pk": 36038,
      "name": "Alpengasthof Schlögelberger GmbH",
      "isEjn": false
    },
    {
      "pk": 36091,
      "name": "Alpenhaus Kesselfall",
      "isEjn": false
    },
    {
      "pk": 36018,
      "name": "Alpenhof-Annaberg",
      "isEjn": false
    },
    {
      "pk": 36121,
      "name": "Alpenhotels Großglockner Sauper GmbH",
      "isEjn": false
    },
    {
      "pk": 36085,
      "name": "AlpenParks Ferienresort Rehrenberg GmbH",
      "isEjn": false
    },
    {
      "pk": 36058,
      "name": "Alpenparks Hotel Sonnleiten GmbH",
      "isEjn": false
    },
    {
      "pk": 20285,
      "name": "Alpenrind GmbH",
      "isEjn": false
    },
    {
      "pk": 36093,
      "name": "Alpinresort Sport & Spa",
      "isEjn": false
    },
    {
      "pk": 13976,
      "name": "Alpla Werke Lehner GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 36112,
      "name": "Alps Residence Holidayservice GmbH",
      "isEjn": false
    },
    {
      "pk": 38768,
      "name": "Alstom Transport Deutschland",
      "isEjn": false
    },
    {
      "pk": 34413,
      "name": "Altervim",
      "isEjn": false
    },
    {
      "pk": 16430,
      "name": "ALtstadthotel \"Zum Hechten\" Schloßgasthof GmbH",
      "isEjn": false
    },
    {
      "pk": 32616,
      "name": "Altstadthotel Kneitinger e.K.",
      "isEjn": false
    },
    {
      "pk": 36976,
      "name": "AMAG",
      "isEjn": false
    },
    {
      "pk": 41447,
      "name": "AMAG Group AG",
      "isEjn": false
    },
    {
      "pk": 13347,
      "name": "Amano",
      "isEjn": false
    },
    {
      "pk": 36099,
      "name": "Amersports Austria GmbH",
      "isEjn": false
    },
    {
      "pk": 13675,
      "name": "Ammer-Loisach Energie",
      "isEjn": false
    },
    {
      "pk": 20249,
      "name": "AmpedUp!",
      "isEjn": false
    },
    {
      "pk": 20224,
      "name": "Ampere AG",
      "isEjn": false
    },
    {
      "pk": 12719,
      "name": "Amperio (DE)",
      "isEjn": false
    },
    {
      "pk": 15750,
      "name": "Ampol",
      "isEjn": false
    },
    {
      "pk": 1952,
      "name": "AmpUp",
      "isEjn": false
    },
    {
      "pk": 13722,
      "name": "Amt für Ernährung, Landwirtschaft und Forsten Roth",
      "isEjn": false
    },
    {
      "pk": 15425,
      "name": "Andreas Altenweisl",
      "isEjn": false
    },
    {
      "pk": 36381,
      "name": "Andreas Lederer - Lederer Boutique Hotel",
      "isEjn": false
    },
    {
      "pk": 32595,
      "name": "Andreas Meißner e.K. Poststrasse 2 08258 Markneukirchen Geramany",
      "isEjn": false
    },
    {
      "pk": 14948,
      "name": "Andreas Siller",
      "isEjn": false
    },
    {
      "pk": 14200,
      "name": "Andreas Zick",
      "isEjn": false
    },
    {
      "pk": 12769,
      "name": "AndRes Hotel Betriebs-GmbH",
      "isEjn": false
    },
    {
      "pk": 12846,
      "name": "Anleitner GmbH",
      "isEjn": false
    },
    {
      "pk": 15616,
      "name": "Anne-Kathrin Janke-Voels",
      "isEjn": false
    },
    {
      "pk": 26750,
      "name": "Anneliese Schneider",
      "isEjn": false
    },
    {
      "pk": 12847,
      "name": "Anton Henning",
      "isEjn": false
    },
    {
      "pk": 14938,
      "name": "Antonius von Boeselager",
      "isEjn": false
    },
    {
      "pk": 13042,
      "name": "Anykščių rajono savivaldybės administracija",
      "isEjn": false
    },
    {
      "pk": 32574,
      "name": "Apart Relax",
      "isEjn": false
    },
    {
      "pk": 38940,
      "name": "Appartement 11-302",
      "isEjn": false
    },
    {
      "pk": 36074,
      "name": "Appartment Kristall Langegger - Hollaus KG",
      "isEjn": false
    },
    {
      "pk": 39011,
      "name": "Appel & Peer",
      "isEjn": false
    },
    {
      "pk": 36019,
      "name": "AQUA Dome - Tirol Therme Längenfeld",
      "isEjn": false
    },
    {
      "pk": 36270,
      "name": "ARBÖ Bundesorganisations GmbH",
      "isEjn": false
    },
    {
      "pk": 25138,
      "name": "Arcona",
      "isEjn": false
    },
    {
      "pk": 27866,
      "name": "ArEs Besitz- und Verwaltungs GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 1823,
      "name": "Arinea",
      "isEjn": false
    },
    {
      "pk": 27831,
      "name": "ARK",
      "isEjn": false
    },
    {
      "pk": 25057,
      "name": "Armatec",
      "isEjn": false
    },
    {
      "pk": 16807,
      "name": "Arno Brandenburger",
      "isEjn": false
    },
    {
      "pk": 23854,
      "name": "Aros Auto Västerås",
      "isEjn": false
    },
    {
      "pk": 35664,
      "name": "ARR",
      "isEjn": false
    },
    {
      "pk": 36029,
      "name": "Arte Hotel Salzburg",
      "isEjn": false
    },
    {
      "pk": 16422,
      "name": "Aschaffenburger Versorgungs",
      "isEjn": false
    },
    {
      "pk": 14763,
      "name": "Ascherwirt Gasthof & Pension",
      "isEjn": false
    },
    {
      "pk": 1242,
      "name": "ASEM Mobility",
      "isEjn": false
    },
    {
      "pk": 21755,
      "name": "Assaí",
      "isEjn": false
    },
    {
      "pk": 12765,
      "name": "Assay Immobiliengesellschaft mbH",
      "isEjn": false
    },
    {
      "pk": 12829,
      "name": "Astenkick GmbH",
      "isEjn": false
    },
    {
      "pk": 25134,
      "name": "Astor",
      "isEjn": false
    },
    {
      "pk": 38794,
      "name": "ASW Wahl",
      "isEjn": false
    },
    {
      "pk": 12787,
      "name": "ASW Wahl ",
      "isEjn": false
    },
    {
      "pk": 36078,
      "name": "Atomic Austria GmbH",
      "isEjn": false
    },
    {
      "pk": 1459,
      "name": "AUDI",
      "isEjn": false
    },
    {
      "pk": 32576,
      "name": "Auer Bau GmbH",
      "isEjn": false
    },
    {
      "pk": 13195,
      "name": "AUG. PRIEN Bauunternehmung (GmbH & Co. KG)",
      "isEjn": false
    },
    {
      "pk": 34935,
      "name": "AUGUSTJAEGER",
      "isEjn": false
    },
    {
      "pk": 32586,
      "name": "Auktion & Markt AG",
      "isEjn": false
    },
    {
      "pk": 32672,
      "name": "AULESO GmbH",
      "isEjn": false
    },
    {
      "pk": 19207,
      "name": "Austria Alpinhotel",
      "isEjn": false
    },
    {
      "pk": 34414,
      "name": "AUT",
      "isEjn": false
    },
    {
      "pk": 39148,
      "name": "Autel",
      "isEjn": false
    },
    {
      "pk": 31984,
      "name": "Auto Eberhaut",
      "isEjn": false
    },
    {
      "pk": 36012,
      "name": "Auto Huber GmbH",
      "isEjn": false
    },
    {
      "pk": 16968,
      "name": "Auto Singer GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 25775,
      "name": "Auto Walter",
      "isEjn": false
    },
    {
      "pk": 21719,
      "name": "Auto-Leebmann GmbH",
      "isEjn": false
    },
    {
      "pk": 32585,
      "name": "Auto-Mobil-Funk",
      "isEjn": false
    },
    {
      "pk": 13053,
      "name": "Auto-Schmid GmbH",
      "isEjn": false
    },
    {
      "pk": 18997,
      "name": "Autocenter Altmittweida GmbH",
      "isEjn": false
    },
    {
      "pk": 35665,
      "name": "Autocharge",
      "isEjn": false
    },
    {
      "pk": 16946,
      "name": "Autohaus Becker",
      "isEjn": false
    },
    {
      "pk": 16945,
      "name": "Autohaus Berger ",
      "isEjn": false
    },
    {
      "pk": 32397,
      "name": "Autohaus Bonefeld",
      "isEjn": false
    },
    {
      "pk": 16948,
      "name": "Autohaus Bothe",
      "isEjn": false
    },
    {
      "pk": 32582,
      "name": "Autohaus Brucker GmbH",
      "isEjn": false
    },
    {
      "pk": 16966,
      "name": "Autohaus Burkhart GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 16929,
      "name": "Autohaus Dobner GmbH",
      "isEjn": false
    },
    {
      "pk": 17923,
      "name": "Autohaus Dorn GmbH",
      "isEjn": false
    },
    {
      "pk": 16950,
      "name": "Autohaus Fischer",
      "isEjn": false
    },
    {
      "pk": 32474,
      "name": "Autohaus Friedmann ",
      "isEjn": false
    },
    {
      "pk": 15892,
      "name": "Autohaus Fritze GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 16977,
      "name": "Autohaus Fuchs",
      "isEjn": false
    },
    {
      "pk": 38797,
      "name": "Autohaus Grötzinger",
      "isEjn": false
    },
    {
      "pk": 13668,
      "name": "Autohaus Grötzinger ",
      "isEjn": false
    },
    {
      "pk": 16949,
      "name": "Autohaus Gruber GmbH",
      "isEjn": false
    },
    {
      "pk": 32494,
      "name": "Autohaus Hansa Nord GmbH",
      "isEjn": false
    },
    {
      "pk": 16423,
      "name": "Autohaus Heuberger GmbH",
      "isEjn": false
    },
    {
      "pk": 36073,
      "name": "Autohaus Hollin GmbH & Co KG",
      "isEjn": false
    },
    {
      "pk": 13663,
      "name": "Autohaus Homrich und Neike GmbH",
      "isEjn": false
    },
    {
      "pk": 13674,
      "name": "Autohaus Joas OHG",
      "isEjn": false
    },
    {
      "pk": 16969,
      "name": "Autohaus Kainz",
      "isEjn": false
    },
    {
      "pk": 14023,
      "name": "Autohaus Kalcher",
      "isEjn": false
    },
    {
      "pk": 12785,
      "name": "Autohaus Kaltenbach",
      "isEjn": false
    },
    {
      "pk": 16982,
      "name": "Autohaus Kaltenbach GmbH & Co.KG",
      "isEjn": false
    },
    {
      "pk": 16959,
      "name": "Autohaus Karl Moser GmbH",
      "isEjn": false
    },
    {
      "pk": 13677,
      "name": "Autohaus Kathan",
      "isEjn": false
    },
    {
      "pk": 12788,
      "name": "Autohaus Keller",
      "isEjn": false
    },
    {
      "pk": 14934,
      "name": "Autohaus Kirschstein",
      "isEjn": false
    },
    {
      "pk": 16980,
      "name": "Autohaus Klaus Schmitt e.K.",
      "isEjn": false
    },
    {
      "pk": 36536,
      "name": "Autohaus Körner GmbH",
      "isEjn": false
    },
    {
      "pk": 36355,
      "name": "Autohaus Kreidenhuber GmbH & Co.KG",
      "isEjn": false
    },
    {
      "pk": 16952,
      "name": "Autohaus Kühnert GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 16964,
      "name": "Autohaus Langlinderer ",
      "isEjn": false
    },
    {
      "pk": 38763,
      "name": "Autohaus Lehmann",
      "isEjn": false
    },
    {
      "pk": 38765,
      "name": "Autohaus Matthes",
      "isEjn": false
    },
    {
      "pk": 12786,
      "name": "Autohaus Michael Schmidt GmbH",
      "isEjn": false
    },
    {
      "pk": 14945,
      "name": "Autohaus MKM Huber GmbH",
      "isEjn": false
    },
    {
      "pk": 16421,
      "name": "Autohaus Müller GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 13667,
      "name": "Autohaus Netuschil",
      "isEjn": false
    },
    {
      "pk": 16974,
      "name": "Autohaus Nidda GmbH",
      "isEjn": false
    },
    {
      "pk": 38516,
      "name": "Autohaus Nobis",
      "isEjn": false
    },
    {
      "pk": 32395,
      "name": "Autohaus Nobis ",
      "isEjn": false
    },
    {
      "pk": 16947,
      "name": "Autohaus Ost GmbH",
      "isEjn": false
    },
    {
      "pk": 38793,
      "name": "Autohaus Pagel",
      "isEjn": false
    },
    {
      "pk": 19205,
      "name": "Autohaus Pichel GmbH Chemnitz",
      "isEjn": false
    },
    {
      "pk": 32629,
      "name": "Autohaus Pichel GmbH Chemnitz Blankenburgstraße 67/75 09114 Chemnitz",
      "isEjn": false
    },
    {
      "pk": 18672,
      "name": "Autohaus Pichel GmbH ChemnitzBlankenburgstraße 67/7509114 Chemnitz",
      "isEjn": false
    },
    {
      "pk": 16965,
      "name": "Autohaus Praller ",
      "isEjn": false
    },
    {
      "pk": 38799,
      "name": "Autohaus Reisacher",
      "isEjn": false
    },
    {
      "pk": 13676,
      "name": "Autohaus Reisacher ",
      "isEjn": false
    },
    {
      "pk": 16940,
      "name": "Autohaus Rolf Horn ",
      "isEjn": false
    },
    {
      "pk": 32637,
      "name": "Autohaus Rüf GmbH & Co KG",
      "isEjn": false
    },
    {
      "pk": 16970,
      "name": "Autohaus Schaal ",
      "isEjn": false
    },
    {
      "pk": 16971,
      "name": "Autohaus Schmid ",
      "isEjn": false
    },
    {
      "pk": 13666,
      "name": "Autohaus Schneider ",
      "isEjn": false
    },
    {
      "pk": 16951,
      "name": "Autohaus Scholtes GmbH",
      "isEjn": false
    },
    {
      "pk": 17725,
      "name": "Autohaus Seitz GmbH",
      "isEjn": false
    },
    {
      "pk": 16953,
      "name": "Autohaus Siedle GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 38760,
      "name": "Autohaus Sperber",
      "isEjn": false
    },
    {
      "pk": 16988,
      "name": "Autohaus Stahmer ",
      "isEjn": false
    },
    {
      "pk": 13664,
      "name": "Autohaus Stark ",
      "isEjn": false
    },
    {
      "pk": 29846,
      "name": "Autohaus Stöber Witzenhausen",
      "isEjn": false
    },
    {
      "pk": 38770,
      "name": "Autohaus Strobel",
      "isEjn": false
    },
    {
      "pk": 16962,
      "name": "Autohaus Thaler ",
      "isEjn": false
    },
    {
      "pk": 38796,
      "name": "Autohaus Tönnemann",
      "isEjn": false
    },
    {
      "pk": 15891,
      "name": "Autohaus Wako GmbH",
      "isEjn": false
    },
    {
      "pk": 38754,
      "name": "Autohaus Walkenhorst",
      "isEjn": false
    },
    {
      "pk": 13670,
      "name": "Autohaus Walter Mulfinger ",
      "isEjn": false
    },
    {
      "pk": 38756,
      "name": "Autohaus Weissenfels",
      "isEjn": false
    },
    {
      "pk": 13679,
      "name": "Autohaus Widmann & Winterholler ",
      "isEjn": false
    },
    {
      "pk": 34700,
      "name": "Autohaus Winkler",
      "isEjn": false
    },
    {
      "pk": 38795,
      "name": "Autohaus Winter",
      "isEjn": false
    },
    {
      "pk": 13681,
      "name": "Autohaus Winter ",
      "isEjn": false
    },
    {
      "pk": 16976,
      "name": "Autohaus Woldegk GmbH",
      "isEjn": false
    },
    {
      "pk": 38764,
      "name": "Autohaus Wormser",
      "isEjn": false
    },
    {
      "pk": 13671,
      "name": "Autohaus Wormser ",
      "isEjn": false
    },
    {
      "pk": 38767,
      "name": "Autohaus Zeimet",
      "isEjn": false
    },
    {
      "pk": 15098,
      "name": "Autohaus Zender",
      "isEjn": false
    },
    {
      "pk": 22168,
      "name": "Autoliitto",
      "isEjn": false
    },
    {
      "pk": 13672,
      "name": "AUTOMAG GmbH",
      "isEjn": false
    },
    {
      "pk": 16808,
      "name": "Autopark Ost Fichtner Gmbh",
      "isEjn": false
    },
    {
      "pk": 27135,
      "name": "Autostrom Einert",
      "isEjn": false
    },
    {
      "pk": 20276,
      "name": "AUW",
      "isEjn": false
    },
    {
      "pk": 37324,
      "name": "AV Germania Markneukirchen e.V. Schützenstr. 49 08258 Markneukirchen",
      "isEjn": false
    },
    {
      "pk": 14124,
      "name": "Avea GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 39985,
      "name": "AVI",
      "isEjn": false
    },
    {
      "pk": 3357,
      "name": "AVP Autoland",
      "isEjn": false
    },
    {
      "pk": 32573,
      "name": "AVP Autoland GmbH&Co.KG AVP E-Mobility Dr.-Wandinger-Straße 7 94447 Plattling",
      "isEjn": false
    },
    {
      "pk": 38805,
      "name": "AWO Fachkrankenhaus Jerichow",
      "isEjn": false
    },
    {
      "pk": 23580,
      "name": "AWO Fachkrankenhaus Jerichow ",
      "isEjn": false
    },
    {
      "pk": 14832,
      "name": "AWO gGmbH",
      "isEjn": false
    },
    {
      "pk": 14485,
      "name": "Axel Heidemann",
      "isEjn": false
    },
    {
      "pk": 36040,
      "name": "Axel Menke",
      "isEjn": false
    },
    {
      "pk": 12964,
      "name": "Axel Schulz",
      "isEjn": false
    },
    {
      "pk": 15593,
      "name": "AXION",
      "isEjn": false
    },
    {
      "pk": 15466,
      "name": "Azpetrol",
      "isEjn": false
    },
    {
      "pk": 29332,
      "name": "B-Charge",
      "isEjn": false
    },
    {
      "pk": 36044,
      "name": "Bachgut GmbH",
      "isEjn": false
    },
    {
      "pk": 27661,
      "name": "Bachmanngut GmbH",
      "isEjn": false
    },
    {
      "pk": 36107,
      "name": "Bäckerei Christian Binggl e.U.",
      "isEjn": false
    },
    {
      "pk": 32436,
      "name": "Bad Gleichenberg Energie",
      "isEjn": false
    },
    {
      "pk": 32438,
      "name": "Bad Gleichenberger Energie",
      "isEjn": false
    },
    {
      "pk": 15107,
      "name": "Bail GbR",
      "isEjn": false
    },
    {
      "pk": 14940,
      "name": "BÄKO Schleswig-Holstein eG",
      "isEjn": false
    },
    {
      "pk": 30724,
      "name": "Bauder Ges.m.b.H.",
      "isEjn": false
    },
    {
      "pk": 15610,
      "name": "Bauer Solarstrom GbR",
      "isEjn": false
    },
    {
      "pk": 36009,
      "name": "Bauträgermodell",
      "isEjn": false
    },
    {
      "pk": 32643,
      "name": "Bauwaren Canal GmbH & Co-KG.",
      "isEjn": false
    },
    {
      "pk": 13584,
      "name": "Bayerischer Hof",
      "isEjn": false
    },
    {
      "pk": 22295,
      "name": "Bayerisches Staatsbad Bad Steben GmbH",
      "isEjn": false
    },
    {
      "pk": 29708,
      "name": "BAYERNOIL Raffineriegesellschaft mbH",
      "isEjn": false
    },
    {
      "pk": 33764,
      "name": "bayernwerk e-mobil Ladestation Gemeinde Waldbüttelbrunn",
      "isEjn": false
    },
    {
      "pk": 32674,
      "name": "bayernwerk e-mobil.",
      "isEjn": false
    },
    {
      "pk": 32428,
      "name": "bayernwerk Regio Energie GmbH",
      "isEjn": false
    },
    {
      "pk": 36804,
      "name": "Bayerwaldhof",
      "isEjn": false
    },
    {
      "pk": 35666,
      "name": "BayWa Mobility Solutions GmbH.",
      "isEjn": false
    },
    {
      "pk": 32655,
      "name": "BCS GmbH & Co KG",
      "isEjn": false
    },
    {
      "pk": 15284,
      "name": "Be blauwe lantaarn",
      "isEjn": false
    },
    {
      "pk": 27903,
      "name": "be energised",
      "isEjn": false
    },
    {
      "pk": 38003,
      "name": "Beach house 471 Roompot",
      "isEjn": false
    },
    {
      "pk": 34051,
      "name": "Bee Charged",
      "isEjn": false
    },
    {
      "pk": 16810,
      "name": "Beefull",
      "isEjn": false
    },
    {
      "pk": 16249,
      "name": "beepbeep",
      "isEjn": false
    },
    {
      "pk": 32563,
      "name": "BeeV",
      "isEjn": false
    },
    {
      "pk": 19599,
      "name": "Belectric Drive",
      "isEjn": false
    },
    {
      "pk": 16987,
      "name": "BELKAW",
      "isEjn": false
    },
    {
      "pk": 25777,
      "name": "Benzin-Kontor AG",
      "isEjn": false
    },
    {
      "pk": 23581,
      "name": "BEP",
      "isEjn": false
    },
    {
      "pk": 32480,
      "name": "Berg- und Jagdhotel Gabelbach",
      "isEjn": false
    },
    {
      "pk": 36345,
      "name": "Bergbahnen Filzmoos GmbH",
      "isEjn": false
    },
    {
      "pk": 38740,
      "name": "Bergen Parkering KF",
      "isEjn": false
    },
    {
      "pk": 36304,
      "name": "Berger's Sporthotel GmbH",
      "isEjn": false
    },
    {
      "pk": 18996,
      "name": "Berghof Fluoroplastic",
      "isEjn": false
    },
    {
      "pk": 22061,
      "name": "Bergners Bil",
      "isEjn": false
    },
    {
      "pk": 12783,
      "name": "Bergwiese Immobilien und Touristik",
      "isEjn": false
    },
    {
      "pk": 38525,
      "name": "Bernd Roßmann",
      "isEjn": false
    },
    {
      "pk": 32324,
      "name": "Berufskolleg Gladbeck",
      "isEjn": false
    },
    {
      "pk": 24667,
      "name": "Best CPO - We have no faults and therefore need no hotline",
      "isEjn": false
    },
    {
      "pk": 32665,
      "name": "Bestattungshaus Rost & Rost",
      "isEjn": false
    },
    {
      "pk": 12770,
      "name": "Bettina Nowarre",
      "isEjn": false
    },
    {
      "pk": 32404,
      "name": "BEW Bergische Energie und Wasser",
      "isEjn": false
    },
    {
      "pk": 28383,
      "name": "BIB",
      "isEjn": false
    },
    {
      "pk": 21239,
      "name": "Biberger Immobilien GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 1796,
      "name": "Billa",
      "isEjn": false
    },
    {
      "pk": 36399,
      "name": "Biowärme Saalhof reg. Gen.m.b.H",
      "isEjn": false
    },
    {
      "pk": 16432,
      "name": "Birgit Bellmann",
      "isEjn": false
    },
    {
      "pk": 38001,
      "name": "BIT BV",
      "isEjn": false
    },
    {
      "pk": 37611,
      "name": "Bitte beachten Sie die Beschilderung vor Ort, um mögliche Parkgebühren zu vermeiden",
      "isEjn": false
    },
    {
      "pk": 18988,
      "name": "Björkmans Bil",
      "isEjn": false
    },
    {
      "pk": 40364,
      "name": "BKMZ",
      "isEjn": false
    },
    {
      "pk": 31712,
      "name": "BKP GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 38782,
      "name": "Blitz Power",
      "isEjn": false
    },
    {
      "pk": 16983,
      "name": "Blomberger Versorgungsbetriebe",
      "isEjn": false
    },
    {
      "pk": 14,
      "name": "Blue Current",
      "isEjn": false
    },
    {
      "pk": 34412,
      "name": "Blue SG",
      "isEjn": false
    },
    {
      "pk": 864,
      "name": "BMW",
      "isEjn": false
    },
    {
      "pk": 16944,
      "name": "BOGAMA - Bopparder-Garten-Fachmarkt",
      "isEjn": false
    },
    {
      "pk": 21168,
      "name": "Bonn/Rhein-Sieg",
      "isEjn": false
    },
    {
      "pk": 12737,
      "name": "BonpreuEsclat",
      "isEjn": false
    },
    {
      "pk": 13054,
      "name": "Borne non accessible au public extérieur au camping de 22h à 9h",
      "isEjn": false
    },
    {
      "pk": 38008,
      "name": "Bos elektrotechniek B.V.",
      "isEjn": false
    },
    {
      "pk": 1624,
      "name": "Boston Borough Council",
      "isEjn": false
    },
    {
      "pk": 3883,
      "name": "BP France",
      "isEjn": false
    },
    {
      "pk": 6893,
      "name": "BP Pulse",
      "isEjn": false
    },
    {
      "pk": 14236,
      "name": "BPP",
      "isEjn": false
    },
    {
      "pk": 16985,
      "name": "BPW Bergische Achsen",
      "isEjn": false
    },
    {
      "pk": 21752,
      "name": "BR Super Carga",
      "isEjn": false
    },
    {
      "pk": 29710,
      "name": "Brabants Afval Team",
      "isEjn": false
    },
    {
      "pk": 12814,
      "name": "Brasserij Offcourse",
      "isEjn": false
    },
    {
      "pk": 36096,
      "name": "Bräurup GmbH & Co KG",
      "isEjn": false
    },
    {
      "pk": 38148,
      "name": "Bredaseweg 85",
      "isEjn": false
    },
    {
      "pk": 36122,
      "name": "Bremsen Eder Amerhauser",
      "isEjn": false
    },
    {
      "pk": 37222,
      "name": "BRIELMANN",
      "isEjn": false
    },
    {
      "pk": 17962,
      "name": "Brigitte Loferer",
      "isEjn": false
    },
    {
      "pk": 30392,
      "name": "Brüder Rath Steinbrüche GmbH",
      "isEjn": false
    },
    {
      "pk": 40363,
      "name": "BRW DEV",
      "isEjn": false
    },
    {
      "pk": 32615,
      "name": "BSV-Mountain",
      "isEjn": false
    },
    {
      "pk": 20283,
      "name": "BSW",
      "isEjn": false
    },
    {
      "pk": 16431,
      "name": "Buchberger Energiedienstleistungen",
      "isEjn": false
    },
    {
      "pk": 12960,
      "name": "Budimex Mobility",
      "isEjn": false
    },
    {
      "pk": 12840,
      "name": "Buitengoed de Boomgaard",
      "isEjn": false
    },
    {
      "pk": 13307,
      "name": "Burton Sportartikel GmbH",
      "isEjn": false
    },
    {
      "pk": 20310,
      "name": "BUS",
      "isEjn": false
    },
    {
      "pk": 20311,
      "name": "Buscher Service GmbH",
      "isEjn": false
    },
    {
      "pk": 38010,
      "name": "Business School",
      "isEjn": false
    },
    {
      "pk": 15283,
      "name": "Buss & Bohlen OHG",
      "isEjn": false
    },
    {
      "pk": 37330,
      "name": "Buttinger Blumenhaus",
      "isEjn": false
    },
    {
      "pk": 1376,
      "name": "BYD",
      "isEjn": false
    },
    {
      "pk": 38632,
      "name": "Bystronic Maschinenbau GmbH",
      "isEjn": false
    },
    {
      "pk": 27240,
      "name": "BZG Pustertal",
      "isEjn": false
    },
    {
      "pk": 38631,
      "name": "C.Christophel GmbH",
      "isEjn": false
    },
    {
      "pk": 5678,
      "name": "Cable Energia",
      "isEjn": false
    },
    {
      "pk": 12940,
      "name": "CABO-E",
      "isEjn": false
    },
    {
      "pk": 32592,
      "name": "Cafe K2",
      "isEjn": false
    },
    {
      "pk": 20238,
      "name": "Camer",
      "isEjn": false
    },
    {
      "pk": 12816,
      "name": "Camping Agri-Nova",
      "isEjn": false
    },
    {
      "pk": 12842,
      "name": "Camping Muralt",
      "isEjn": false
    },
    {
      "pk": 12750,
      "name": "Camping Roelage",
      "isEjn": false
    },
    {
      "pk": 14126,
      "name": "Camping Seespitz",
      "isEjn": false
    },
    {
      "pk": 33089,
      "name": "Camping Stadlerhof",
      "isEjn": false
    },
    {
      "pk": 13059,
      "name": "Campingplatz Königsbuch",
      "isEjn": false
    },
    {
      "pk": 25132,
      "name": "Carbonage",
      "isEjn": false
    },
    {
      "pk": 20312,
      "name": "Carl Knauber Holding GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 16941,
      "name": "Carpus+Partner",
      "isEjn": false
    },
    {
      "pk": 12821,
      "name": "Carsten Schwarz",
      "isEjn": false
    },
    {
      "pk": 12810,
      "name": "Carsten Wehrmann ",
      "isEjn": false
    },
    {
      "pk": 34053,
      "name": "CasaCharge",
      "isEjn": false
    },
    {
      "pk": 36094,
      "name": "Cascade Empire Immobilien GmbH",
      "isEjn": false
    },
    {
      "pk": 37946,
      "name": "Castle Energy",
      "isEjn": false
    },
    {
      "pk": 15503,
      "name": "CATEC",
      "isEjn": false
    },
    {
      "pk": 15613,
      "name": "Cay Hüneke",
      "isEjn": false
    },
    {
      "pk": 32472,
      "name": "CC – Vitry Champagne et Der",
      "isEjn": false
    },
    {
      "pk": 38006,
      "name": "CCS2",
      "isEjn": false
    },
    {
      "pk": 34411,
      "name": "CDG ENGIE",
      "isEjn": false
    },
    {
      "pk": 32600,
      "name": "Cerealps GmbH",
      "isEjn": false
    },
    {
      "pk": 35258,
      "name": "CEZ",
      "isEjn": false
    },
    {
      "pk": 15603,
      "name": "CFE",
      "isEjn": false
    },
    {
      "pk": 15584,
      "name": "CGE",
      "isEjn": false
    },
    {
      "pk": 36015,
      "name": "Chalet Dorfkristall Robert und Caramieke Addink",
      "isEjn": false
    },
    {
      "pk": 36067,
      "name": "Chalet Eben",
      "isEjn": false
    },
    {
      "pk": 38336,
      "name": "Chalet ELKHORN",
      "isEjn": false
    },
    {
      "pk": 36290,
      "name": "Chalets Filzmooserhof GmbH",
      "isEjn": false
    },
    {
      "pk": 709,
      "name": "Char.gy",
      "isEjn": false
    },
    {
      "pk": 2116,
      "name": "Charge & Drive",
      "isEjn": false
    },
    {
      "pk": 11548,
      "name": "Charge My Ride (Malta)",
      "isEjn": false
    },
    {
      "pk": 23714,
      "name": "Charge Now",
      "isEjn": false
    },
    {
      "pk": 33224,
      "name": "Charge Pro",
      "isEjn": false
    },
    {
      "pk": 37327,
      "name": "Charge to Move Härnösand",
      "isEjn": false
    },
    {
      "pk": 37328,
      "name": "Charge to Move Vimmerby",
      "isEjn": false
    },
    {
      "pk": 695,
      "name": "Charge Your Car",
      "isEjn": false
    },
    {
      "pk": 39155,
      "name": "Charge Zone",
      "isEjn": false
    },
    {
      "pk": 20252,
      "name": "Charge-ON",
      "isEjn": false
    },
    {
      "pk": 12739,
      "name": "charge.direct",
      "isEjn": false
    },
    {
      "pk": 15496,
      "name": "Charge+",
      "isEjn": false
    },
    {
      "pk": 15586,
      "name": "Chargebox net",
      "isEjn": false
    },
    {
      "pk": 15594,
      "name": "ChargeboxNet",
      "isEjn": false
    },
    {
      "pk": 34660,
      "name": "ChargEco",
      "isEjn": false
    },
    {
      "pk": 20321,
      "name": "Chargee",
      "isEjn": false
    },
    {
      "pk": 2273,
      "name": "ChargeFox",
      "isEjn": false
    },
    {
      "pk": 25387,
      "name": "ChargeHub",
      "isEjn": false
    },
    {
      "pk": 39150,
      "name": "ChargeIt",
      "isEjn": false
    },
    {
      "pk": 1896,
      "name": "ChargeLab",
      "isEjn": false
    },
    {
      "pk": 34054,
      "name": "ChargeN’Go",
      "isEjn": false
    },
    {
      "pk": 2141,
      "name": "ChargeNet",
      "isEjn": false
    },
    {
      "pk": 19316,
      "name": "ChargeNode",
      "isEjn": false
    },
    {
      "pk": 32953,
      "name": "ChargePanel",
      "isEjn": false
    },
    {
      "pk": 14275,
      "name": "Chargepoly (FR)",
      "isEjn": false
    },
    {
      "pk": 19563,
      "name": "CHARGERSPOT PROSTA S.A.",
      "isEjn": false
    },
    {
      "pk": 20248,
      "name": "ChargeShare",
      "isEjn": false
    },
    {
      "pk": 28527,
      "name": "ChargeSmart EV",
      "isEjn": false
    },
    {
      "pk": 41154,
      "name": "ChargeSmith",
      "isEjn": false
    },
    {
      "pk": 2288,
      "name": "Chargestar",
      "isEjn": false
    },
    {
      "pk": 38798,
      "name": "ChargeStop",
      "isEjn": false
    },
    {
      "pk": 13683,
      "name": "ChargeStop ",
      "isEjn": false
    },
    {
      "pk": 12759,
      "name": "Chargetech",
      "isEjn": false
    },
    {
      "pk": 30188,
      "name": "Chargey",
      "isEjn": false
    },
    {
      "pk": 20228,
      "name": "ChargeZone",
      "isEjn": false
    },
    {
      "pk": 18262,
      "name": "Chargie",
      "isEjn": false
    },
    {
      "pk": 41159,
      "name": "Charginn",
      "isEjn": false
    },
    {
      "pk": 29334,
      "name": "Chesa Monte",
      "isEjn": false
    },
    {
      "pk": 15462,
      "name": "Chilquinta",
      "isEjn": false
    },
    {
      "pk": 36340,
      "name": "Chris Webb - Mulk GmbH + Co KG",
      "isEjn": false
    },
    {
      "pk": 14936,
      "name": "Christian Lühmann GmbH",
      "isEjn": false
    },
    {
      "pk": 23511,
      "name": "Christian Reindl",
      "isEjn": false
    },
    {
      "pk": 18374,
      "name": "Christian Sager",
      "isEjn": false
    },
    {
      "pk": 13440,
      "name": "Christiane Schuberth",
      "isEjn": false
    },
    {
      "pk": 12852,
      "name": "Christiane Steinmetz",
      "isEjn": false
    },
    {
      "pk": 36101,
      "name": "Christine Parzermair",
      "isEjn": false
    },
    {
      "pk": 36315,
      "name": "Christine WeberSeegasthof Gamsjaga",
      "isEjn": false
    },
    {
      "pk": 36389,
      "name": "Christof Mayr",
      "isEjn": false
    },
    {
      "pk": 137,
      "name": "Circle K",
      "isEjn": false
    },
    {
      "pk": 32532,
      "name": "Circontrol",
      "isEjn": false
    },
    {
      "pk": 38521,
      "name": "Circutor",
      "isEjn": false
    },
    {
      "pk": 704,
      "name": "CitiPark",
      "isEjn": false
    },
    {
      "pk": 12905,
      "name": "City Center Amstetten GmbH",
      "isEjn": false
    },
    {
      "pk": 41164,
      "name": "City Charging",
      "isEjn": false
    },
    {
      "pk": 32555,
      "name": "CityCharging",
      "isEjn": false
    },
    {
      "pk": 30507,
      "name": "CK0",
      "isEjn": false
    },
    {
      "pk": 39154,
      "name": "Clean Energy ",
      "isEjn": false
    },
    {
      "pk": 15582,
      "name": "Colbun",
      "isEjn": false
    },
    {
      "pk": 3384,
      "name": "Communauté d'Agglomération Paris-Saclay",
      "isEjn": false
    },
    {
      "pk": 40189,
      "name": "Compleo",
      "isEjn": false
    },
    {
      "pk": 23856,
      "name": "Compromix Laxå",
      "isEjn": false
    },
    {
      "pk": 2539,
      "name": "Consum",
      "isEjn": false
    },
    {
      "pk": 15353,
      "name": "Convoterm Welbilt Deutschland GmbH",
      "isEjn": false
    },
    {
      "pk": 15596,
      "name": "CopecVoltex",
      "isEjn": false
    },
    {
      "pk": 23372,
      "name": "Coral EV",
      "isEjn": false
    },
    {
      "pk": 41347,
      "name": "Couche Tard Recharge",
      "isEjn": false
    },
    {
      "pk": 32482,
      "name": "COW",
      "isEjn": false
    },
    {
      "pk": 37947,
      "name": "CPFL eRecarga",
      "isEjn": false
    },
    {
      "pk": 32465,
      "name": "CPT Test GmbH",
      "isEjn": false
    },
    {
      "pk": 15852,
      "name": "Cramer-Mühle",
      "isEjn": false
    },
    {
      "pk": 3674,
      "name": "Cross Market Places GmbH",
      "isEjn": false
    },
    {
      "pk": 16183,
      "name": "Cube Charging",
      "isEjn": false
    },
    {
      "pk": 32606,
      "name": "Cupra",
      "isEjn": false
    },
    {
      "pk": 29367,
      "name": "Current",
      "isEjn": false
    },
    {
      "pk": 38755,
      "name": "Currenta",
      "isEjn": false
    },
    {
      "pk": 30389,
      "name": "Cut!Energy",
      "isEjn": false
    },
    {
      "pk": 20242,
      "name": "CV Charging",
      "isEjn": false
    },
    {
      "pk": 40359,
      "name": "Cyan Energy AS",
      "isEjn": false
    },
    {
      "pk": 15488,
      "name": "D-Charge",
      "isEjn": false
    },
    {
      "pk": 16109,
      "name": "D.van Doorn",
      "isEjn": false
    },
    {
      "pk": 36314,
      "name": "Dachstein Tourismus AG",
      "isEjn": false
    },
    {
      "pk": 12764,
      "name": "DAH Engineering GmbH",
      "isEjn": false
    },
    {
      "pk": 15355,
      "name": "Daniel Bruckhoff ",
      "isEjn": false
    },
    {
      "pk": 27352,
      "name": "Daninci Gastro",
      "isEjn": false
    },
    {
      "pk": 32399,
      "name": "De Nieuwe Stad",
      "isEjn": false
    },
    {
      "pk": 32536,
      "name": "Décathlon",
      "isEjn": false
    },
    {
      "pk": 32414,
      "name": "Defa",
      "isEjn": false
    },
    {
      "pk": 26751,
      "name": "DEG",
      "isEjn": false
    },
    {
      "pk": 5692,
      "name": "DEI Blue",
      "isEjn": false
    },
    {
      "pk": 27134,
      "name": "Demonstration Company",
      "isEjn": false
    },
    {
      "pk": 23373,
      "name": "Denison parking",
      "isEjn": false
    },
    {
      "pk": 13396,
      "name": "Der Fuchsbau",
      "isEjn": false
    },
    {
      "pk": 36352,
      "name": "Der Löwe lebt frei GmbH & CoKG",
      "isEjn": false
    },
    {
      "pk": 32323,
      "name": "Der Schwendterwirt GmbH",
      "isEjn": false
    },
    {
      "pk": 19203,
      "name": "Der Spanien Shop - Peter Bachfischer",
      "isEjn": false
    },
    {
      "pk": 28774,
      "name": "Derzeit wurden keine Informationen zu diesem Bereich erfasst.",
      "isEjn": false
    },
    {
      "pk": 18681,
      "name": "Dev Elephants GmbH",
      "isEjn": false
    },
    {
      "pk": 5705,
      "name": "DEWA",
      "isEjn": false
    },
    {
      "pk": 18367,
      "name": "DHS Grundstücksverwaltung UG Co. KG",
      "isEjn": false
    },
    {
      "pk": 14834,
      "name": "Dietrich Werner Goll GmbH",
      "isEjn": false
    },
    {
      "pk": 24002,
      "name": "Digital Charging Solutions GmbH",
      "isEjn": false
    },
    {
      "pk": 35767,
      "name": "Digital Energy Solutions",
      "isEjn": false
    },
    {
      "pk": 12758,
      "name": "Dirk Koedijk",
      "isEjn": false
    },
    {
      "pk": 16923,
      "name": "DKB Stiftung Liebenberg Gemeinnützige GmbH",
      "isEjn": false
    },
    {
      "pk": 36313,
      "name": "Döllerer Genusswelten GmbH",
      "isEjn": false
    },
    {
      "pk": 39157,
      "name": "Domaine Dols",
      "isEjn": false
    },
    {
      "pk": 14130,
      "name": "Domein Laurabos",
      "isEjn": false
    },
    {
      "pk": 16820,
      "name": "Dorfalm Flachau",
      "isEjn": false
    },
    {
      "pk": 36418,
      "name": "Dorfenten Betriebs GmbH & Co KG",
      "isEjn": false
    },
    {
      "pk": 26582,
      "name": "Dr Schär",
      "isEjn": false
    },
    {
      "pk": 36343,
      "name": "Dr. Georg Wallner e.U.",
      "isEjn": false
    },
    {
      "pk": 36412,
      "name": "Dr. Helmut Ehrmann",
      "isEjn": false
    },
    {
      "pk": 12790,
      "name": "Dr. Melanie Hartmann",
      "isEjn": false
    },
    {
      "pk": 16042,
      "name": "Dr.Ing. Rüdiger Stauth",
      "isEjn": false
    },
    {
      "pk": 3365,
      "name": "Drem Kft.",
      "isEjn": false
    },
    {
      "pk": 38758,
      "name": "Drexl + Ziegler",
      "isEjn": false
    },
    {
      "pk": 8670,
      "name": "Drivalia",
      "isEjn": false
    },
    {
      "pk": 26754,
      "name": "Druckzentrum Salzburg",
      "isEjn": false
    },
    {
      "pk": 13984,
      "name": "DrW",
      "isEjn": false
    },
    {
      "pk": 29437,
      "name": "Dürnberger Elektrotechnik GmbH",
      "isEjn": false
    },
    {
      "pk": 38151,
      "name": "Dynamx / Fitfever - Montfoort",
      "isEjn": false
    },
    {
      "pk": 38792,
      "name": "e-con",
      "isEjn": false
    },
    {
      "pk": 30084,
      "name": "E-Etech Energie GmbH Untere Masenbergstraße 227 8232 Grafendorf",
      "isEjn": false
    },
    {
      "pk": 40880,
      "name": "E-Flux",
      "isEjn": false
    },
    {
      "pk": 12797,
      "name": "e-hier - Josef Geyer e-mobil GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 3853,
      "name": "E-moving",
      "isEjn": false
    },
    {
      "pk": 2637,
      "name": "E-nergyze",
      "isEjn": false
    },
    {
      "pk": 16942,
      "name": "e-rp",
      "isEjn": false
    },
    {
      "pk": 15465,
      "name": "E-Space",
      "isEjn": false
    },
    {
      "pk": 39670,
      "name": "E-Totem",
      "isEjn": false
    },
    {
      "pk": 5836,
      "name": "E-Way",
      "isEjn": false
    },
    {
      "pk": 16004,
      "name": "e-werk Sachsenwald",
      "isEjn": false
    },
    {
      "pk": 17920,
      "name": "E. W. Neu GmbH",
      "isEjn": false
    },
    {
      "pk": 40645,
      "name": "E.ON Drive",
      "isEjn": false
    },
    {
      "pk": 15497,
      "name": "EA Anywhere",
      "isEjn": false
    },
    {
      "pk": 12822,
      "name": "Earth",
      "isEjn": false
    },
    {
      "pk": 16250,
      "name": "Easy",
      "isEjn": false
    },
    {
      "pk": 32519,
      "name": "Easycharges",
      "isEjn": false
    },
    {
      "pk": 854,
      "name": "Easygo.ie",
      "isEjn": false
    },
    {
      "pk": 23371,
      "name": "Eaton",
      "isEjn": false
    },
    {
      "pk": 32639,
      "name": "Ebee Smart Technologies",
      "isEjn": false
    },
    {
      "pk": 32660,
      "name": "Eberhard Schmücker",
      "isEjn": false
    },
    {
      "pk": 38778,
      "name": "EBERwerk",
      "isEjn": false
    },
    {
      "pk": 32462,
      "name": "EBG Compleo",
      "isEjn": false
    },
    {
      "pk": 16041,
      "name": "EBM",
      "isEjn": false
    },
    {
      "pk": 33873,
      "name": "eBZ GmbH",
      "isEjn": false
    },
    {
      "pk": 20922,
      "name": "EC-IS",
      "isEjn": false
    },
    {
      "pk": 16822,
      "name": "Eco.Tech neue Energien & Technik GmbH",
      "isEjn": false
    },
    {
      "pk": 20241,
      "name": "EcoCars",
      "isEjn": false
    },
    {
      "pk": 20244,
      "name": "EcoDot",
      "isEjn": false
    },
    {
      "pk": 1256,
      "name": "EcoFactor",
      "isEjn": false
    },
    {
      "pk": 30233,
      "name": "Ecoleon",
      "isEjn": false
    },
    {
      "pk": 32568,
      "name": "ECS Energie-Contracting Spessart GmbH",
      "isEjn": false
    },
    {
      "pk": 36320,
      "name": "Eder Hotels GmbH",
      "isEjn": false
    },
    {
      "pk": 29436,
      "name": "EDI",
      "isEjn": false
    },
    {
      "pk": 38145,
      "name": "EDISON-HAUS UND GRUNDSTÜCKS-GMBH & CO. KG",
      "isEjn": false
    },
    {
      "pk": 20641,
      "name": "EDrive",
      "isEjn": false
    },
    {
      "pk": 25182,
      "name": "EDS",
      "isEjn": false
    },
    {
      "pk": 36409,
      "name": "EDS Immobilien",
      "isEjn": false
    },
    {
      "pk": 24975,
      "name": "Edtbauer Immobilien Gesellschaft m.b.H.",
      "isEjn": false
    },
    {
      "pk": 30387,
      "name": "Eesell GmbH",
      "isEjn": false
    },
    {
      "pk": 36050,
      "name": "EG Brucker Bundesstraße 49c/o Altmann + Partner Immobilientreuhand GmbH",
      "isEjn": false
    },
    {
      "pk": 36273,
      "name": "EG Carl-Zuckmayerstraße 37",
      "isEjn": false
    },
    {
      "pk": 36322,
      "name": "Egger KG",
      "isEjn": false
    },
    {
      "pk": 16924,
      "name": "Eider-Treene-Sorge",
      "isEjn": false
    },
    {
      "pk": 21127,
      "name": "Eins",
      "isEjn": false
    },
    {
      "pk": 19355,
      "name": "Eirenschmalz Maschinenbaumechanik & Metallbau GmbH",
      "isEjn": false
    },
    {
      "pk": 16991,
      "name": "Eisenacher Versorgungs-Betriebe",
      "isEjn": false
    },
    {
      "pk": 19984,
      "name": "Eisenbahner Baugenossenschaft München - HBF E.G.",
      "isEjn": false
    },
    {
      "pk": 1798,
      "name": "ejoin",
      "isEjn": false
    },
    {
      "pk": 16176,
      "name": "EKO Point",
      "isEjn": false
    },
    {
      "pk": 37436,
      "name": "Eksjö Kommun",
      "isEjn": false
    },
    {
      "pk": 2470,
      "name": "Elanga",
      "isEjn": false
    },
    {
      "pk": 30192,
      "name": "Elbilbox",
      "isEjn": false
    },
    {
      "pk": 646,
      "name": "Eldrive",
      "isEjn": false
    },
    {
      "pk": 16943,
      "name": "ELE Emscher Lippe Energie",
      "isEjn": false
    },
    {
      "pk": 13935,
      "name": "Electico.es",
      "isEjn": false
    },
    {
      "pk": 1842,
      "name": "Electric Circuit",
      "isEjn": false
    },
    {
      "pk": 38002,
      "name": "Electric Era",
      "isEjn": false
    },
    {
      "pk": 32709,
      "name": "Electricity Authority of Cyprus",
      "isEjn": false
    },
    {
      "pk": 15748,
      "name": "Electrico.es",
      "isEjn": false
    },
    {
      "pk": 32541,
      "name": "ELECTRIFIED",
      "isEjn": false
    },
    {
      "pk": 2556,
      "name": "ELECTRIFIED Α.Ε.",
      "isEjn": false
    },
    {
      "pk": 1837,
      "name": "Electrify America",
      "isEjn": false
    },
    {
      "pk": 15473,
      "name": "ElectroGeorgia",
      "isEjn": false
    },
    {
      "pk": 14481,
      "name": "Electrolux",
      "isEjn": false
    },
    {
      "pk": 23370,
      "name": "Electron Charger",
      "isEjn": false
    },
    {
      "pk": 15486,
      "name": "Electroposto Celesc",
      "isEjn": false
    },
    {
      "pk": 15612,
      "name": "Elektrizitätsgenossenschaft Pflersch",
      "isEjn": false
    },
    {
      "pk": 12774,
      "name": "Elektrizitätsvereinigung Böbing eG",
      "isEjn": false
    },
    {
      "pk": 12775,
      "name": "Elektrizitätsvereinigung eG ",
      "isEjn": false
    },
    {
      "pk": 11949,
      "name": "Elektrizitätsversorgung Werther",
      "isEjn": false
    },
    {
      "pk": 13673,
      "name": "Elektrizitätswerk Goldbach-Hösbach",
      "isEjn": false
    },
    {
      "pk": 17071,
      "name": "Elektrizitätswerk Gösting V. Franz GmbH",
      "isEjn": false
    },
    {
      "pk": 17452,
      "name": "Elektrizitätswerk Gröbming KG",
      "isEjn": false
    },
    {
      "pk": 31141,
      "name": "Elektrizitätswerk Tegernsee Vertr",
      "isEjn": false
    },
    {
      "pk": 38773,
      "name": "Elektrizitätswerke Reutte",
      "isEjn": false
    },
    {
      "pk": 38759,
      "name": "Elektro Bauer",
      "isEjn": false
    },
    {
      "pk": 17450,
      "name": "Elektro Buder GmbH",
      "isEjn": false
    },
    {
      "pk": 38771,
      "name": "Elektro Eichkorn",
      "isEjn": false
    },
    {
      "pk": 31191,
      "name": "ELEKTRO GINDL",
      "isEjn": false
    },
    {
      "pk": 25669,
      "name": "Elektro Hollauf",
      "isEjn": false
    },
    {
      "pk": 32634,
      "name": "Elektro Margreiter GmbH",
      "isEjn": false
    },
    {
      "pk": 32550,
      "name": "Elektro Müller",
      "isEjn": false
    },
    {
      "pk": 16990,
      "name": "Elektro Obernauer",
      "isEjn": false
    },
    {
      "pk": 20539,
      "name": "Elektro Rümpel & Sohn",
      "isEjn": false
    },
    {
      "pk": 30378,
      "name": "ElektroBirk",
      "isEjn": false
    },
    {
      "pk": 38762,
      "name": "Elektrohaus Zentrum",
      "isEjn": false
    },
    {
      "pk": 673,
      "name": "Eleport",
      "isEjn": false
    },
    {
      "pk": 15575,
      "name": "Eletroposto Celesc",
      "isEjn": false
    },
    {
      "pk": 15491,
      "name": "EleXA",
      "isEjn": false
    },
    {
      "pk": 24215,
      "name": "elift",
      "isEjn": false
    },
    {
      "pk": 36117,
      "name": "Elisabeth Lang",
      "isEjn": false
    },
    {
      "pk": 37188,
      "name": "Eliso GmbH",
      "isEjn": false
    },
    {
      "pk": 5879,
      "name": "Ella",
      "isEjn": false
    },
    {
      "pk": 32498,
      "name": "Elmtronics Ltd. Viewpoint",
      "isEjn": false
    },
    {
      "pk": 32543,
      "name": "Eltric",
      "isEjn": false
    },
    {
      "pk": 26752,
      "name": "EM1 GmbH",
      "isEjn": false
    },
    {
      "pk": 16972,
      "name": "EMB Energie Mark Brandenburg",
      "isEjn": false
    },
    {
      "pk": 36087,
      "name": "Embacher Sporthotel GmbH",
      "isEjn": false
    },
    {
      "pk": 36357,
      "name": "Embachhof GmbH & Co KG",
      "isEjn": false
    },
    {
      "pk": 32512,
      "name": "EMEO",
      "isEjn": false
    },
    {
      "pk": 13126,
      "name": "Emeo (SK)",
      "isEjn": false
    },
    {
      "pk": 20225,
      "name": "Emergy Führungs- und Servicegesellschaft mbH",
      "isEjn": false
    },
    {
      "pk": 20229,
      "name": "EMES Charge",
      "isEjn": false
    },
    {
      "pk": 16973,
      "name": "Emil Frey Küstengarage GmbH",
      "isEjn": false
    },
    {
      "pk": 17731,
      "name": "EMob Betriebsgesellschaft",
      "isEjn": false
    },
    {
      "pk": 29472,
      "name": "EMOXXO GmbH",
      "isEjn": false
    },
    {
      "pk": 16426,
      "name": "En Yakit",
      "isEjn": false
    },
    {
      "pk": 14941,
      "name": "EnAlpin AG",
      "isEjn": false
    },
    {
      "pk": 32451,
      "name": "EnAlpin AG Bahnhofplatz 1b 3930 Visp",
      "isEjn": false
    },
    {
      "pk": 661,
      "name": "Endesa X",
      "isEjn": false
    },
    {
      "pk": 1305,
      "name": "Endolla Barcelona",
      "isEjn": false
    },
    {
      "pk": 13384,
      "name": "ENEA S.A.",
      "isEjn": false
    },
    {
      "pk": 36771,
      "name": "Enel X",
      "isEjn": false
    },
    {
      "pk": 39773,
      "name": "enercoop",
      "isEjn": false
    },
    {
      "pk": 1634,
      "name": "Energa",
      "isEjn": false
    },
    {
      "pk": 19199,
      "name": "Energa Obrót",
      "isEjn": false
    },
    {
      "pk": 14752,
      "name": "Energa Oświetlenie",
      "isEjn": false
    },
    {
      "pk": 32439,
      "name": "Energie Bad Gleichenberg",
      "isEjn": false
    },
    {
      "pk": 813,
      "name": "Energie Graz GmbH",
      "isEjn": false
    },
    {
      "pk": 21492,
      "name": "Energie Intern",
      "isEjn": false
    },
    {
      "pk": 40186,
      "name": "Energie Südbayern",
      "isEjn": false
    },
    {
      "pk": 38777,
      "name": "Energie und Wasser Wahlstedt/Bad Segeberg",
      "isEjn": false
    },
    {
      "pk": 12772,
      "name": "Energie Vorpommern",
      "isEjn": false
    },
    {
      "pk": 32403,
      "name": "Energie- und Wasserversorgung Kirchzarten",
      "isEjn": false
    },
    {
      "pk": 17072,
      "name": "Energie- und Wasserversorgung Rheine",
      "isEjn": false
    },
    {
      "pk": 39496,
      "name": "energielenker service",
      "isEjn": false
    },
    {
      "pk": 12827,
      "name": "Energiepark Schlei-Ostsee",
      "isEjn": false
    },
    {
      "pk": 18665,
      "name": "Energieservice Rhein-Main",
      "isEjn": false
    },
    {
      "pk": 20301,
      "name": "Energieversorgung Alzenau",
      "isEjn": false
    },
    {
      "pk": 32402,
      "name": "Energieversorgung Apolda",
      "isEjn": false
    },
    {
      "pk": 16993,
      "name": "Energieversorgung Gera",
      "isEjn": false
    },
    {
      "pk": 32419,
      "name": "Energieversorgung Inselsberg",
      "isEjn": false
    },
    {
      "pk": 32427,
      "name": "Energieversorgung Klettgau-Rheintal",
      "isEjn": false
    },
    {
      "pk": 17961,
      "name": "Energieversorgung Nordhausen",
      "isEjn": false
    },
    {
      "pk": 32429,
      "name": "Energieversorgung Rodau",
      "isEjn": false
    },
    {
      "pk": 22025,
      "name": "Energieversorgung Rudolstadt",
      "isEjn": false
    },
    {
      "pk": 20305,
      "name": "Energieversorgung Sehnde",
      "isEjn": false
    },
    {
      "pk": 18675,
      "name": "Energieversorgung Sylt",
      "isEjn": false
    },
    {
      "pk": 20317,
      "name": "Energiezone GmbH",
      "isEjn": false
    },
    {
      "pk": 32453,
      "name": "energis",
      "isEjn": false
    },
    {
      "pk": 13755,
      "name": "Energomix S.A.",
      "isEjn": false
    },
    {
      "pk": 15595,
      "name": "Enersa",
      "isEjn": false
    },
    {
      "pk": 3747,
      "name": "Eniwa",
      "isEjn": false
    },
    {
      "pk": 15572,
      "name": "ENOC",
      "isEjn": false
    },
    {
      "pk": 20287,
      "name": "ENR",
      "isEjn": false
    },
    {
      "pk": 38225,
      "name": "enviaM",
      "isEjn": false
    },
    {
      "pk": 16040,
      "name": "enwor - energie & wasser vor ort",
      "isEjn": false
    },
    {
      "pk": 707,
      "name": "eo Charging",
      "isEjn": false
    },
    {
      "pk": 12804,
      "name": "eONE",
      "isEjn": false
    },
    {
      "pk": 15588,
      "name": "EPE",
      "isEjn": false
    },
    {
      "pk": 25133,
      "name": "EPSIS",
      "isEjn": false
    },
    {
      "pk": 38479,
      "name": "EQS Pro",
      "isEjn": false
    },
    {
      "pk": 38528,
      "name": "EQUANS I&E",
      "isEjn": false
    },
    {
      "pk": 14237,
      "name": "Erbacherhof",
      "isEjn": false
    },
    {
      "pk": 17729,
      "name": "erdgas schwaben",
      "isEjn": false
    },
    {
      "pk": 20309,
      "name": "ERE",
      "isEjn": false
    },
    {
      "pk": 32625,
      "name": "Erich Hofer, Hotel Fernau KG",
      "isEjn": false
    },
    {
      "pk": 20270,
      "name": "Erler Holding",
      "isEjn": false
    },
    {
      "pk": 14757,
      "name": "Erlkönig Gästeappartments GmbH",
      "isEjn": false
    },
    {
      "pk": 15470,
      "name": "Esarj",
      "isEjn": false
    },
    {
      "pk": 32490,
      "name": "Eşarj",
      "isEjn": false
    },
    {
      "pk": 38004,
      "name": "Esplanade",
      "isEjn": false
    },
    {
      "pk": 16823,
      "name": "essen-macht-spass GmbH",
      "isEjn": false
    },
    {
      "pk": 14197,
      "name": "ESWE Versorgungs",
      "isEjn": false
    },
    {
      "pk": 37944,
      "name": "eTanke.tirol",
      "isEjn": false
    },
    {
      "pk": 666,
      "name": "etecnic",
      "isEjn": false
    },
    {
      "pk": 20268,
      "name": "eTerminal",
      "isEjn": false
    },
    {
      "pk": 18678,
      "name": "Etrel Inch Duo",
      "isEjn": false
    },
    {
      "pk": 13973,
      "name": "EUROLOOP sp. z o.o.",
      "isEjn": false
    },
    {
      "pk": 37992,
      "name": "EV Box Charge",
      "isEjn": false
    },
    {
      "pk": 20431,
      "name": "EV Cars",
      "isEjn": false
    },
    {
      "pk": 1897,
      "name": "EV Connect",
      "isEjn": false
    },
    {
      "pk": 15468,
      "name": "EV Edge",
      "isEjn": false
    },
    {
      "pk": 13477,
      "name": "EV Gateway",
      "isEjn": false
    },
    {
      "pk": 37997,
      "name": "EV Green Charger",
      "isEjn": false
    },
    {
      "pk": 28042,
      "name": "EV Ladetechnik GmbH",
      "isEjn": false
    },
    {
      "pk": 1246,
      "name": "EV Loader",
      "isEjn": false
    },
    {
      "pk": 2548,
      "name": "EV Range",
      "isEjn": false
    },
    {
      "pk": 41163,
      "name": "EV Star",
      "isEjn": false
    },
    {
      "pk": 37043,
      "name": "EV Station PluZ",
      "isEjn": false
    },
    {
      "pk": 40360,
      "name": "EV-box",
      "isEjn": false
    },
    {
      "pk": 1319,
      "name": "EV-Dot",
      "isEjn": false
    },
    {
      "pk": 27722,
      "name": "EV-ON GmbH",
      "isEjn": false
    },
    {
      "pk": 13385,
      "name": "EV365",
      "isEjn": false
    },
    {
      "pk": 15604,
      "name": "EValue",
      "isEjn": false
    },
    {
      "pk": 15590,
      "name": "EVAT",
      "isEjn": false
    },
    {
      "pk": 25137,
      "name": "EVC Charge",
      "isEjn": false
    },
    {
      "pk": 36127,
      "name": "EVC Holdings",
      "isEjn": false
    },
    {
      "pk": 694,
      "name": "EVCharge",
      "isEjn": false
    },
    {
      "pk": 1386,
      "name": "EVconnect Romania",
      "isEjn": false
    },
    {
      "pk": 1898,
      "name": "EVCS",
      "isEjn": false
    },
    {
      "pk": 27941,
      "name": "Evedis",
      "isEjn": false
    },
    {
      "pk": 2231,
      "name": "EVERTY",
      "isEjn": false
    },
    {
      "pk": 25184,
      "name": "EVerycharge",
      "isEjn": false
    },
    {
      "pk": 1836,
      "name": "EVgo",
      "isEjn": false
    },
    {
      "pk": 1164,
      "name": "EVI Energieversorgung Hildesheim GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 36022,
      "name": "EVI GmbH",
      "isEjn": false
    },
    {
      "pk": 2265,
      "name": "Evie",
      "isEjn": false
    },
    {
      "pk": 1841,
      "name": "EVmatch",
      "isEjn": false
    },
    {
      "pk": 20256,
      "name": "Evnex",
      "isEjn": false
    },
    {
      "pk": 15605,
      "name": "EVOASIS",
      "isEjn": false
    },
    {
      "pk": 837,
      "name": "Evolt",
      "isEjn": false
    },
    {
      "pk": 27392,
      "name": "Evolve",
      "isEjn": false
    },
    {
      "pk": 38112,
      "name": "EVP Network",
      "isEjn": false
    },
    {
      "pk": 41161,
      "name": "EVRun",
      "isEjn": false
    },
    {
      "pk": 23301,
      "name": "EVStart",
      "isEjn": false
    },
    {
      "pk": 15502,
      "name": "EVStation",
      "isEjn": false
    },
    {
      "pk": 15597,
      "name": "EVTAIL",
      "isEjn": false
    },
    {
      "pk": 2272,
      "name": "EVUp",
      "isEjn": false
    },
    {
      "pk": 19601,
      "name": "EVX",
      "isEjn": false
    },
    {
      "pk": 14025,
      "name": "EW-Schattwald",
      "isEjn": false
    },
    {
      "pk": 656,
      "name": "Eway",
      "isEjn": false
    },
    {
      "pk": 214,
      "name": "Eways",
      "isEjn": false
    },
    {
      "pk": 39009,
      "name": "EWBUENDEFOLLOWER",
      "isEjn": false
    },
    {
      "pk": 66,
      "name": "EWE Go",
      "isEjn": false
    },
    {
      "pk": 3657,
      "name": "EWE Go GmbH",
      "isEjn": false
    },
    {
      "pk": 14935,
      "name": "EWE Vertrieb GmbH",
      "isEjn": false
    },
    {
      "pk": 32400,
      "name": "EWEC",
      "isEjn": false
    },
    {
      "pk": 20250,
      "name": "EWV Energie- und Wasser- Versorgung",
      "isEjn": false
    },
    {
      "pk": 12752,
      "name": "Excluton",
      "isEjn": false
    },
    {
      "pk": 2269,
      "name": "Exploren",
      "isEjn": false
    },
    {
      "pk": 26,
      "name": "EZE Network",
      "isEjn": false
    },
    {
      "pk": 32534,
      "name": "Ezstop",
      "isEjn": false
    },
    {
      "pk": 15483,
      "name": "EZvolt",
      "isEjn": false
    },
    {
      "pk": 31813,
      "name": "F. Zimmermann",
      "isEjn": false
    },
    {
      "pk": 302,
      "name": "F1rst",
      "isEjn": false
    },
    {
      "pk": 26577,
      "name": "FA_ANNELIESE_SCHNEIDER",
      "isEjn": false
    },
    {
      "pk": 35734,
      "name": "Fa. BASTIZI Photovoltaik und Energieeffizienz",
      "isEjn": false
    },
    {
      "pk": 14021,
      "name": "Fa. Kaps GmbH & Co KG",
      "isEjn": false
    },
    {
      "pk": 32466,
      "name": "Fa. Schwarz, Wagendorffer & Co. Elektrizitätswerk GmbH",
      "isEjn": false
    },
    {
      "pk": 36309,
      "name": "Fachhochschule Salzburg GmbH",
      "isEjn": false
    },
    {
      "pk": 15108,
      "name": "Fageralmhuette",
      "isEjn": false
    },
    {
      "pk": 36348,
      "name": "Fahrtraum GmbH",
      "isEjn": false
    },
    {
      "pk": 18674,
      "name": "FairEnergie",
      "isEjn": false
    },
    {
      "pk": 36021,
      "name": "Familie Maier GmbH & Co KG",
      "isEjn": false
    },
    {
      "pk": 36324,
      "name": "Familie Schernthanner KG",
      "isEjn": false
    },
    {
      "pk": 16812,
      "name": "Family - Apart",
      "isEjn": false
    },
    {
      "pk": 36283,
      "name": "Fashionpalais GmbH",
      "isEjn": false
    },
    {
      "pk": 32667,
      "name": "Fast-Charging LexEnergy",
      "isEjn": false
    },
    {
      "pk": 10705,
      "name": "Fastvolt",
      "isEjn": false
    },
    {
      "pk": 36023,
      "name": "FC Red Bull Salzburg GmbH",
      "isEjn": false
    },
    {
      "pk": 37710,
      "name": "FEDA",
      "isEjn": false
    },
    {
      "pk": 3537,
      "name": "Fédération Départementale d'Electrification et d'Energie de la Corrèze (FDEE19)",
      "isEjn": false
    },
    {
      "pk": 23933,
      "name": "Feinauer Kran- und Hebetechnik GmbH",
      "isEjn": false
    },
    {
      "pk": 665,
      "name": "Fenie Energia",
      "isEjn": false
    },
    {
      "pk": 25143,
      "name": "Ferien-Appartements Ritterhof Fiss",
      "isEjn": false
    },
    {
      "pk": 24665,
      "name": "Feriendomizile Tschiderer GmbH",
      "isEjn": false
    },
    {
      "pk": 29948,
      "name": "Feriengut Unterhochstätt",
      "isEjn": false
    },
    {
      "pk": 20289,
      "name": "Ferienhaus Central",
      "isEjn": false
    },
    {
      "pk": 12904,
      "name": "Ferienhausdorf Thale",
      "isEjn": false
    },
    {
      "pk": 31331,
      "name": "Ferienhof GutMichel",
      "isEjn": false
    },
    {
      "pk": 36410,
      "name": "Ferienwohnung Lärchenhof",
      "isEjn": false
    },
    {
      "pk": 27723,
      "name": "Ferienwohnung Tirolglueck",
      "isEjn": false
    },
    {
      "pk": 27034,
      "name": "Ferienwohnungsvermietung Kalifornien GbR",
      "isEjn": false
    },
    {
      "pk": 15573,
      "name": "FEWA",
      "isEjn": false
    },
    {
      "pk": 31846,
      "name": "Finestre GmbH",
      "isEjn": false
    },
    {
      "pk": 36061,
      "name": "Firma AdH GmbH",
      "isEjn": false
    },
    {
      "pk": 14946,
      "name": "Firma Krause",
      "isEjn": false
    },
    {
      "pk": 21490,
      "name": "Firma Kurt Buck",
      "isEjn": false
    },
    {
      "pk": 20275,
      "name": "Firma Schröckenfux",
      "isEjn": false
    },
    {
      "pk": 29981,
      "name": "Fischerwirt KG",
      "isEjn": false
    },
    {
      "pk": 18667,
      "name": "Fitnessclub ACTIVITA Aichach GmbH",
      "isEjn": false
    },
    {
      "pk": 18196,
      "name": "Flash",
      "isEjn": false
    },
    {
      "pk": 31192,
      "name": "Fleischerei Stroh GmbH",
      "isEjn": false
    },
    {
      "pk": 1825,
      "name": "FLO",
      "isEjn": false
    },
    {
      "pk": 36330,
      "name": "Florian Heffeter",
      "isEjn": false
    },
    {
      "pk": 32437,
      "name": "Flughafen Erfurt",
      "isEjn": false
    },
    {
      "pk": 31500,
      "name": "fluxa",
      "isEjn": false
    },
    {
      "pk": 17347,
      "name": "Folger GmbH",
      "isEjn": false
    },
    {
      "pk": 14833,
      "name": "Fondation des Parkings",
      "isEjn": false
    },
    {
      "pk": 16424,
      "name": "Fonden Langelands Elforsyning",
      "isEjn": false
    },
    {
      "pk": 15476,
      "name": "FORA",
      "isEjn": false
    },
    {
      "pk": 36977,
      "name": "Ford",
      "isEjn": false
    },
    {
      "pk": 15585,
      "name": "Ford TRIAL",
      "isEjn": false
    },
    {
      "pk": 25492,
      "name": "formElektrik",
      "isEjn": false
    },
    {
      "pk": 32590,
      "name": "ForSea",
      "isEjn": false
    },
    {
      "pk": 30187,
      "name": "Fount",
      "isEjn": false
    },
    {
      "pk": 25183,
      "name": "Framtida Bil - KIA Köping",
      "isEjn": false
    },
    {
      "pk": 1913,
      "name": "Francis Energy",
      "isEjn": false
    },
    {
      "pk": 36010,
      "name": "Frank Abbenhuijs",
      "isEjn": false
    },
    {
      "pk": 17734,
      "name": "Frank Hillenhinrichs",
      "isEjn": false
    },
    {
      "pk": 13985,
      "name": "Frank und Nicole Teuber GbR ",
      "isEjn": false
    },
    {
      "pk": 36062,
      "name": "Frank-Willi Kohlen",
      "isEjn": false
    },
    {
      "pk": 15960,
      "name": "Franklin Energy",
      "isEjn": false
    },
    {
      "pk": 31138,
      "name": "Fraunhofer-Gesellschaft",
      "isEjn": false
    },
    {
      "pk": 36036,
      "name": "Fred Thiesen",
      "isEjn": false
    },
    {
      "pk": 32416,
      "name": "Freisinger Stadtwerke Versorgungs-GmbH",
      "isEjn": false
    },
    {
      "pk": 36420,
      "name": "Friedrich Sochor - Zell am See",
      "isEjn": false
    },
    {
      "pk": 38772,
      "name": "FriesenEnergie",
      "isEjn": false
    },
    {
      "pk": 32587,
      "name": "Frühstückspension Kärnten Inn",
      "isEjn": false
    },
    {
      "pk": 18368,
      "name": "Fryksdalskraft",
      "isEjn": false
    },
    {
      "pk": 37999,
      "name": "Fuel Up Snellaadplein",
      "isEjn": false
    },
    {
      "pk": 26337,
      "name": "Futuregmbh",
      "isEjn": false
    },
    {
      "pk": 33797,
      "name": "FutureNed",
      "isEjn": false
    },
    {
      "pk": 38263,
      "name": "Galvanic",
      "isEjn": false
    },
    {
      "pk": 32661,
      "name": "Gamsalm GmbH Großdorf 85 A-9981 Kals Am Großglockner",
      "isEjn": false
    },
    {
      "pk": 36086,
      "name": "Gappmaier Gebäude & Logistik",
      "isEjn": false
    },
    {
      "pk": 32540,
      "name": "GAR",
      "isEjn": false
    },
    {
      "pk": 12749,
      "name": "Garagebedrijf Petrogas",
      "isEjn": false
    },
    {
      "pk": 23931,
      "name": "Garni Aghél",
      "isEjn": false
    },
    {
      "pk": 16817,
      "name": "Garten-Center Kremer GmbH",
      "isEjn": false
    },
    {
      "pk": 20296,
      "name": "Gas- und Wasserversorgung Höxter GmbH",
      "isEjn": false
    },
    {
      "pk": 15100,
      "name": "Gästehaus Maria",
      "isEjn": false
    },
    {
      "pk": 33223,
      "name": "Gästehaus Obererlacher",
      "isEjn": false
    },
    {
      "pk": 20434,
      "name": "Gästehaus Roland",
      "isEjn": false
    },
    {
      "pk": 22294,
      "name": "Gasteigerhof GesmbH & Co KG",
      "isEjn": false
    },
    {
      "pk": 36014,
      "name": "Gasteiner Bergbahnen AG",
      "isEjn": false
    },
    {
      "pk": 14761,
      "name": "Gasthaus Steidl",
      "isEjn": false
    },
    {
      "pk": 36421,
      "name": "Gasthof Camping Glemmerhof",
      "isEjn": false
    },
    {
      "pk": 36360,
      "name": "Gasthof Hotel Restaurant Burgblick Jürgen Göttlich e. U.",
      "isEjn": false
    },
    {
      "pk": 36271,
      "name": "Gasthof Waldwirt",
      "isEjn": false
    },
    {
      "pk": 36285,
      "name": "Gasthof Wiesenhof GmbH",
      "isEjn": false
    },
    {
      "pk": 31260,
      "name": "Gasthof zum Freden GmbH",
      "isEjn": false
    },
    {
      "pk": 25185,
      "name": "GB-SMC",
      "isEjn": false
    },
    {
      "pk": 36325,
      "name": "GBB Vermietung GmbH & Co KG",
      "isEjn": false
    },
    {
      "pk": 20246,
      "name": "GE Watt",
      "isEjn": false
    },
    {
      "pk": 36380,
      "name": "GEA Austria GmbH",
      "isEjn": false
    },
    {
      "pk": 36393,
      "name": "GEA CEE GmbH",
      "isEjn": false
    },
    {
      "pk": 27721,
      "name": "Gebrüder LIMMERT AG",
      "isEjn": false
    },
    {
      "pk": 21633,
      "name": "Geitner GmbH",
      "isEjn": false
    },
    {
      "pk": 12815,
      "name": "Geke van Dijk",
      "isEjn": false
    },
    {
      "pk": 38515,
      "name": "Gelsenwasser",
      "isEjn": false
    },
    {
      "pk": 32396,
      "name": "Gelsenwasser ",
      "isEjn": false
    },
    {
      "pk": 38757,
      "name": "Gemeinde Ahnatal",
      "isEjn": false
    },
    {
      "pk": 36370,
      "name": "Gemeinde Bad Gastein",
      "isEjn": false
    },
    {
      "pk": 36371,
      "name": "Gemeinde Bad Vigaun",
      "isEjn": false
    },
    {
      "pk": 13271,
      "name": "Gemeinde Buch am Erlbach ",
      "isEjn": false
    },
    {
      "pk": 13680,
      "name": "Gemeinde Chieming",
      "isEjn": false
    },
    {
      "pk": 32589,
      "name": "Gemeinde Eggelsberg",
      "isEjn": false
    },
    {
      "pk": 41454,
      "name": "Gemeinde Faulbach",
      "isEjn": false
    },
    {
      "pk": 36303,
      "name": "Gemeinde Filzmoos",
      "isEjn": false
    },
    {
      "pk": 28316,
      "name": "Gemeinde Finnentrop",
      "isEjn": false
    },
    {
      "pk": 17726,
      "name": "Gemeinde Flachau",
      "isEjn": false
    },
    {
      "pk": 22292,
      "name": "Gemeinde Freinberg",
      "isEjn": false
    },
    {
      "pk": 31068,
      "name": "Gemeinde Göriach",
      "isEjn": false
    },
    {
      "pk": 38338,
      "name": "Gemeinde Gundelsheim",
      "isEjn": false
    },
    {
      "pk": 22026,
      "name": "Gemeinde Hallwang",
      "isEjn": false
    },
    {
      "pk": 19873,
      "name": "Gemeinde Hohenzell",
      "isEjn": false
    },
    {
      "pk": 32571,
      "name": "Gemeinde Kirchberg an der Raab",
      "isEjn": false
    },
    {
      "pk": 25739,
      "name": "Gemeinde Ladis",
      "isEjn": false
    },
    {
      "pk": 40190,
      "name": "Gemeinde Laufach",
      "isEjn": false
    },
    {
      "pk": 32467,
      "name": "Gemeinde Litzendorf",
      "isEjn": false
    },
    {
      "pk": 36328,
      "name": "Gemeinde Nussdorf am Haunsberg",
      "isEjn": false
    },
    {
      "pk": 20642,
      "name": "Gemeinde Oberschneiding",
      "isEjn": false
    },
    {
      "pk": 28008,
      "name": "Gemeinde Ottersweier",
      "isEjn": false
    },
    {
      "pk": 36354,
      "name": "Gemeinde Pfarrwerfen",
      "isEjn": false
    },
    {
      "pk": 19206,
      "name": "Gemeinde Ramingstein",
      "isEjn": false
    },
    {
      "pk": 15102,
      "name": "Gemeinde Rettenberg",
      "isEjn": false
    },
    {
      "pk": 22065,
      "name": "Gemeinde Semriach",
      "isEjn": false
    },
    {
      "pk": 34558,
      "name": "Gemeinde St. Georgen am Längsee",
      "isEjn": false
    },
    {
      "pk": 24631,
      "name": "Gemeinde St. Koloman",
      "isEjn": false
    },
    {
      "pk": 36277,
      "name": "Gemeinde Strobl",
      "isEjn": false
    },
    {
      "pk": 12903,
      "name": "Gemeinde Unterlamm",
      "isEjn": false
    },
    {
      "pk": 36419,
      "name": "Gemeinde Wals-Siezenheim Kindergarten",
      "isEjn": false
    },
    {
      "pk": 12819,
      "name": "Gemeinde Weichs",
      "isEjn": false
    },
    {
      "pk": 36365,
      "name": "Gemeinde Weißbach bei Lofer",
      "isEjn": false
    },
    {
      "pk": 27797,
      "name": "Gemeindeverwaltung Nürensdorf",
      "isEjn": false
    },
    {
      "pk": 29123,
      "name": "Gemeindewerke Finnentrop GmbH",
      "isEjn": false
    },
    {
      "pk": 32418,
      "name": "Gemeindewerke Gundelfingen",
      "isEjn": false
    },
    {
      "pk": 32421,
      "name": "Gemeindewerke Haßloch",
      "isEjn": false
    },
    {
      "pk": 31641,
      "name": "Gemeindewerke Hünxe GmbH",
      "isEjn": false
    },
    {
      "pk": 32413,
      "name": "Gemeindewerke Stockelsdorf",
      "isEjn": false
    },
    {
      "pk": 38665,
      "name": "Genosse Zimmermann",
      "isEjn": false
    },
    {
      "pk": 40499,
      "name": "Gentari",
      "isEjn": false
    },
    {
      "pk": 36413,
      "name": "Georg Gruber",
      "isEjn": false
    },
    {
      "pk": 15282,
      "name": "Georg Gstrein und Steffen Pastwa",
      "isEjn": false
    },
    {
      "pk": 36299,
      "name": "Georg Schmiderer GmbH",
      "isEjn": false
    },
    {
      "pk": 36349,
      "name": "Gersbergalm Hotelbetriebs GmbH & Co KG",
      "isEjn": false
    },
    {
      "pk": 14127,
      "name": "Gerüstbau Schiestl",
      "isEjn": false
    },
    {
      "pk": 32602,
      "name": "GET Electric",
      "isEjn": false
    },
    {
      "pk": 29571,
      "name": "GFC – Gesellschaft des Kreises Coesfeld zur Förderung regenerativer Energien mbH",
      "isEjn": false
    },
    {
      "pk": 38264,
      "name": "GINKA",
      "isEjn": false
    },
    {
      "pk": 20265,
      "name": "Ginzinger GmbH",
      "isEjn": false
    },
    {
      "pk": 25130,
      "name": "GIOev",
      "isEjn": false
    },
    {
      "pk": 30861,
      "name": "GITO Helgingborgs Hamn",
      "isEjn": false
    },
    {
      "pk": 13058,
      "name": "GK SOFTWARE SE",
      "isEjn": false
    },
    {
      "pk": 32642,
      "name": "GK SOFTWARE SE Waldstrasse 7 08261 Schöneck GERMANY",
      "isEjn": false
    },
    {
      "pk": 17723,
      "name": "GK SOFTWARE SEWaldstrasse 708261 SchöneckGermany",
      "isEjn": false
    },
    {
      "pk": 12833,
      "name": "Glanbia Nutritionals",
      "isEjn": false
    },
    {
      "pk": 36035,
      "name": "Glas Gasperlmair GmbH",
      "isEjn": false
    },
    {
      "pk": 32646,
      "name": "Glaskontor Glinicke",
      "isEjn": false
    },
    {
      "pk": 36291,
      "name": "Glemm Lodge Apartments",
      "isEjn": false
    },
    {
      "pk": 19875,
      "name": "Glinicke Baunatal",
      "isEjn": false
    },
    {
      "pk": 22611,
      "name": "glinicke energie und service",
      "isEjn": false
    },
    {
      "pk": 17449,
      "name": "Global Life Sciences Solutions Austria GmbH & Co KG",
      "isEjn": false
    },
    {
      "pk": 24559,
      "name": "Glock Aviation GmbH",
      "isEjn": false
    },
    {
      "pk": 26537,
      "name": "GLSBANKOPERATOR",
      "isEjn": false
    },
    {
      "pk": 20245,
      "name": "GO EC",
      "isEjn": false
    },
    {
      "pk": 23409,
      "name": "Go Electric E-Mobility",
      "isEjn": false
    },
    {
      "pk": 657,
      "name": "Go Electric Stations",
      "isEjn": false
    },
    {
      "pk": 20257,
      "name": "GOB",
      "isEjn": false
    },
    {
      "pk": 5887,
      "name": "GoCharge",
      "isEjn": false
    },
    {
      "pk": 20286,
      "name": "GOE",
      "isEjn": false
    },
    {
      "pk": 15752,
      "name": "Goedehuizen",
      "isEjn": false
    },
    {
      "pk": 36082,
      "name": "Golf & Country Club",
      "isEjn": false
    },
    {
      "pk": 19204,
      "name": "Golf und Sporthotel Moarhof",
      "isEjn": false
    },
    {
      "pk": 36282,
      "name": "Golfclub ESR Zell am See-Kaprun GmbH & Co.KG",
      "isEjn": false
    },
    {
      "pk": 30898,
      "name": "Gölles Holding GmbH",
      "isEjn": false
    },
    {
      "pk": 29743,
      "name": "GP JOULE GmbH",
      "isEjn": false
    },
    {
      "pk": 30118,
      "name": "GPE",
      "isEjn": false
    },
    {
      "pk": 36403,
      "name": "GPV Vermietungs GmbH",
      "isEjn": false
    },
    {
      "pk": 13622,
      "name": "Graviti Energy",
      "isEjn": false
    },
    {
      "pk": 18229,
      "name": "Gravity Energy",
      "isEjn": false
    },
    {
      "pk": 26118,
      "name": "Great CPO",
      "isEjn": false
    },
    {
      "pk": 38769,
      "name": "Green City",
      "isEjn": false
    },
    {
      "pk": 1793,
      "name": "Green Mile Charging Co Ltd",
      "isEjn": false
    },
    {
      "pk": 20430,
      "name": "Green To Wheel",
      "isEjn": false
    },
    {
      "pk": 31259,
      "name": "Greenman Energy Operations GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 15504,
      "name": "Greenparking",
      "isEjn": false
    },
    {
      "pk": 1297,
      "name": "Gremo Na Elektriko",
      "isEjn": false
    },
    {
      "pk": 22257,
      "name": "GRID",
      "isEjn": false
    },
    {
      "pk": 5893,
      "name": "GridCars",
      "isEjn": false
    },
    {
      "pk": 4201,
      "name": "Gridserve",
      "isEjn": false
    },
    {
      "pk": 32567,
      "name": "Groen Goud Biervliet",
      "isEjn": false
    },
    {
      "pk": 16001,
      "name": "Grösch Gastro GmbH",
      "isEjn": false
    },
    {
      "pk": 36312,
      "name": "Großarler Bergbahnen GmbH & Co KG",
      "isEjn": false
    },
    {
      "pk": 30394,
      "name": "Große Kreisstadt Leimen",
      "isEjn": false
    },
    {
      "pk": 36047,
      "name": "Gruber Tourismusbetriebe GmbH",
      "isEjn": false
    },
    {
      "pk": 23858,
      "name": "Gulf Idre",
      "isEjn": false
    },
    {
      "pk": 36358,
      "name": "Gunnar Kohl",
      "isEjn": false
    },
    {
      "pk": 36092,
      "name": "Gürtler-Mauthner Vermögensverwaltung GmbH",
      "isEjn": false
    },
    {
      "pk": 20290,
      "name": "Gut Friederikenhof",
      "isEjn": false
    },
    {
      "pk": 36405,
      "name": "Gutsverwaltung Aigen",
      "isEjn": false
    },
    {
      "pk": 38262,
      "name": "GWM",
      "isEjn": false
    },
    {
      "pk": 39195,
      "name": "H Fast Load",
      "isEjn": false
    },
    {
      "pk": 38775,
      "name": "H. Tiemeyer",
      "isEjn": false
    },
    {
      "pk": 14479,
      "name": "H&K Müller GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 26756,
      "name": "Haberl Stefanie",
      "isEjn": false
    },
    {
      "pk": 15423,
      "name": "HadSol",
      "isEjn": false
    },
    {
      "pk": 40362,
      "name": "Hafenkante U-Hof 3 GmbH",
      "isEjn": false
    },
    {
      "pk": 26584,
      "name": "Halmstads kommun",
      "isEjn": false
    },
    {
      "pk": 39361,
      "name": "Hamburg Messe und Congress",
      "isEjn": false
    },
    {
      "pk": 33,
      "name": "Hamburger Energiewerke Mobil",
      "isEjn": false
    },
    {
      "pk": 34766,
      "name": "Hammarströms EV Charging",
      "isEjn": false
    },
    {
      "pk": 38761,
      "name": "Hanauer Parkhaus",
      "isEjn": false
    },
    {
      "pk": 38766,
      "name": "HANKO Kraftfahrzeughandel",
      "isEjn": false
    },
    {
      "pk": 33359,
      "name": "Hannes Stadler",
      "isEjn": false
    },
    {
      "pk": 13978,
      "name": "Hannesn Hof",
      "isEjn": false
    },
    {
      "pk": 13685,
      "name": "Hans Georg Maier",
      "isEjn": false
    },
    {
      "pk": 32535,
      "name": "Hans Jongerius",
      "isEjn": false
    },
    {
      "pk": 32578,
      "name": "Hans Pressinger Gasthaus Bacher",
      "isEjn": false
    },
    {
      "pk": 36406,
      "name": "Hans Seifert Ges.m.b.H.&Co.KG",
      "isEjn": false
    },
    {
      "pk": 17963,
      "name": "HANSE HOTEL Attendorn",
      "isEjn": false
    },
    {
      "pk": 36350,
      "name": "Hapimag GmbH - Bad Gastein",
      "isEjn": false
    },
    {
      "pk": 36057,
      "name": "Hapimag GmbH - Lungau",
      "isEjn": false
    },
    {
      "pk": 36401,
      "name": "Hapimag GmbH - Saalbach",
      "isEjn": false
    },
    {
      "pk": 36268,
      "name": "Hapimag GmbH - Zell am See",
      "isEjn": false
    },
    {
      "pk": 36081,
      "name": "Harald Frühauf",
      "isEjn": false
    },
    {
      "pk": 36390,
      "name": "Harham46 Hotelbetriebs GmbH",
      "isEjn": false
    },
    {
      "pk": 18785,
      "name": "Harro Höfliger",
      "isEjn": false
    },
    {
      "pk": 17736,
      "name": "Harzer Hof e.K.",
      "isEjn": false
    },
    {
      "pk": 20435,
      "name": "Hass + Hatje GmbH",
      "isEjn": false
    },
    {
      "pk": 13394,
      "name": "Haug Bioenergie GbR",
      "isEjn": false
    },
    {
      "pk": 15477,
      "name": "HAUP",
      "isEjn": false
    },
    {
      "pk": 36385,
      "name": "Haus Bergblick",
      "isEjn": false
    },
    {
      "pk": 38939,
      "name": "Haus Crystal",
      "isEjn": false
    },
    {
      "pk": 36116,
      "name": "Haus Joosten",
      "isEjn": false
    },
    {
      "pk": 36077,
      "name": "Haus Renswouw",
      "isEjn": false
    },
    {
      "pk": 20785,
      "name": "Haus Vlietländer",
      "isEjn": false
    },
    {
      "pk": 36398,
      "name": "Haus Voglreiter",
      "isEjn": false
    },
    {
      "pk": 34557,
      "name": "Hausmeisterservice Berger",
      "isEjn": false
    },
    {
      "pk": 12799,
      "name": "Hawerländer Wirtsgut ",
      "isEjn": false
    },
    {
      "pk": 17037,
      "name": "HBW Verwaltungs GmbH",
      "isEjn": false
    },
    {
      "pk": 28315,
      "name": "Heck Group GmbH",
      "isEjn": false
    },
    {
      "pk": 32491,
      "name": "Heldele",
      "isEjn": false
    },
    {
      "pk": 3754,
      "name": "Heldele GmbH CPO",
      "isEjn": false
    },
    {
      "pk": 32450,
      "name": "Helen",
      "isEjn": false
    },
    {
      "pk": 36570,
      "name": "Helmut Gruber",
      "isEjn": false
    },
    {
      "pk": 13688,
      "name": "Helmut Krüger GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 36109,
      "name": "Henk Speknijder",
      "isEjn": false
    },
    {
      "pk": 32434,
      "name": "Herbert Lugitsch&Söhne GmbH",
      "isEjn": false
    },
    {
      "pk": 18381,
      "name": "Herr Sommer",
      "isEjn": false
    },
    {
      "pk": 36391,
      "name": "HH Hinterglemmer Hotel",
      "isEjn": false
    },
    {
      "pk": 2444,
      "name": "Hikotron",
      "isEjn": false
    },
    {
      "pk": 21756,
      "name": "Hiperideal",
      "isEjn": false
    },
    {
      "pk": 36333,
      "name": "HLGS KG",
      "isEjn": false
    },
    {
      "pk": 36342,
      "name": "HMH Vermögensverwaltungs GmbH",
      "isEjn": false
    },
    {
      "pk": 18521,
      "name": "Hochfilzer GmbH & Co KG",
      "isEjn": false
    },
    {
      "pk": 36274,
      "name": "Hochkönig Bergbahnen GmbH",
      "isEjn": false
    },
    {
      "pk": 12830,
      "name": "Hochsauerlandkreis",
      "isEjn": false
    },
    {
      "pk": 29745,
      "name": "Hoeflmaier",
      "isEjn": false
    },
    {
      "pk": 38190,
      "name": "Höger`s Hotel & Restaurant GmbH",
      "isEjn": false
    },
    {
      "pk": 32328,
      "name": "HOL",
      "isEjn": false
    },
    {
      "pk": 32521,
      "name": "Holiday Inn Express",
      "isEjn": false
    },
    {
      "pk": 36334,
      "name": "Holleis Hotel GmbH",
      "isEjn": false
    },
    {
      "pk": 14486,
      "name": "holtöltsek.hu / Greenetik Retail Kft.",
      "isEjn": false
    },
    {
      "pk": 12939,
      "name": "Holzgerber",
      "isEjn": false
    },
    {
      "pk": 35036,
      "name": "Holzlodge deluxe",
      "isEjn": false
    },
    {
      "pk": 36323,
      "name": "Holztechnikum Kuchl BetriebsgmbH",
      "isEjn": false
    },
    {
      "pk": 36368,
      "name": "Horega PlanungsgmbH",
      "isEjn": false
    },
    {
      "pk": 36416,
      "name": "Hörl Peter",
      "isEjn": false
    },
    {
      "pk": 19208,
      "name": "Horst Geiger vertr  d. Weileder Immobilien GmbH & Co. KG ",
      "isEjn": false
    },
    {
      "pk": 38789,
      "name": "Horyzont EV",
      "isEjn": false
    },
    {
      "pk": 12957,
      "name": "Horyzont EV ",
      "isEjn": false
    },
    {
      "pk": 36353,
      "name": "Hotel - Gasthof Lukashansl GmbH",
      "isEjn": false
    },
    {
      "pk": 32580,
      "name": "Hotel & Restaurant Flattacherhof, Flattach 13, 9831 Flattach",
      "isEjn": false
    },
    {
      "pk": 21489,
      "name": "Hotel Alpenfrieden",
      "isEjn": false
    },
    {
      "pk": 22173,
      "name": "Hotel Alpenhof - Hintertux",
      "isEjn": false
    },
    {
      "pk": 20298,
      "name": "Hotel Alpenland",
      "isEjn": false
    },
    {
      "pk": 36111,
      "name": "Hotel Alpina Gmbh",
      "isEjn": false
    },
    {
      "pk": 33293,
      "name": "Hotel Alt Kaiser",
      "isEjn": false
    },
    {
      "pk": 12753,
      "name": "Hotel Alte Mühle GmbH",
      "isEjn": false
    },
    {
      "pk": 36289,
      "name": "Hotel Ammerhauser GmbH",
      "isEjn": false
    },
    {
      "pk": 36070,
      "name": "Hotel Astrid e.U.Steger Astrid",
      "isEjn": false
    },
    {
      "pk": 32528,
      "name": "Hotel Aurora",
      "isEjn": false
    },
    {
      "pk": 36130,
      "name": "Hotel Austria",
      "isEjn": false
    },
    {
      "pk": 36411,
      "name": "Hotel Bergheimat Kögl-Plenk KG",
      "isEjn": false
    },
    {
      "pk": 12768,
      "name": "Hotel Berghof",
      "isEjn": false
    },
    {
      "pk": 22172,
      "name": "Hotel Berghof Obertauern",
      "isEjn": false
    },
    {
      "pk": 36359,
      "name": "Hotel Bergrose KG",
      "isEjn": false
    },
    {
      "pk": 14942,
      "name": "Hotel Blü",
      "isEjn": false
    },
    {
      "pk": 30388,
      "name": "Hotel Carle",
      "isEjn": false
    },
    {
      "pk": 32527,
      "name": "Hotel Central",
      "isEjn": false
    },
    {
      "pk": 20277,
      "name": "Hotel Dolomitenblick",
      "isEjn": false
    },
    {
      "pk": 36408,
      "name": "Hotel Egger Ges.m.b.H",
      "isEjn": false
    },
    {
      "pk": 36072,
      "name": "Hotel Felben Scharler GmbH & Co KG",
      "isEjn": false
    },
    {
      "pk": 36362,
      "name": "Hotel Forellenhof",
      "isEjn": false
    },
    {
      "pk": 36113,
      "name": "Hotel Frosthofgut GmbH",
      "isEjn": false
    },
    {
      "pk": 35907,
      "name": "Hotel Garni Alpenjuwel Residenz",
      "isEjn": false
    },
    {
      "pk": 20300,
      "name": "Hotel Garni Kristall",
      "isEjn": false
    },
    {
      "pk": 36105,
      "name": "Hotel Garni Landhaus Gitti",
      "isEjn": false
    },
    {
      "pk": 36379,
      "name": "Hotel Gasthof Weitgasser",
      "isEjn": false
    },
    {
      "pk": 35324,
      "name": "Hotel Gasthof zur Krone",
      "isEjn": false
    },
    {
      "pk": 15351,
      "name": "Hotel Germania",
      "isEjn": false
    },
    {
      "pk": 32648,
      "name": "Hotel Gloeckerin",
      "isEjn": false
    },
    {
      "pk": 36039,
      "name": "Hotel Goldener Stern",
      "isEjn": false
    },
    {
      "pk": 36080,
      "name": "Hotel Grimming",
      "isEjn": false
    },
    {
      "pk": 15106,
      "name": "Hotel Heisshof",
      "isEjn": false
    },
    {
      "pk": 20316,
      "name": "Hotel Hubertus",
      "isEjn": false
    },
    {
      "pk": 13310,
      "name": "Hotel Jagdhaus Wiese",
      "isEjn": false
    },
    {
      "pk": 32917,
      "name": "Hotel Juenmi",
      "isEjn": false
    },
    {
      "pk": 32662,
      "name": "Hotel Kesselspitze",
      "isEjn": false
    },
    {
      "pk": 12771,
      "name": "Hotel Kleins Wiese",
      "isEjn": false
    },
    {
      "pk": 36366,
      "name": "Hotel Königgut",
      "isEjn": false
    },
    {
      "pk": 35135,
      "name": "Hotel Lücke GmbH",
      "isEjn": false
    },
    {
      "pk": 32598,
      "name": "Hotel Panorama Alpin",
      "isEjn": false
    },
    {
      "pk": 36392,
      "name": "Hotel Pension Sieglhub",
      "isEjn": false
    },
    {
      "pk": 36316,
      "name": "Hotel Pension Theresia",
      "isEjn": false
    },
    {
      "pk": 12891,
      "name": "Hotel Post",
      "isEjn": false
    },
    {
      "pk": 18677,
      "name": "Hotel Restaurant Steinbock",
      "isEjn": false
    },
    {
      "pk": 37478,
      "name": "Hotel Roslehen GmbH",
      "isEjn": false
    },
    {
      "pk": 36332,
      "name": "Hotel Salzburgerhof",
      "isEjn": false
    },
    {
      "pk": 36060,
      "name": "Hotel Seitenalm GmbH",
      "isEjn": false
    },
    {
      "pk": 36329,
      "name": "Hotel Sonnberghof GmbH & Co KG",
      "isEjn": false
    },
    {
      "pk": 36319,
      "name": "Hotel Sonne GmbH",
      "isEjn": false
    },
    {
      "pk": 34276,
      "name": "Hotel Sonne Mellau Gmbh",
      "isEjn": false
    },
    {
      "pk": 36384,
      "name": "Hotel St. Hubertus KG",
      "isEjn": false
    },
    {
      "pk": 32638,
      "name": "Hotel Stacklerhof",
      "isEjn": false
    },
    {
      "pk": 13582,
      "name": "Hotel Stadt Hamburg",
      "isEjn": false
    },
    {
      "pk": 36075,
      "name": "Hotel Steiner Obertauern GmbH & Co KG",
      "isEjn": false
    },
    {
      "pk": 23510,
      "name": "Hotel Stern",
      "isEjn": false
    },
    {
      "pk": 36347,
      "name": "Hotel Theresia GmbH",
      "isEjn": false
    },
    {
      "pk": 36104,
      "name": "Hotel Thurnerhof",
      "isEjn": false
    },
    {
      "pk": 36089,
      "name": "Hotel Tirolerbuam GmbH & Co KG",
      "isEjn": false
    },
    {
      "pk": 36374,
      "name": "Hotel u. Gasthof Hölle e. U",
      "isEjn": false
    },
    {
      "pk": 17724,
      "name": "Hotel und Restaurant Steinbock",
      "isEjn": false
    },
    {
      "pk": 24666,
      "name": "Hotel Unterschattmair",
      "isEjn": false
    },
    {
      "pk": 13388,
      "name": "Hotel Vier Jahreszeiten",
      "isEjn": false
    },
    {
      "pk": 13987,
      "name": "Hotel VILA VITA",
      "isEjn": false
    },
    {
      "pk": 32526,
      "name": "Hotel Waidmannsheil",
      "isEjn": false
    },
    {
      "pk": 32612,
      "name": "Hotel Weiß GmbH",
      "isEjn": false
    },
    {
      "pk": 13392,
      "name": "Hotel Winterbauer GmbH",
      "isEjn": false
    },
    {
      "pk": 26403,
      "name": "Hotel Wöscherhof eU",
      "isEjn": false
    },
    {
      "pk": 29260,
      "name": "Hotel Zum alten Schloss",
      "isEjn": false
    },
    {
      "pk": 32565,
      "name": "Hotel zum Kramerwirt",
      "isEjn": false
    },
    {
      "pk": 27239,
      "name": "Hotel zum Turm",
      "isEjn": false
    },
    {
      "pk": 12802,
      "name": "Hotel-Restaurant \"Wattenkieker\" GbR",
      "isEjn": false
    },
    {
      "pk": 38630,
      "name": "Hovden Hytteservice",
      "isEjn": false
    },
    {
      "pk": 38742,
      "name": "HSB Brf",
      "isEjn": false
    },
    {
      "pk": 41167,
      "name": "HTB-Chargepoint",
      "isEjn": false
    },
    {
      "pk": 36338,
      "name": "Huber Christian",
      "isEjn": false
    },
    {
      "pk": 17928,
      "name": "Hubtex",
      "isEjn": false
    },
    {
      "pk": 38786,
      "name": "Hummel Systemhaus",
      "isEjn": false
    },
    {
      "pk": 24629,
      "name": "Hummel Systemhaus ",
      "isEjn": false
    },
    {
      "pk": 12965,
      "name": "Huss Verlag",
      "isEjn": false
    },
    {
      "pk": 38150,
      "name": "HVAC-Techniek B.V.",
      "isEjn": false
    },
    {
      "pk": 40500,
      "name": "HX Power Charger & Solar GmbH",
      "isEjn": false
    },
    {
      "pk": 15099,
      "name": "Hypercharge",
      "isEjn": false
    },
    {
      "pk": 700,
      "name": "Hyundai",
      "isEjn": false
    },
    {
      "pk": 37986,
      "name": "IBC SOLAR B.V.",
      "isEjn": false
    },
    {
      "pk": 36301,
      "name": "Ibex Residences OG",
      "isEjn": false
    },
    {
      "pk": 22024,
      "name": "ICA Kvantum Lerum",
      "isEjn": false
    },
    {
      "pk": 17728,
      "name": "Ica Maxi Stormarknad Kristinehamn",
      "isEjn": false
    },
    {
      "pk": 16420,
      "name": "iCharging",
      "isEjn": false
    },
    {
      "pk": 19876,
      "name": "IEG Feuerbach GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 12897,
      "name": "IG Immobilien Invest GmbH",
      "isEjn": false
    },
    {
      "pk": 17453,
      "name": "Ignitis",
      "isEjn": false
    },
    {
      "pk": 39153,
      "name": "Ignitis ON",
      "isEjn": false
    },
    {
      "pk": 13623,
      "name": "Ignitis UAB (LT)",
      "isEjn": false
    },
    {
      "pk": 32641,
      "name": "IGZ Innovations- und Gründerzentrum Nürnberg-Fürth-Erlangen GmbH",
      "isEjn": false
    },
    {
      "pk": 3701,
      "name": "IKB AG (Innsbrucker Kommunalbetriebe) CPO",
      "isEjn": false
    },
    {
      "pk": 180,
      "name": "IKEA",
      "isEjn": false
    },
    {
      "pk": 36407,
      "name": "IKO Europe GmbH",
      "isEjn": false
    },
    {
      "pk": 13046,
      "name": "Ikrautas",
      "isEjn": false
    },
    {
      "pk": 39568,
      "name": "Immo Immobilientreuhand GmbH",
      "isEjn": false
    },
    {
      "pk": 14947,
      "name": "Impulshotel Tirol",
      "isEjn": false
    },
    {
      "pk": 38480,
      "name": "In de Rimboe",
      "isEjn": false
    },
    {
      "pk": 32493,
      "name": "in-tech",
      "isEjn": false
    },
    {
      "pk": 14024,
      "name": "In(n) Energie GmbH",
      "isEjn": false
    },
    {
      "pk": 12796,
      "name": "Ina Hansen-Kern",
      "isEjn": false
    },
    {
      "pk": 2675,
      "name": "Inbalance",
      "isEjn": false
    },
    {
      "pk": 13624,
      "name": "Inbalance grid (LT)",
      "isEjn": false
    },
    {
      "pk": 32547,
      "name": "InCharge SE",
      "isEjn": false
    },
    {
      "pk": 40223,
      "name": "InCharge US",
      "isEjn": false
    },
    {
      "pk": 39008,
      "name": "Incrementum",
      "isEjn": false
    },
    {
      "pk": 39360,
      "name": "Independent Operators",
      "isEjn": false
    },
    {
      "pk": 40501,
      "name": "INDICE S.A.",
      "isEjn": false
    },
    {
      "pk": 12818,
      "name": "Industrie & Service GmbH",
      "isEjn": false
    },
    {
      "pk": 29088,
      "name": "Industrie- und Handelskammer Lahn-Dill",
      "isEjn": false
    },
    {
      "pk": 32554,
      "name": "INFOLIO Verpackungs GmbH",
      "isEjn": false
    },
    {
      "pk": 23229,
      "name": "ING",
      "isEjn": false
    },
    {
      "pk": 34733,
      "name": "Ing.Weberschläger GmbH",
      "isEjn": false
    },
    {
      "pk": 12780,
      "name": "Ingo Brandt",
      "isEjn": false
    },
    {
      "pk": 36306,
      "name": "Ingrid Rapp",
      "isEjn": false
    },
    {
      "pk": 41160,
      "name": "InnoKnight",
      "isEjn": false
    },
    {
      "pk": 3444,
      "name": "INOUId Smart ECharging",
      "isEjn": false
    },
    {
      "pk": 3686,
      "name": "Inselwerke",
      "isEjn": false
    },
    {
      "pk": 41553,
      "name": "InstaVolt UK CPO",
      "isEjn": false
    },
    {
      "pk": 30393,
      "name": "Integrierte Dienste, iD GmbH",
      "isEjn": false
    },
    {
      "pk": 35595,
      "name": "Intelbras",
      "isEjn": false
    },
    {
      "pk": 13662,
      "name": "Interparking Poland",
      "isEjn": false
    },
    {
      "pk": 23016,
      "name": "Intertie",
      "isEjn": false
    },
    {
      "pk": 13387,
      "name": "ION",
      "isEjn": false
    },
    {
      "pk": 22777,
      "name": "IP Innovative Power GmbH",
      "isEjn": false
    },
    {
      "pk": 12793,
      "name": "Isabell&Thomasm Giese",
      "isEjn": false
    },
    {
      "pk": 671,
      "name": "Isorka",
      "isEjn": false
    },
    {
      "pk": 25136,
      "name": "Ispirli",
      "isEjn": false
    },
    {
      "pk": 13684,
      "name": "Ivo Majerotto",
      "isEjn": false
    },
    {
      "pk": 34933,
      "name": "Ivy",
      "isEjn": false
    },
    {
      "pk": 32499,
      "name": "IWB",
      "isEjn": false
    },
    {
      "pk": 14480,
      "name": "J. & J. Kampichler Gesellschaft m.b.H.",
      "isEjn": false
    },
    {
      "pk": 14483,
      "name": "J. MÜLLER Weser GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 12835,
      "name": "J. MÜLLER Weser GmbH u. Co. KG",
      "isEjn": false
    },
    {
      "pk": 37325,
      "name": "Jan Hager",
      "isEjn": false
    },
    {
      "pk": 36055,
      "name": "Jan Linderman",
      "isEjn": false
    },
    {
      "pk": 16177,
      "name": "Jan Otto UG",
      "isEjn": false
    },
    {
      "pk": 36361,
      "name": "Jausenstation Habersattgut",
      "isEjn": false
    },
    {
      "pk": 18383,
      "name": "JB Charge",
      "isEjn": false
    },
    {
      "pk": 38007,
      "name": "JBI Charging",
      "isEjn": false
    },
    {
      "pk": 15581,
      "name": "Jeep",
      "isEjn": false
    },
    {
      "pk": 2928,
      "name": "JET Charge",
      "isEjn": false
    },
    {
      "pk": 41015,
      "name": "JET Tankstellen Austria GmbH",
      "isEjn": false
    },
    {
      "pk": 37224,
      "name": "JET Tankstellen Deutschland GmbH",
      "isEjn": false
    },
    {
      "pk": 36388,
      "name": "JGC Lengau",
      "isEjn": false
    },
    {
      "pk": 32537,
      "name": "JHO",
      "isEjn": false
    },
    {
      "pk": 41166,
      "name": "JINPOWER",
      "isEjn": false
    },
    {
      "pk": 30272,
      "name": "JLR Charging",
      "isEjn": false
    },
    {
      "pk": 34344,
      "name": "JOE",
      "isEjn": false
    },
    {
      "pk": 36088,
      "name": "Johann Heigl",
      "isEjn": false
    },
    {
      "pk": 36037,
      "name": "Johannes Kracher",
      "isEjn": false
    },
    {
      "pk": 20293,
      "name": "John/Röthe",
      "isEjn": false
    },
    {
      "pk": 1161,
      "name": "Joju Ltd",
      "isEjn": false
    },
    {
      "pk": 25971,
      "name": "JOLT",
      "isEjn": false
    },
    {
      "pk": 41049,
      "name": "Jolt Sweden A/B",
      "isEjn": false
    },
    {
      "pk": 41380,
      "name": "Jolt Sweden AB",
      "isEjn": false
    },
    {
      "pk": 34697,
      "name": "JomCharge",
      "isEjn": false
    },
    {
      "pk": 22171,
      "name": "Jonas Janssen",
      "isEjn": false
    },
    {
      "pk": 38804,
      "name": "Jörg Kurt",
      "isEjn": false
    },
    {
      "pk": 25062,
      "name": "Jörg Kurt ",
      "isEjn": false
    },
    {
      "pk": 36275,
      "name": "Josef Dick GmbH",
      "isEjn": false
    },
    {
      "pk": 36383,
      "name": "Josef Landa",
      "isEjn": false
    },
    {
      "pk": 12779,
      "name": "Josefs Gesellschaft gGmbH Haus Rheinfrieden",
      "isEjn": false
    },
    {
      "pk": 40498,
      "name": "JSD GmbH",
      "isEjn": false
    },
    {
      "pk": 36307,
      "name": "JUFA Hotels Österreich GmbH - Graz",
      "isEjn": false
    },
    {
      "pk": 36382,
      "name": "Jufenalm GmbH & Co KG",
      "isEjn": false
    },
    {
      "pk": 36052,
      "name": "Jugendalpincenter GmbH",
      "isEjn": false
    },
    {
      "pk": 18363,
      "name": "Jule",
      "isEjn": false
    },
    {
      "pk": 13442,
      "name": "Julia Hauch",
      "isEjn": false
    },
    {
      "pk": 36375,
      "name": "Julian Schlosser",
      "isEjn": false
    },
    {
      "pk": 12832,
      "name": "Jürgen Awe",
      "isEjn": false
    },
    {
      "pk": 12757,
      "name": "K3-Hotel-Wust",
      "isEjn": false
    },
    {
      "pk": 40185,
      "name": "Kaatee Transport",
      "isEjn": false
    },
    {
      "pk": 20437,
      "name": "Kai Stubenrauch",
      "isEjn": false
    },
    {
      "pk": 36027,
      "name": "Kaiserer GmbH",
      "isEjn": false
    },
    {
      "pk": 38481,
      "name": "Kaiserkraft Naturprodukte e.U.",
      "isEjn": false
    },
    {
      "pk": 13391,
      "name": "Karl Elser Druck GmbH",
      "isEjn": false
    },
    {
      "pk": 12850,
      "name": "Karl Heinz Maerzke",
      "isEjn": false
    },
    {
      "pk": 32452,
      "name": "Karl Stengle",
      "isEjn": false
    },
    {
      "pk": 12845,
      "name": "Karsten Abel",
      "isEjn": false
    },
    {
      "pk": 924,
      "name": "Kaufland",
      "isEjn": false
    },
    {
      "pk": 13049,
      "name": "Kauno miesto savivaldybės administracija",
      "isEjn": false
    },
    {
      "pk": 32470,
      "name": "KEB",
      "isEjn": false
    },
    {
      "pk": 36108,
      "name": "Kees Hotel Salzburg GmbH & Co KG",
      "isEjn": false
    },
    {
      "pk": 26259,
      "name": "Keimfarben",
      "isEjn": false
    },
    {
      "pk": 34554,
      "name": "Keppel",
      "isEjn": false
    },
    {
      "pk": 34659,
      "name": "Keppel Volt",
      "isEjn": false
    },
    {
      "pk": 12837,
      "name": "KFS Biodiesel Kassel GmbH",
      "isEjn": false
    },
    {
      "pk": 24249,
      "name": "KFZ Technik Schwab",
      "isEjn": false
    },
    {
      "pk": 30379,
      "name": "KH Energie GbR",
      "isEjn": false
    },
    {
      "pk": 34207,
      "name": "Khumbu Energy AB",
      "isEjn": false
    },
    {
      "pk": 1378,
      "name": "Kia",
      "isEjn": false
    },
    {
      "pk": 16436,
      "name": "KiBostCar",
      "isEjn": false
    },
    {
      "pk": 30273,
      "name": "Kilowatt",
      "isEjn": false
    },
    {
      "pk": 12776,
      "name": "Kim Rodenburg ",
      "isEjn": false
    },
    {
      "pk": 15175,
      "name": "Kinder- und Jugenddorf",
      "isEjn": false
    },
    {
      "pk": 15356,
      "name": "KIRCHHOFF Automotive Deutschland GmbH",
      "isEjn": false
    },
    {
      "pk": 15751,
      "name": "kiwEV",
      "isEjn": false
    },
    {
      "pk": 26574,
      "name": "KL Energie GmbH",
      "isEjn": false
    },
    {
      "pk": 13051,
      "name": "Klaipėdos rajono savivaldybės administracija",
      "isEjn": false
    },
    {
      "pk": 38146,
      "name": "KLE",
      "isEjn": false
    },
    {
      "pk": 38265,
      "name": "Klepp Energi",
      "isEjn": false
    },
    {
      "pk": 36272,
      "name": "Klinikum Austria Gesundheitsgruppe GmbH",
      "isEjn": false
    },
    {
      "pk": 16821,
      "name": "KM Brau-Manufactur Allgaeu ",
      "isEjn": false
    },
    {
      "pk": 15601,
      "name": "KMUTT",
      "isEjn": false
    },
    {
      "pk": 20232,
      "name": "KNA",
      "isEjn": false
    },
    {
      "pk": 12894,
      "name": "KnePo Kunststofftechnik GmbH",
      "isEjn": false
    },
    {
      "pk": 30385,
      "name": "Koblenz",
      "isEjn": false
    },
    {
      "pk": 14953,
      "name": "Koch Freiburg",
      "isEjn": false
    },
    {
      "pk": 36376,
      "name": "Kogler GmbH - Sporthotel Kogler",
      "isEjn": false
    },
    {
      "pk": 40503,
      "name": "KOLLERWIRT",
      "isEjn": false
    },
    {
      "pk": 36056,
      "name": "Kolpingsfamilie Salzburg Zentral",
      "isEjn": false
    },
    {
      "pk": 20223,
      "name": "Kommunalunternehmen Stadtwerke Penzberg",
      "isEjn": false
    },
    {
      "pk": 13761,
      "name": "Königs + Woisetschläger GmbH",
      "isEjn": false
    },
    {
      "pk": 165,
      "name": "Kople",
      "isEjn": false
    },
    {
      "pk": 38154,
      "name": "Kopper Automotive B.V.",
      "isEjn": false
    },
    {
      "pk": 27032,
      "name": "Kostner GmbH",
      "isEjn": false
    },
    {
      "pk": 23748,
      "name": "KP",
      "isEjn": false
    },
    {
      "pk": 27390,
      "name": "KR-Charging",
      "isEjn": false
    },
    {
      "pk": 26580,
      "name": "Kraftringen Energi AB",
      "isEjn": false
    },
    {
      "pk": 12820,
      "name": "Kramer Elektro-Anlagen GmbH",
      "isEjn": false
    },
    {
      "pk": 39462,
      "name": "Kreis Herzogtum Lauenburg",
      "isEjn": false
    },
    {
      "pk": 32670,
      "name": "Kretschmann Andreas Photovoltaikanlagen",
      "isEjn": false
    },
    {
      "pk": 32608,
      "name": "Kreuzmayr GmbH",
      "isEjn": false
    },
    {
      "pk": 20303,
      "name": "Kronplatz GmbH",
      "isEjn": false
    },
    {
      "pk": 14125,
      "name": "KS Verwaltung",
      "isEjn": false
    },
    {
      "pk": 32569,
      "name": "KS Verwaltungsgesellschaft",
      "isEjn": false
    },
    {
      "pk": 39949,
      "name": "KSE",
      "isEjn": false
    },
    {
      "pk": 17921,
      "name": "Kuchlbauer",
      "isEjn": false
    },
    {
      "pk": 35529,
      "name": "KUF",
      "isEjn": false
    },
    {
      "pk": 38803,
      "name": "Kühlanlagen Postl GmbH",
      "isEjn": false
    },
    {
      "pk": 32668,
      "name": "Kühlanlagen Postl GmbH ",
      "isEjn": false
    },
    {
      "pk": 32614,
      "name": "Kurcamping Badgastein",
      "isEjn": false
    },
    {
      "pk": 39396,
      "name": "Kuster Energy",
      "isEjn": false
    },
    {
      "pk": 38009,
      "name": "KW Sulzau GmbH",
      "isEjn": false
    },
    {
      "pk": 36119,
      "name": "KW Wiesermühle GmbH",
      "isEjn": false
    },
    {
      "pk": 38517,
      "name": "kW-Solutions",
      "isEjn": false
    },
    {
      "pk": 23855,
      "name": "kWatt Sävast",
      "isEjn": false
    },
    {
      "pk": 20306,
      "name": "KWS",
      "isEjn": false
    },
    {
      "pk": 32556,
      "name": "La Ricarica Elettrica Udine",
      "isEjn": false
    },
    {
      "pk": 23120,
      "name": "la_plagne",
      "isEjn": false
    },
    {
      "pk": 19596,
      "name": "Laddel",
      "isEjn": false
    },
    {
      "pk": 32503,
      "name": "Laddkoll SE",
      "isEjn": false
    },
    {
      "pk": 15682,
      "name": "Ladegrün! eG",
      "isEjn": false
    },
    {
      "pk": 499,
      "name": "Ladenetz",
      "isEjn": false
    },
    {
      "pk": 26575,
      "name": "LADEPARKMEININGEN",
      "isEjn": false
    },
    {
      "pk": 13039,
      "name": "Ladesäule befindet sich im nördlichen Bereich des Reitbetrieb",
      "isEjn": false
    },
    {
      "pk": 21308,
      "name": "Ladestart",
      "isEjn": false
    },
    {
      "pk": 12899,
      "name": "Ladestation befindet sich im nördlichen Teil des Reitbetrieb",
      "isEjn": false
    },
    {
      "pk": 13625,
      "name": "LAKD (LT)",
      "isEjn": false
    },
    {
      "pk": 36341,
      "name": "Lakenmache Hotel GmbH",
      "isEjn": false
    },
    {
      "pk": 12746,
      "name": "Lamping Systemtechnik GmbH",
      "isEjn": false
    },
    {
      "pk": 36396,
      "name": "Landgasthof Schloss Kammer",
      "isEjn": false
    },
    {
      "pk": 33492,
      "name": "Landgasthof Zur Burg Grenzau",
      "isEjn": false
    },
    {
      "pk": 24807,
      "name": "Landhaus Huber",
      "isEjn": false
    },
    {
      "pk": 30230,
      "name": "Landhotel Berger",
      "isEjn": false
    },
    {
      "pk": 22222,
      "name": "Landhotel Denggerhof",
      "isEjn": false
    },
    {
      "pk": 14129,
      "name": "Landhotel Dresden",
      "isEjn": false
    },
    {
      "pk": 33874,
      "name": "Landhotel Hoisl-Bräu",
      "isEjn": false
    },
    {
      "pk": 12895,
      "name": "Landhotel Küffner GbR.",
      "isEjn": false
    },
    {
      "pk": 12745,
      "name": "Landhotel Seeg",
      "isEjn": false
    },
    {
      "pk": 41453,
      "name": "Landkreis Schwandorf",
      "isEjn": false
    },
    {
      "pk": 29400,
      "name": "Landratsamt Mühldorf am Inn",
      "isEjn": false
    },
    {
      "pk": 23857,
      "name": "Landrins Bil",
      "isEjn": false
    },
    {
      "pk": 31985,
      "name": "Lange & Hofmeister Haus- und Grundstücksverwaltung GmbH",
      "isEjn": false
    },
    {
      "pk": 21631,
      "name": "langhofer quality hosting and solutions gmbh",
      "isEjn": false
    },
    {
      "pk": 13977,
      "name": "Lansigt Accountants & Belastingadviseurs B.V.",
      "isEjn": false
    },
    {
      "pk": 12826,
      "name": "Lauffener Weingärtner EG",
      "isEjn": false
    },
    {
      "pk": 13050,
      "name": "Lazdijų rajono savivaldybės administracija",
      "isEjn": false
    },
    {
      "pk": 20258,
      "name": "LDK",
      "isEjn": false
    },
    {
      "pk": 13762,
      "name": "LEF",
      "isEjn": false
    },
    {
      "pk": 37985,
      "name": "Legoland",
      "isEjn": false
    },
    {
      "pk": 13429,
      "name": "LEKTRI.CO",
      "isEjn": false
    },
    {
      "pk": 41165,
      "name": "Lemon Power",
      "isEjn": false
    },
    {
      "pk": 41449,
      "name": "Lenhart Automobilservice GbR",
      "isEjn": false
    },
    {
      "pk": 36084,
      "name": "Leni Boeren",
      "isEjn": false
    },
    {
      "pk": 36363,
      "name": "Leoganger Bergbahnen GmbH",
      "isEjn": false
    },
    {
      "pk": 36414,
      "name": "Lerchpeuntgut GmbH & Co KG",
      "isEjn": false
    },
    {
      "pk": 22575,
      "name": "LexEnergy",
      "isEjn": false
    },
    {
      "pk": 32588,
      "name": "LGB Operations GmbH",
      "isEjn": false
    },
    {
      "pk": 36279,
      "name": "LH Gastronomie GmbH",
      "isEjn": false
    },
    {
      "pk": 30380,
      "name": "Liebevoll",
      "isEjn": false
    },
    {
      "pk": 12794,
      "name": "Liepkalni SIA",
      "isEjn": false
    },
    {
      "pk": 14954,
      "name": "Life with Style GmbH",
      "isEjn": false
    },
    {
      "pk": 41202,
      "name": "Liikennevirta Oy",
      "isEjn": false
    },
    {
      "pk": 38736,
      "name": "Lillehammer kommune",
      "isEjn": false
    },
    {
      "pk": 41156,
      "name": "LINBROS",
      "isEjn": false
    },
    {
      "pk": 33948,
      "name": "Linga Network",
      "isEjn": false
    },
    {
      "pk": 32583,
      "name": "Lithuanian Road Administration (LAKD)",
      "isEjn": false
    },
    {
      "pk": 1939,
      "name": "Livingston",
      "isEjn": false
    },
    {
      "pk": 19914,
      "name": "Livingston Energy",
      "isEjn": false
    },
    {
      "pk": 30344,
      "name": "LLM Ladelösung GmbH",
      "isEjn": false
    },
    {
      "pk": 20320,
      "name": "LNT",
      "isEjn": false
    },
    {
      "pk": 36097,
      "name": "Löckerwirt GmbH & Co KG",
      "isEjn": false
    },
    {
      "pk": 3533,
      "name": "Lodder EV",
      "isEjn": false
    },
    {
      "pk": 32443,
      "name": "Loherhof",
      "isEjn": false
    },
    {
      "pk": 37987,
      "name": "Lok Installatietechniek BV",
      "isEjn": false
    },
    {
      "pk": 18361,
      "name": "Loop",
      "isEjn": false
    },
    {
      "pk": 27101,
      "name": "Lothar Schreiber",
      "isEjn": false
    },
    {
      "pk": 15602,
      "name": "Loxley",
      "isEjn": false
    },
    {
      "pk": 12838,
      "name": "LRA Miesbach",
      "isEjn": false
    },
    {
      "pk": 20319,
      "name": "LSB",
      "isEjn": false
    },
    {
      "pk": 28146,
      "name": "LT Ultra-Precision Technology GmbH Aftholderberg, Wiesenstrasse 9 D-88634 Herdwangen-Schönach",
      "isEjn": false
    },
    {
      "pk": 12968,
      "name": "Luc Paquay",
      "isEjn": false
    },
    {
      "pk": 37996,
      "name": "Luc's place",
      "isEjn": false
    },
    {
      "pk": 27795,
      "name": "Lucassen",
      "isEjn": false
    },
    {
      "pk": 25142,
      "name": "Luichtl",
      "isEjn": false
    },
    {
      "pk": 22812,
      "name": "Luichtl Wasser & Wärme GmbH",
      "isEjn": false
    },
    {
      "pk": 24630,
      "name": "Lukas Gstrein",
      "isEjn": false
    },
    {
      "pk": 25135,
      "name": "Lumicle",
      "isEjn": false
    },
    {
      "pk": 41272,
      "name": "Lumosa",
      "isEjn": false
    },
    {
      "pk": 13759,
      "name": "Lutz GmbH",
      "isEjn": false
    },
    {
      "pk": 36123,
      "name": "Luzia Wallner",
      "isEjn": false
    },
    {
      "pk": 20239,
      "name": "LVP",
      "isEjn": false
    },
    {
      "pk": 25180,
      "name": "lynkwel",
      "isEjn": false
    },
    {
      "pk": 30231,
      "name": "Lynkwell",
      "isEjn": false
    },
    {
      "pk": 32649,
      "name": "MA Automotive Im Finingen 2 28832 Achim",
      "isEjn": false
    },
    {
      "pk": 20313,
      "name": "MA AutomotiveIm Finingen 228832 Achim",
      "isEjn": false
    },
    {
      "pk": 40504,
      "name": "MaazTD",
      "isEjn": false
    },
    {
      "pk": 1628,
      "name": "Magenta Grupa TAURON",
      "isEjn": false
    },
    {
      "pk": 36321,
      "name": "Maier Touristik GmbH",
      "isEjn": false
    },
    {
      "pk": 12808,
      "name": "Mairie de Beaugency",
      "isEjn": false
    },
    {
      "pk": 32605,
      "name": "Maisalm",
      "isEjn": false
    },
    {
      "pk": 36296,
      "name": "Maiskogelblick GmbH",
      "isEjn": false
    },
    {
      "pk": 1320,
      "name": "Malanka",
      "isEjn": false
    },
    {
      "pk": 24806,
      "name": "Malerwinkl",
      "isEjn": false
    },
    {
      "pk": 23303,
      "name": "Malung Sälens Kommun",
      "isEjn": false
    },
    {
      "pk": 36083,
      "name": "Manfred Nindl",
      "isEjn": false
    },
    {
      "pk": 15998,
      "name": "Manni Energy",
      "isEjn": false
    },
    {
      "pk": 12812,
      "name": "Marc Gärtner",
      "isEjn": false
    },
    {
      "pk": 12967,
      "name": "Marc Munsters",
      "isEjn": false
    },
    {
      "pk": 17349,
      "name": "Marco Pfeifer",
      "isEjn": false
    },
    {
      "pk": 36378,
      "name": "Marcus und Jeanett Heerink",
      "isEjn": false
    },
    {
      "pk": 14759,
      "name": "Marine Charging Point",
      "isEjn": false
    },
    {
      "pk": 12889,
      "name": "Marion & Falk Schuppan",
      "isEjn": false
    },
    {
      "pk": 25671,
      "name": "Markt Rattelsdorf",
      "isEjn": false
    },
    {
      "pk": 25670,
      "name": "Markt Rattelsdorf.",
      "isEjn": false
    },
    {
      "pk": 24808,
      "name": "Marktgemeinde Anger",
      "isEjn": false
    },
    {
      "pk": 36288,
      "name": "Marktgemeinde Schwarzach",
      "isEjn": false
    },
    {
      "pk": 22293,
      "name": "Marktgemeinde St. Lambrecht",
      "isEjn": false
    },
    {
      "pk": 36397,
      "name": "Marktgemeinde Taxenbach",
      "isEjn": false
    },
    {
      "pk": 22063,
      "name": "Marktgemeinde Ternberg",
      "isEjn": false
    },
    {
      "pk": 2142,
      "name": "Marktgemeinde Vösendorf",
      "isEjn": false
    },
    {
      "pk": 18676,
      "name": "Markus Bellinger",
      "isEjn": false
    },
    {
      "pk": 36068,
      "name": "Markus Klarwunn - Hotel Apartment",
      "isEjn": false
    },
    {
      "pk": 36364,
      "name": "Markus Rauh",
      "isEjn": false
    },
    {
      "pk": 32645,
      "name": "Markus Schuler",
      "isEjn": false
    },
    {
      "pk": 38191,
      "name": "Markus Steinke MS-IT-Electronics",
      "isEjn": false
    },
    {
      "pk": 15103,
      "name": "Markus und Stefanie Steinlein",
      "isEjn": false
    },
    {
      "pk": 27867,
      "name": "Markus Zoeggeler",
      "isEjn": false
    },
    {
      "pk": 31606,
      "name": "Marne et Gondoire",
      "isEjn": false
    },
    {
      "pk": 38783,
      "name": "Marriott",
      "isEjn": false
    },
    {
      "pk": 40084,
      "name": "Martens en Van Oord",
      "isEjn": false
    },
    {
      "pk": 36031,
      "name": "Martin Strebl | Ferienwohnungen Falkenstein",
      "isEjn": false
    },
    {
      "pk": 41269,
      "name": "MARXCHARGING",
      "isEjn": false
    },
    {
      "pk": 12762,
      "name": "Matthias Bäuerle",
      "isEjn": false
    },
    {
      "pk": 36066,
      "name": "Matthias Schwaiger",
      "isEjn": false
    },
    {
      "pk": 12844,
      "name": "Matthieu Neering",
      "isEjn": false
    },
    {
      "pk": 36369,
      "name": "mbc Maschinenbau Coban GmbH",
      "isEjn": false
    },
    {
      "pk": 12828,
      "name": "MBR",
      "isEjn": false
    },
    {
      "pk": 20260,
      "name": "MDS",
      "isEjn": false
    },
    {
      "pk": 15498,
      "name": "MEA EV",
      "isEjn": false
    },
    {
      "pk": 36045,
      "name": "Mechatronik Austria GmbH",
      "isEjn": false
    },
    {
      "pk": 15894,
      "name": "Medig Betreibergesellschaft",
      "isEjn": false
    },
    {
      "pk": 32483,
      "name": "medl",
      "isEjn": false
    },
    {
      "pk": 20279,
      "name": "Mehrwert Liegenschaftsverwaltung GmbH",
      "isEjn": false
    },
    {
      "pk": 19351,
      "name": "MEHRWERT Versicherungsmakler Wolfgang Reicht e.U.",
      "isEjn": false
    },
    {
      "pk": 32325,
      "name": "Meierhof Betriebs GmbH",
      "isEjn": false
    },
    {
      "pk": 30794,
      "name": "MeinMax",
      "isEjn": false
    },
    {
      "pk": 30863,
      "name": "MeinMaxx",
      "isEjn": false
    },
    {
      "pk": 1132,
      "name": "MELIB",
      "isEjn": false
    },
    {
      "pk": 36415,
      "name": "Menapace GnbR",
      "isEjn": false
    },
    {
      "pk": 32572,
      "name": "Menge GmbH",
      "isEjn": false
    },
    {
      "pk": 26570,
      "name": "MENGE_GMBH",
      "isEjn": false
    },
    {
      "pk": 1377,
      "name": "Mercadona",
      "isEjn": false
    },
    {
      "pk": 41153,
      "name": "Mercedes",
      "isEjn": false
    },
    {
      "pk": 26335,
      "name": "Mercedes-Benz Charging Network",
      "isEjn": false
    },
    {
      "pk": 33056,
      "name": "Meridian Energy",
      "isEjn": false
    },
    {
      "pk": 2285,
      "name": "MERIDIAN ENERGY",
      "isEjn": false
    },
    {
      "pk": 36493,
      "name": "Mesnerhaus GmbH",
      "isEjn": false
    },
    {
      "pk": 691,
      "name": "Meter Manager",
      "isEjn": false
    },
    {
      "pk": 34658,
      "name": "MG Motor",
      "isEjn": false
    },
    {
      "pk": 15500,
      "name": "MG Super Charge",
      "isEjn": false
    },
    {
      "pk": 12798,
      "name": "Michael und Heidrun Wegener",
      "isEjn": false
    },
    {
      "pk": 36008,
      "name": "Michiel ten Brinke",
      "isEjn": false
    },
    {
      "pk": 40502,
      "name": "Middle Tennessee Electric Electric Era EV Fast",
      "isEjn": false
    },
    {
      "pk": 30382,
      "name": "Mieter- und Bauverein Karlsruhe eG",
      "isEjn": false
    },
    {
      "pk": 15101,
      "name": "Mihm Thermobau GmbH",
      "isEjn": false
    },
    {
      "pk": 27658,
      "name": "Milence",
      "isEjn": false
    },
    {
      "pk": 41448,
      "name": "Milence Sweden AB",
      "isEjn": false
    },
    {
      "pk": 27794,
      "name": "Milepost AB",
      "isEjn": false
    },
    {
      "pk": 38011,
      "name": "Milltown Golf Club",
      "isEjn": false
    },
    {
      "pk": 37994,
      "name": "MindFuture Hub - Employees Only",
      "isEjn": false
    },
    {
      "pk": 36310,
      "name": "Mitterlengau GmbH - Hotel Hubertushof",
      "isEjn": false
    },
    {
      "pk": 40086,
      "name": "MLK",
      "isEjn": false
    },
    {
      "pk": 20269,
      "name": "MNV",
      "isEjn": false
    },
    {
      "pk": 33495,
      "name": "MO.Point Mobilitätsservices GmbH",
      "isEjn": false
    },
    {
      "pk": 12805,
      "name": "MOA",
      "isEjn": false
    },
    {
      "pk": 34057,
      "name": "Moberly Motors Electric Era EV Fast",
      "isEjn": false
    },
    {
      "pk": 33360,
      "name": "Mobido",
      "isEjn": false
    },
    {
      "pk": 39669,
      "name": "Moerbei 6",
      "isEjn": false
    },
    {
      "pk": 15478,
      "name": "MOESK",
      "isEjn": false
    },
    {
      "pk": 38153,
      "name": "Mombarg Beton",
      "isEjn": false
    },
    {
      "pk": 29573,
      "name": "Monaco ON",
      "isEjn": false
    },
    {
      "pk": 31711,
      "name": "Mosburger GmbH",
      "isEjn": false
    },
    {
      "pk": 29401,
      "name": "Mostschänke Turmkeller",
      "isEjn": false
    },
    {
      "pk": 17173,
      "name": "Move Eletromobilidade",
      "isEjn": false
    },
    {
      "pk": 40579,
      "name": "Mr. W.M. Kolffstraat",
      "isEjn": false
    },
    {
      "pk": 29744,
      "name": "msr bau",
      "isEjn": false
    },
    {
      "pk": 20273,
      "name": "MUC",
      "isEjn": false
    },
    {
      "pk": 12766,
      "name": "MUERITZ VITAL ",
      "isEjn": false
    },
    {
      "pk": 32448,
      "name": "Müller-Mühle",
      "isEjn": false
    },
    {
      "pk": 13760,
      "name": "MV Consult",
      "isEjn": false
    },
    {
      "pk": 39007,
      "name": "MVM Mobiliti Demo",
      "isEjn": false
    },
    {
      "pk": 20280,
      "name": "MVV",
      "isEjn": false
    },
    {
      "pk": 12809,
      "name": "MW Solutions",
      "isEjn": false
    },
    {
      "pk": 20288,
      "name": "MWE",
      "isEjn": false
    },
    {
      "pk": 16433,
      "name": "mwenergy",
      "isEjn": false
    },
    {
      "pk": 25060,
      "name": "MyCharge",
      "isEjn": false
    },
    {
      "pk": 28595,
      "name": "N1",
      "isEjn": false
    },
    {
      "pk": 18380,
      "name": "Nationales Automuseum",
      "isEjn": false
    },
    {
      "pk": 12849,
      "name": "Nature Point GmbH",
      "isEjn": false
    },
    {
      "pk": 29709,
      "name": "NaturEnergie",
      "isEjn": false
    },
    {
      "pk": 12738,
      "name": "Naturgy",
      "isEjn": false
    },
    {
      "pk": 36318,
      "name": "Naturhotel Schütterbad (Pfaffenbichler KG)",
      "isEjn": false
    },
    {
      "pk": 12848,
      "name": "Naturpoint GmbH",
      "isEjn": false
    },
    {
      "pk": 12754,
      "name": "NEF Grundstücksbesitz GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 3432,
      "name": "NEIS GmbH",
      "isEjn": false
    },
    {
      "pk": 15579,
      "name": "Neoenergia",
      "isEjn": false
    },
    {
      "pk": 39772,
      "name": "Neogy",
      "isEjn": false
    },
    {
      "pk": 39703,
      "name": "NES",
      "isEjn": false
    },
    {
      "pk": 5952,
      "name": "Neste Lataus",
      "isEjn": false
    },
    {
      "pk": 39704,
      "name": "Neste MY Renewable Charge",
      "isEjn": false
    },
    {
      "pk": 40085,
      "name": "Neste MY Uusiutuva Lataus",
      "isEjn": false
    },
    {
      "pk": 32431,
      "name": "NET",
      "isEjn": false
    },
    {
      "pk": 26576,
      "name": "NEUAMERIKA",
      "isEjn": false
    },
    {
      "pk": 32631,
      "name": "NeuAmerika GbR",
      "isEjn": false
    },
    {
      "pk": 16179,
      "name": "NEUE MITTE",
      "isEjn": false
    },
    {
      "pk": 38526,
      "name": "Neumann & Neumann Software und Beratungs GmbH",
      "isEjn": false
    },
    {
      "pk": 12747,
      "name": "Neustädter Hof",
      "isEjn": false
    },
    {
      "pk": 30234,
      "name": "New Motion",
      "isEjn": false
    },
    {
      "pk": 32520,
      "name": "Next Level Energy GmbH",
      "isEjn": false
    },
    {
      "pk": 30391,
      "name": "next step mobility GmbH",
      "isEjn": false
    },
    {
      "pk": 20282,
      "name": "nfzHaubner",
      "isEjn": false
    },
    {
      "pk": 41155,
      "name": "NHOA.TCC",
      "isEjn": false
    },
    {
      "pk": 706,
      "name": "NI Public Sector",
      "isEjn": false
    },
    {
      "pk": 24554,
      "name": "NK Parkaden",
      "isEjn": false
    },
    {
      "pk": 22219,
      "name": "NL MAB Laadoplossingen",
      "isEjn": false
    },
    {
      "pk": 3346,
      "name": "NL-AVI",
      "isEjn": false
    },
    {
      "pk": 20274,
      "name": "NMS",
      "isEjn": false
    },
    {
      "pk": 34343,
      "name": "Nofar Energy",
      "isEjn": false
    },
    {
      "pk": 21,
      "name": "Non-networked",
      "isEjn": false
    },
    {
      "pk": 2078,
      "name": "Noodoe EV",
      "isEjn": false
    },
    {
      "pk": 36030,
      "name": "Norbert Katsch Vermietung",
      "isEjn": false
    },
    {
      "pk": 23122,
      "name": "Nordmarkens Bil",
      "isEjn": false
    },
    {
      "pk": 40358,
      "name": "Nordmarkens Motor",
      "isEjn": false
    },
    {
      "pk": 14131,
      "name": "NRG Mobility",
      "isEjn": false
    },
    {
      "pk": 2271,
      "name": "NRMA",
      "isEjn": false
    },
    {
      "pk": 29259,
      "name": "NTG Neue Technologien GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 39500,
      "name": "Numbat",
      "isEjn": false
    },
    {
      "pk": 452,
      "name": "nvb Nordhorner Versorgungsbetriebe GmbH",
      "isEjn": false
    },
    {
      "pk": 36126,
      "name": "NVZ GmbH",
      "isEjn": false
    },
    {
      "pk": 36025,
      "name": "O.C.R.A GmbH",
      "isEjn": false
    },
    {
      "pk": 24214,
      "name": "OBE Power",
      "isEjn": false
    },
    {
      "pk": 18987,
      "name": "OBE Power Networks",
      "isEjn": false
    },
    {
      "pk": 32619,
      "name": "Obererlacher KG",
      "isEjn": false
    },
    {
      "pk": 13390,
      "name": "Objektgesellschaft An der Pönt 44 GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 20307,
      "name": "Ochsenkühn Baumaschinen GmbH",
      "isEjn": false
    },
    {
      "pk": 32455,
      "name": "Ockelbo kommun",
      "isEjn": false
    },
    {
      "pk": 27832,
      "name": "OER",
      "isEjn": false
    },
    {
      "pk": 2235,
      "name": "OG Clean Fuels",
      "isEjn": false
    },
    {
      "pk": 16180,
      "name": "Oharek GmbH",
      "isEjn": false
    },
    {
      "pk": 32417,
      "name": "Ohra Energie",
      "isEjn": false
    },
    {
      "pk": 20247,
      "name": "OK2Charge",
      "isEjn": false
    },
    {
      "pk": 16813,
      "name": "Olaf Wegermann",
      "isEjn": false
    },
    {
      "pk": 30383,
      "name": "Oliver Göcke",
      "isEjn": false
    },
    {
      "pk": 38745,
      "name": "Olycharge",
      "isEjn": false
    },
    {
      "pk": 32460,
      "name": "Olycharge ",
      "isEjn": false
    },
    {
      "pk": 39112,
      "name": "Olympia charging station",
      "isEjn": false
    },
    {
      "pk": 38149,
      "name": "Olympic Hotel - Parking for Guests",
      "isEjn": false
    },
    {
      "pk": 13443,
      "name": "Omega Projektentwicklungs GmbH",
      "isEjn": false
    },
    {
      "pk": 22296,
      "name": "Omniport GmbH",
      "isEjn": false
    },
    {
      "pk": 35462,
      "name": "OMV Slovensko, s.r.o.",
      "isEjn": false
    },
    {
      "pk": 650,
      "name": "ON Power",
      "isEjn": false
    },
    {
      "pk": 32518,
      "name": "ON-ION",
      "isEjn": false
    },
    {
      "pk": 15598,
      "name": "on|ion",
      "isEjn": false
    },
    {
      "pk": 15607,
      "name": "Oncharge",
      "isEjn": false
    },
    {
      "pk": 32524,
      "name": "One9",
      "isEjn": false
    },
    {
      "pk": 38260,
      "name": "OneCharge",
      "isEjn": false
    },
    {
      "pk": 23375,
      "name": "Oodles Energy",
      "isEjn": false
    },
    {
      "pk": 20302,
      "name": "OPE",
      "isEjn": false
    },
    {
      "pk": 5969,
      "name": "OpenLoop",
      "isEjn": false
    },
    {
      "pk": 33055,
      "name": "Operator",
      "isEjn": false
    },
    {
      "pk": 35872,
      "name": "Orion eMobility",
      "isEjn": false
    },
    {
      "pk": 29641,
      "name": "Orkan",
      "isEjn": false
    },
    {
      "pk": 14619,
      "name": "Orkubú Vestfjarða",
      "isEjn": false
    },
    {
      "pk": 14474,
      "name": "ORLEN",
      "isEjn": false
    },
    {
      "pk": 20254,
      "name": "Ortsgemeinde Mörsfeld",
      "isEjn": false
    },
    {
      "pk": 136,
      "name": "Oslo kommune",
      "isEjn": false
    },
    {
      "pk": 296,
      "name": "Osnabrücker Parkstätten- Betriebsgesellschaft mbH (OPG)",
      "isEjn": false
    },
    {
      "pk": 4062,
      "name": "ostatní",
      "isEjn": false
    },
    {
      "pk": 25058,
      "name": "Otojet",
      "isEjn": false
    },
    {
      "pk": 25059,
      "name": "Otowatt",
      "isEjn": false
    },
    {
      "pk": 16003,
      "name": "Overkamp Gastronomie ",
      "isEjn": false
    },
    {
      "pk": 38941,
      "name": "OVMedia 11kw",
      "isEjn": false
    },
    {
      "pk": 20231,
      "name": "OZE",
      "isEjn": false
    },
    {
      "pk": 29438,
      "name": "P. van der Velden",
      "isEjn": false
    },
    {
      "pk": 32531,
      "name": "P&R Verwaltungs GmbH",
      "isEjn": false
    },
    {
      "pk": 18994,
      "name": "Pädagogisch Soziales Zentrum Dortmund e.V.",
      "isEjn": false
    },
    {
      "pk": 15390,
      "name": "PaderSprinter GmbH",
      "isEjn": false
    },
    {
      "pk": 13048,
      "name": "Palangos komunalinis ūkis",
      "isEjn": false
    },
    {
      "pk": 36317,
      "name": "Palfner Tankstelle GesmbH",
      "isEjn": false
    },
    {
      "pk": 20432,
      "name": "PAR",
      "isEjn": false
    },
    {
      "pk": 36051,
      "name": "Paracelsus Medizinische Privatuniversität Salzburg",
      "isEjn": false
    },
    {
      "pk": 37993,
      "name": "Park d'heule",
      "isEjn": false
    },
    {
      "pk": 38737,
      "name": "Park It Smart",
      "isEjn": false
    },
    {
      "pk": 38747,
      "name": "Parkering Malmö",
      "isEjn": false
    },
    {
      "pk": 12813,
      "name": "Parkgarage Altstadt Bad Neustadt GmbH & Co KG",
      "isEjn": false
    },
    {
      "pk": 15352,
      "name": "Parkhotel",
      "isEjn": false
    },
    {
      "pk": 31571,
      "name": "Parkhotel Braunau",
      "isEjn": false
    },
    {
      "pk": 32675,
      "name": "Parkhotel Braunau GmbH",
      "isEjn": false
    },
    {
      "pk": 40612,
      "name": "ParkingYou",
      "isEjn": false
    },
    {
      "pk": 32425,
      "name": "Parkly",
      "isEjn": false
    },
    {
      "pk": 30191,
      "name": "Parkster",
      "isEjn": false
    },
    {
      "pk": 40508,
      "name": "Partino EUR",
      "isEjn": false
    },
    {
      "pk": 12755,
      "name": "Paul Armbruster",
      "isEjn": false
    },
    {
      "pk": 16994,
      "name": "Paul Schulten + Sohn GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 32622,
      "name": "Paulus Besitz GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 30186,
      "name": "Pay N Charge Nordic",
      "isEjn": false
    },
    {
      "pk": 41235,
      "name": "PC-Tank",
      "isEjn": false
    },
    {
      "pk": 12966,
      "name": "PC-Tank s.à.r.l.",
      "isEjn": false
    },
    {
      "pk": 21892,
      "name": "PCT",
      "isEjn": false
    },
    {
      "pk": 15499,
      "name": "PEA VOLTA",
      "isEjn": false
    },
    {
      "pk": 16673,
      "name": "PEM-energy",
      "isEjn": false
    },
    {
      "pk": 36041,
      "name": "Pension Falkenstein, Fa. Strebl KG",
      "isEjn": false
    },
    {
      "pk": 36020,
      "name": "Pension Gabi",
      "isEjn": false
    },
    {
      "pk": 17925,
      "name": "Pension Haus am Meer Lange & Nippkow OHG",
      "isEjn": false
    },
    {
      "pk": 36100,
      "name": "Pension Maurachhof",
      "isEjn": false
    },
    {
      "pk": 24739,
      "name": "Pension Rosenhügel",
      "isEjn": false
    },
    {
      "pk": 14238,
      "name": "Pension Wiesfleck",
      "isEjn": false
    },
    {
      "pk": 36128,
      "name": "Peter Siegmann",
      "isEjn": false
    },
    {
      "pk": 36295,
      "name": "Peter Vitzthum Hotel GmbH",
      "isEjn": false
    },
    {
      "pk": 13040,
      "name": "Petra Engel",
      "isEjn": false
    },
    {
      "pk": 24560,
      "name": "Petrom Electric",
      "isEjn": false
    },
    {
      "pk": 22609,
      "name": "Petrovaz",
      "isEjn": false
    },
    {
      "pk": 15580,
      "name": "Peugeot",
      "isEjn": false
    },
    {
      "pk": 30377,
      "name": "Pflegedienst Müller",
      "isEjn": false
    },
    {
      "pk": 36805,
      "name": "Pflugsmühle Braun",
      "isEjn": false
    },
    {
      "pk": 29087,
      "name": "PG Perner H u. S",
      "isEjn": false
    },
    {
      "pk": 19353,
      "name": "Philipp Schramm",
      "isEjn": false
    },
    {
      "pk": 32459,
      "name": "Phoenix Compounding Technology GmbH",
      "isEjn": false
    },
    {
      "pk": 36326,
      "name": "Physiotherapie Holleis",
      "isEjn": false
    },
    {
      "pk": 41162,
      "name": "Pick N Charge",
      "isEjn": false
    },
    {
      "pk": 36337,
      "name": "Pinzgau Milch Produktions GmbH",
      "isEjn": false
    },
    {
      "pk": 36286,
      "name": "Pizzeria Simic GmbH",
      "isEjn": false
    },
    {
      "pk": 1635,
      "name": "PKN Orlen S.A",
      "isEjn": false
    },
    {
      "pk": 10138,
      "name": "PKP S.A.",
      "isEjn": false
    },
    {
      "pk": 34055,
      "name": "Plaid Pantry Electric Era EV Fast",
      "isEjn": false
    },
    {
      "pk": 32601,
      "name": "Planarx GmbH",
      "isEjn": false
    },
    {
      "pk": 12751,
      "name": "Platen duurzame technieken",
      "isEjn": false
    },
    {
      "pk": 22297,
      "name": "Platzhirsch Mayrhofen",
      "isEjn": false
    },
    {
      "pk": 25131,
      "name": "Pleco",
      "isEjn": false
    },
    {
      "pk": 34694,
      "name": "Plug & Charge",
      "isEjn": false
    },
    {
      "pk": 38299,
      "name": "Plug surfing",
      "isEjn": false
    },
    {
      "pk": 15471,
      "name": "Plug.am",
      "isEjn": false
    },
    {
      "pk": 34555,
      "name": "Plux EV",
      "isEjn": false
    },
    {
      "pk": 32577,
      "name": "PMC Personal Mobility Center Nordwest eG",
      "isEjn": false
    },
    {
      "pk": 26571,
      "name": "PMCNORDWESTEG",
      "isEjn": false
    },
    {
      "pk": 663,
      "name": "Pod Point",
      "isEjn": false
    },
    {
      "pk": 40982,
      "name": "PoGo",
      "isEjn": false
    },
    {
      "pk": 23934,
      "name": "Polara",
      "isEjn": false
    },
    {
      "pk": 13386,
      "name": "Polenergia eMobility sp. z o.o",
      "isEjn": false
    },
    {
      "pk": 38743,
      "name": "Porsgrunn Kommune",
      "isEjn": false
    },
    {
      "pk": 32562,
      "name": "PORTALEUM GmbH",
      "isEjn": false
    },
    {
      "pk": 25145,
      "name": "Posthotel Achenkirch",
      "isEjn": false
    },
    {
      "pk": 12807,
      "name": "Posthotel Hofherr",
      "isEjn": false
    },
    {
      "pk": 14760,
      "name": "Poudrerie SA",
      "isEjn": false
    },
    {
      "pk": 37945,
      "name": "power envision",
      "isEjn": false
    },
    {
      "pk": 21754,
      "name": "Power2Go",
      "isEjn": false
    },
    {
      "pk": 16006,
      "name": "PowerFlex",
      "isEjn": false
    },
    {
      "pk": 38780,
      "name": "PowerPoint City Car",
      "isEjn": false
    },
    {
      "pk": 38749,
      "name": "Präg Strom & Gas",
      "isEjn": false
    },
    {
      "pk": 31261,
      "name": "Preem",
      "isEjn": false
    },
    {
      "pk": 36033,
      "name": "Proceed GmbH",
      "isEjn": false
    },
    {
      "pk": 36327,
      "name": "Prodinger & Partner WT Steuerberatungs GmbH & Co KG",
      "isEjn": false
    },
    {
      "pk": 18524,
      "name": "Provincie Gelderland",
      "isEjn": false
    },
    {
      "pk": 32628,
      "name": "PS Bioenergie GmbH & Co.KG",
      "isEjn": false
    },
    {
      "pk": 26572,
      "name": "PSBIOENERGIE_FOLLOWER",
      "isEjn": false
    },
    {
      "pk": 20292,
      "name": "PSG",
      "isEjn": false
    },
    {
      "pk": 15501,
      "name": "PTT",
      "isEjn": false
    },
    {
      "pk": 15506,
      "name": "Pulse Charge",
      "isEjn": false
    },
    {
      "pk": 15599,
      "name": "Pumpcharge",
      "isEjn": false
    },
    {
      "pk": 37998,
      "name": "Pupaplug",
      "isEjn": false
    },
    {
      "pk": 13438,
      "name": "PureCity sp. z o.o",
      "isEjn": false
    },
    {
      "pk": 20264,
      "name": "PWY",
      "isEjn": false
    },
    {
      "pk": 38261,
      "name": "Q Charge",
      "isEjn": false
    },
    {
      "pk": 32442,
      "name": "Q8 electric",
      "isEjn": false
    },
    {
      "pk": 34056,
      "name": "Quik Charge Electric Era EV Fast",
      "isEjn": false
    },
    {
      "pk": 14478,
      "name": "R&R Hotels und Villen GbR",
      "isEjn": false
    },
    {
      "pk": 23374,
      "name": "RaceTrac",
      "isEjn": false
    },
    {
      "pk": 40496,
      "name": "Radboud Universiteit",
      "isEjn": false
    },
    {
      "pk": 39147,
      "name": "RAIFFEISEN_WAREN_FOLLOWER",
      "isEjn": false
    },
    {
      "pk": 16819,
      "name": "Raiffeisen-Volksbank Ebersberg eG",
      "isEjn": false
    },
    {
      "pk": 13267,
      "name": "Raiffeisen-Volksbank Varel-Nordenham eG",
      "isEjn": false
    },
    {
      "pk": 1754,
      "name": "Raiffeisenbank Bad Saulgau eG",
      "isEjn": false
    },
    {
      "pk": 36394,
      "name": "Raiffeisenbank Fuschlsee West eGen",
      "isEjn": false
    },
    {
      "pk": 37120,
      "name": "Raiffeisenbank Lungau eGen - Mariapfarr",
      "isEjn": false
    },
    {
      "pk": 37082,
      "name": "Raiffeisenbank Lungau eGen - Pfarrwerfen",
      "isEjn": false
    },
    {
      "pk": 14128,
      "name": "Raiffeisenbank Mureck",
      "isEjn": false
    },
    {
      "pk": 2502,
      "name": "Raiffeisenbank Neumarkt-St. Veit - Reischach eG",
      "isEjn": false
    },
    {
      "pk": 36335,
      "name": "Raiffeisenverband Salzburg eGen",
      "isEjn": false
    },
    {
      "pk": 12792,
      "name": "Ralf Kockel",
      "isEjn": false
    },
    {
      "pk": 34699,
      "name": "Ran? Ramarin",
      "isEjn": false
    },
    {
      "pk": 38806,
      "name": "RATAGS Kunsthandwerk GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 32656,
      "name": "RATAGS Kunsthandwerk GmbH & Co. KG ",
      "isEjn": false
    },
    {
      "pk": 32636,
      "name": "RCH",
      "isEjn": false
    },
    {
      "pk": 13437,
      "name": "Re-Energia",
      "isEjn": false
    },
    {
      "pk": 28145,
      "name": "REA",
      "isEjn": false
    },
    {
      "pk": 17002,
      "name": "REA eCharge GmbH",
      "isEjn": false
    },
    {
      "pk": 32515,
      "name": "REC",
      "isEjn": false
    },
    {
      "pk": 13626,
      "name": "Red E Charge",
      "isEjn": false
    },
    {
      "pk": 13395,
      "name": "Reetland Rugana Betriebsgesellschaft mbH",
      "isEjn": false
    },
    {
      "pk": 37472,
      "name": "reev",
      "isEjn": false
    },
    {
      "pk": 18382,
      "name": "Regio Charge GmbH ",
      "isEjn": false
    },
    {
      "pk": 31142,
      "name": "Region Hauts de France",
      "isEjn": false
    },
    {
      "pk": 30384,
      "name": "ReifenGlatt GmbH",
      "isEjn": false
    },
    {
      "pk": 36095,
      "name": "Reinhard Künstler",
      "isEjn": false
    },
    {
      "pk": 36115,
      "name": "Reinhart Lange",
      "isEjn": false
    },
    {
      "pk": 33122,
      "name": "REISENTHEL",
      "isEjn": false
    },
    {
      "pk": 36367,
      "name": "Reitlehenalm GmbH",
      "isEjn": false
    },
    {
      "pk": 24773,
      "name": "Reload Solution",
      "isEjn": false
    },
    {
      "pk": 37,
      "name": "Renault",
      "isEjn": false
    },
    {
      "pk": 26866,
      "name": "Rene Heiserer",
      "isEjn": false
    },
    {
      "pk": 19879,
      "name": "RER",
      "isEjn": false
    },
    {
      "pk": 32544,
      "name": "Restaurant Seespitz",
      "isEjn": false
    },
    {
      "pk": 18362,
      "name": "Revel",
      "isEjn": false
    },
    {
      "pk": 30396,
      "name": "Reven Rues",
      "isEjn": false
    },
    {
      "pk": 28877,
      "name": "Revol",
      "isEjn": false
    },
    {
      "pk": 36569,
      "name": "Revolt B.V.",
      "isEjn": false
    },
    {
      "pk": 318,
      "name": "rhenag Rheinische Energie AG",
      "isEjn": false
    },
    {
      "pk": 16809,
      "name": "Rhenus Port Logistics Rhein Neckar GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 25061,
      "name": "RHG Enertürk",
      "isEjn": false
    },
    {
      "pk": 39566,
      "name": "RiA ",
      "isEjn": false
    },
    {
      "pk": 34277,
      "name": "Ricarica srl",
      "isEjn": false
    },
    {
      "pk": 36017,
      "name": "Richard Brunnauer",
      "isEjn": false
    },
    {
      "pk": 23118,
      "name": "Ringhotel Siegfriedbrunnen",
      "isEjn": false
    },
    {
      "pk": 32561,
      "name": "Rirodo1",
      "isEjn": false
    },
    {
      "pk": 36402,
      "name": "Rivus GmbH - Thomas Nadler",
      "isEjn": false
    },
    {
      "pk": 12773,
      "name": "RJ Enterprise",
      "isEjn": false
    },
    {
      "pk": 12841,
      "name": "RL Energies",
      "isEjn": false
    },
    {
      "pk": 36377,
      "name": "Robert Halasz",
      "isEjn": false
    },
    {
      "pk": 41450,
      "name": "Robert Schäfer & Söhne KG",
      "isEjn": false
    },
    {
      "pk": 14022,
      "name": "Röchling Industrial Allgäu GmbH",
      "isEjn": false
    },
    {
      "pk": 13975,
      "name": "Romantik- & Wellnesshotel Deimann",
      "isEjn": false
    },
    {
      "pk": 34243,
      "name": "Römerbad Klinik GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 32456,
      "name": "Romme Alpin",
      "isEjn": false
    },
    {
      "pk": 25097,
      "name": "Rönesans Charge-R",
      "isEjn": false
    },
    {
      "pk": 38529,
      "name": "Roodewoud",
      "isEjn": false
    },
    {
      "pk": 17455,
      "name": "Rosserer Refugium",
      "isEjn": false
    },
    {
      "pk": 15461,
      "name": "Rosseti Lenenergo",
      "isEjn": false
    },
    {
      "pk": 20318,
      "name": "Rost & Rost",
      "isEjn": false
    },
    {
      "pk": 13983,
      "name": "Rotkogelhütte Gstrein Fender GmbH",
      "isEjn": false
    },
    {
      "pk": 16928,
      "name": "RP Automobile",
      "isEjn": false
    },
    {
      "pk": 41273,
      "name": "RTW52",
      "isEjn": false
    },
    {
      "pk": 37291,
      "name": "Rubicon E-Mobility",
      "isEjn": false
    },
    {
      "pk": 18522,
      "name": "Rubin Mühle GmbH",
      "isEjn": false
    },
    {
      "pk": 18523,
      "name": "Rubinmühle Vogtland GmbH",
      "isEjn": false
    },
    {
      "pk": 36102,
      "name": "Rüdiger Knevels",
      "isEjn": false
    },
    {
      "pk": 32607,
      "name": "Rügen Ferienhof",
      "isEjn": false
    },
    {
      "pk": 36034,
      "name": "Ruhe & Frieden GmbH",
      "isEjn": false
    },
    {
      "pk": 15481,
      "name": "RusHydro",
      "isEjn": false
    },
    {
      "pk": 12803,
      "name": "S.air",
      "isEjn": false
    },
    {
      "pk": 13758,
      "name": "S.air NV",
      "isEjn": false
    },
    {
      "pk": 36422,
      "name": "Saalbacher Hof GmbH & Co KG",
      "isEjn": false
    },
    {
      "pk": 36302,
      "name": "Saalbacher Hotel Betriebs GmbH",
      "isEjn": false
    },
    {
      "pk": 36276,
      "name": "Saalfeldner Bauträger GmbH",
      "isEjn": false
    },
    {
      "pk": 15464,
      "name": "SachsenEnergie",
      "isEjn": false
    },
    {
      "pk": 36605,
      "name": "SACHSENENERGIEAG",
      "isEjn": false
    },
    {
      "pk": 31713,
      "name": "Sächsische Staatsbäder GmbH",
      "isEjn": false
    },
    {
      "pk": 15583,
      "name": "Saesa",
      "isEjn": false
    },
    {
      "pk": 15856,
      "name": "SAF Tepasse",
      "isEjn": false
    },
    {
      "pk": 38005,
      "name": "Saffron housing",
      "isEjn": false
    },
    {
      "pk": 23117,
      "name": "Sainsbury's Smart Charge",
      "isEjn": false
    },
    {
      "pk": 13052,
      "name": "Šakių rajono savivaldybės administracija",
      "isEjn": false
    },
    {
      "pk": 13044,
      "name": "Šalčininkų rajono savivaldybės administracija",
      "isEjn": false
    },
    {
      "pk": 1317,
      "name": "Saltoki",
      "isEjn": false
    },
    {
      "pk": 38337,
      "name": "Salzburg Linien Verkehrsbetriebe GmbH",
      "isEjn": false
    },
    {
      "pk": 36048,
      "name": "Salzburgring IGM",
      "isEjn": false
    },
    {
      "pk": 13665,
      "name": "Samtgemeinde Bruchhausen-Vilsen",
      "isEjn": false
    },
    {
      "pk": 13980,
      "name": "Sander Dumoulin Holding BV",
      "isEjn": false
    },
    {
      "pk": 38739,
      "name": "Sandnes Parkering KF",
      "isEjn": false
    },
    {
      "pk": 25146,
      "name": "Sarjon",
      "isEjn": false
    },
    {
      "pk": 23859,
      "name": "Särna Laddstation",
      "isEjn": false
    },
    {
      "pk": 22134,
      "name": "SARTHE IRVE",
      "isEjn": false
    },
    {
      "pk": 32673,
      "name": "Sasto Objekt",
      "isEjn": false
    },
    {
      "pk": 38524,
      "name": "SATCO_FOLLOWER",
      "isEjn": false
    },
    {
      "pk": 12811,
      "name": "Sauerländischer Gebirgsverein e. V - SGV Jugendhof",
      "isEjn": false
    },
    {
      "pk": 16251,
      "name": "Save Mart",
      "isEjn": false
    },
    {
      "pk": 32517,
      "name": "SAX",
      "isEjn": false
    },
    {
      "pk": 28147,
      "name": "SB Zentralmarkt",
      "isEjn": false
    },
    {
      "pk": 31640,
      "name": "SBH",
      "isEjn": false
    },
    {
      "pk": 36043,
      "name": "SBI-Schober Bauer Immobilien GmbH",
      "isEjn": false
    },
    {
      "pk": 15587,
      "name": "Scame",
      "isEjn": false
    },
    {
      "pk": 31135,
      "name": "Scandic Hotels",
      "isEjn": false
    },
    {
      "pk": 22881,
      "name": "Scania Sverige AB",
      "isEjn": false
    },
    {
      "pk": 18366,
      "name": "Schäfer Steuerungstechnik ",
      "isEjn": false
    },
    {
      "pk": 36054,
      "name": "Schaidberggut GmbH",
      "isEjn": false
    },
    {
      "pk": 36269,
      "name": "Schal73 GmbH",
      "isEjn": false
    },
    {
      "pk": 36028,
      "name": "Scharnagl GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 31499,
      "name": "Scheidt & Bachmann Energy Retail Solutions GmbH",
      "isEjn": false
    },
    {
      "pk": 25141,
      "name": "Scheidt & Bachmann GmbH",
      "isEjn": false
    },
    {
      "pk": 32514,
      "name": "Scherenberg e-Mobility e.K.",
      "isEjn": false
    },
    {
      "pk": 36417,
      "name": "Schiega Gastronomie GmbH",
      "isEjn": false
    },
    {
      "pk": 32584,
      "name": "Schießzentrum Innviertel",
      "isEjn": false
    },
    {
      "pk": 37988,
      "name": "Schildersgilde Friesland",
      "isEjn": false
    },
    {
      "pk": 22291,
      "name": "Schiliftzentrum Gerlos",
      "isEjn": false
    },
    {
      "pk": 19200,
      "name": "Schippers Clean Energy",
      "isEjn": false
    },
    {
      "pk": 31847,
      "name": "Schloss Kapfenstein",
      "isEjn": false
    },
    {
      "pk": 18385,
      "name": "Schmees Service",
      "isEjn": false
    },
    {
      "pk": 36356,
      "name": "Schmittenhöhenbahn AG",
      "isEjn": false
    },
    {
      "pk": 26578,
      "name": "SCHMUECKER_HOF",
      "isEjn": false
    },
    {
      "pk": 36053,
      "name": "Schneehaus GmbH",
      "isEjn": false
    },
    {
      "pk": 34052,
      "name": "Schneider Electric",
      "isEjn": false
    },
    {
      "pk": 36372,
      "name": "Schoellerbank AG",
      "isEjn": false
    },
    {
      "pk": 18995,
      "name": "Schöffel Sportbekleidung",
      "isEjn": false
    },
    {
      "pk": 36571,
      "name": "Schrack Technik Energie",
      "isEjn": false
    },
    {
      "pk": 26579,
      "name": "SCHREINERHOF",
      "isEjn": false
    },
    {
      "pk": 32664,
      "name": "Schreinerhof Betriebs GmbH",
      "isEjn": false
    },
    {
      "pk": 14764,
      "name": "Schroll ",
      "isEjn": false
    },
    {
      "pk": 12756,
      "name": "Schuerholz GmbH & Co. KG Stanztechnik",
      "isEjn": false
    },
    {
      "pk": 23304,
      "name": "Schultheis Power Solutions GmbH ",
      "isEjn": false
    },
    {
      "pk": 20267,
      "name": "Schulungs- und Ausbildungszentrum Meura UG",
      "isEjn": false
    },
    {
      "pk": 23969,
      "name": "Schulze Niehues Warendorf",
      "isEjn": false
    },
    {
      "pk": 32676,
      "name": "Schuster & Walther GmbH & Co. KG Loh Beteiligungs UG & Co. KG",
      "isEjn": false
    },
    {
      "pk": 31136,
      "name": "Schwarz",
      "isEjn": false
    },
    {
      "pk": 21487,
      "name": "Schwarz Immobilien Service GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 36280,
      "name": "Science City Salzburg GmbH",
      "isEjn": false
    },
    {
      "pk": 15997,
      "name": "SDB",
      "isEjn": false
    },
    {
      "pk": 14797,
      "name": "SDEG16",
      "isEjn": false
    },
    {
      "pk": 40497,
      "name": "SE2",
      "isEjn": false
    },
    {
      "pk": 41168,
      "name": "SEAC_PROJEKT_GMBH_FOLLOWER",
      "isEjn": false
    },
    {
      "pk": 38801,
      "name": "Seat Pilser",
      "isEjn": false
    },
    {
      "pk": 32632,
      "name": "Seat Pilser ",
      "isEjn": false
    },
    {
      "pk": 30397,
      "name": "Sebastian Gitterle Raumausstattung GmbH",
      "isEjn": false
    },
    {
      "pk": 15104,
      "name": "Sebastian Rickenbrock",
      "isEjn": false
    },
    {
      "pk": 20677,
      "name": "SEE",
      "isEjn": false
    },
    {
      "pk": 36103,
      "name": "Seehotel Billroth GmbH",
      "isEjn": false
    },
    {
      "pk": 12970,
      "name": "Seestüberl",
      "isEjn": false
    },
    {
      "pk": 13581,
      "name": "SEG",
      "isEjn": false
    },
    {
      "pk": 31190,
      "name": "SEHV",
      "isEjn": false
    },
    {
      "pk": 14937,
      "name": "Seipp Wohnen GmbH",
      "isEjn": false
    },
    {
      "pk": 18372,
      "name": "Sektion Allgäu-Kempten des Deutschen Alpenvereins e. V. ",
      "isEjn": false
    },
    {
      "pk": 1838,
      "name": "SemaConnect",
      "isEjn": false
    },
    {
      "pk": 32610,
      "name": "Servus Europa Suben",
      "isEjn": false
    },
    {
      "pk": 34139,
      "name": "Seven Feathers Electric Era EV Fast",
      "isEjn": false
    },
    {
      "pk": 29226,
      "name": "SFP SmartFuelPass GmbH",
      "isEjn": false
    },
    {
      "pk": 38111,
      "name": "Shabik",
      "isEjn": false
    },
    {
      "pk": 27904,
      "name": "Share Birrer AG",
      "isEjn": false
    },
    {
      "pk": 15600,
      "name": "SHARGE",
      "isEjn": false
    },
    {
      "pk": 15487,
      "name": "Sharz",
      "isEjn": false
    },
    {
      "pk": 36308,
      "name": "Shuttleberg GmbH & Co KG",
      "isEjn": false
    },
    {
      "pk": 20679,
      "name": "Siegener Versorgungsbetriebe",
      "isEjn": false
    },
    {
      "pk": 22574,
      "name": "Siemens",
      "isEjn": false
    },
    {
      "pk": 16181,
      "name": "Silendi",
      "isEjn": false
    },
    {
      "pk": 14058,
      "name": "Silvia & Markus Gronemeyer",
      "isEjn": false
    },
    {
      "pk": 32611,
      "name": "Sima Apartments",
      "isEjn": false
    },
    {
      "pk": 36098,
      "name": "Simmodo GmbH",
      "isEjn": false
    },
    {
      "pk": 12906,
      "name": "Simon Fuchs",
      "isEjn": false
    },
    {
      "pk": 16000,
      "name": "Simple Charge",
      "isEjn": false
    },
    {
      "pk": 22298,
      "name": "SimpleCharge24 KG",
      "isEjn": false
    },
    {
      "pk": 38781,
      "name": "Sinergas Impianti",
      "isEjn": false
    },
    {
      "pk": 31143,
      "name": "Sirius Facilities GmbH",
      "isEjn": false
    },
    {
      "pk": 3731,
      "name": "Sirti S.p.A.",
      "isEjn": false
    },
    {
      "pk": 37083,
      "name": "Siskiyou Electric Era EV Fast",
      "isEjn": false
    },
    {
      "pk": 32454,
      "name": "Skara Energi",
      "isEjn": false
    },
    {
      "pk": 37948,
      "name": "Skycharger Electric Era EV Fast",
      "isEjn": false
    },
    {
      "pk": 24668,
      "name": "SLB",
      "isEjn": false
    },
    {
      "pk": 12806,
      "name": "SLV",
      "isEjn": false
    },
    {
      "pk": 26260,
      "name": "Smart Fuel Pass s.r.o.",
      "isEjn": false
    },
    {
      "pk": 21310,
      "name": "Smedjebacken Energi",
      "isEjn": false
    },
    {
      "pk": 12763,
      "name": "Smokey Goodness",
      "isEjn": false
    },
    {
      "pk": 36278,
      "name": "Snow Space Salzburg Bergbahnen AG",
      "isEjn": false
    },
    {
      "pk": 32504,
      "name": "SOCAR Charge",
      "isEjn": false
    },
    {
      "pk": 14653,
      "name": "SoCharge",
      "isEjn": false
    },
    {
      "pk": 36090,
      "name": "Sochor Touristik OG",
      "isEjn": false
    },
    {
      "pk": 15479,
      "name": "Soh100",
      "isEjn": false
    },
    {
      "pk": 14652,
      "name": "Soladria e ricarica",
      "isEjn": false
    },
    {
      "pk": 668,
      "name": "Solar Solutions",
      "isEjn": false
    },
    {
      "pk": 32630,
      "name": "SolarHub",
      "isEjn": false
    },
    {
      "pk": 35873,
      "name": "SolarLoader+",
      "isEjn": false
    },
    {
      "pk": 37402,
      "name": "SolarLoader+ GmbH",
      "isEjn": false
    },
    {
      "pk": 15482,
      "name": "Sollus",
      "isEjn": false
    },
    {
      "pk": 18162,
      "name": "Solo",
      "isEjn": false
    },
    {
      "pk": 34310,
      "name": "Sonnengenussmanufaktur Kollitsch KG",
      "isEjn": false
    },
    {
      "pk": 36063,
      "name": "Sonnenhof Beterber GmbH",
      "isEjn": false
    },
    {
      "pk": 23749,
      "name": "Sonnenladen GmbH",
      "isEjn": false
    },
    {
      "pk": 36284,
      "name": "Sonnhof Weber GmbH & Co.KG",
      "isEjn": false
    },
    {
      "pk": 15463,
      "name": "Sonol - EVI",
      "isEjn": false
    },
    {
      "pk": 16002,
      "name": "Sonova Deutschland GmbH",
      "isEjn": false
    },
    {
      "pk": 15492,
      "name": "SP Mobility",
      "isEjn": false
    },
    {
      "pk": 27796,
      "name": "Spaans",
      "isEjn": false
    },
    {
      "pk": 34138,
      "name": "Space Age Electric Era EV Fast",
      "isEjn": false
    },
    {
      "pk": 32564,
      "name": "Spark Charging Solutions GmbH",
      "isEjn": false
    },
    {
      "pk": 13583,
      "name": "Sparkase Mittelfranken Süd ",
      "isEjn": false
    },
    {
      "pk": 32620,
      "name": "Sparkasse im Landkreis Schwandorf",
      "isEjn": false
    },
    {
      "pk": 18669,
      "name": "Sparkasse Pforzheim Calw",
      "isEjn": false
    },
    {
      "pk": 1488,
      "name": "Sperto",
      "isEjn": false
    },
    {
      "pk": 19211,
      "name": "spies Grundstücks GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 37991,
      "name": "Spooky Charge 2",
      "isEjn": false
    },
    {
      "pk": 36297,
      "name": "Sport Huber GmbH & Co KG",
      "isEjn": false
    },
    {
      "pk": 36294,
      "name": "Sporthotel Gut Brandlhof GmbH & Co OG",
      "isEjn": false
    },
    {
      "pk": 22290,
      "name": "Sporthotel Zugspitze",
      "isEjn": false
    },
    {
      "pk": 40505,
      "name": "Spreewald Energy GmbH",
      "isEjn": false
    },
    {
      "pk": 38476,
      "name": "SR'AUTO - TotalEnergies",
      "isEjn": false
    },
    {
      "pk": 20473,
      "name": "SRA",
      "isEjn": false
    },
    {
      "pk": 32551,
      "name": "St. Josef-Stift Sendenhorst",
      "isEjn": false
    },
    {
      "pk": 23444,
      "name": "St. Virgil Salzburg",
      "isEjn": false
    },
    {
      "pk": 27865,
      "name": "St1",
      "isEjn": false
    },
    {
      "pk": 17350,
      "name": "Stadion Center Einkaufszentrumserrichtungs GmbH & Co KG",
      "isEjn": false
    },
    {
      "pk": 30386,
      "name": "Stadt Burglengenfeld",
      "isEjn": false
    },
    {
      "pk": 12744,
      "name": "Stadt Ludwigsburg",
      "isEjn": false
    },
    {
      "pk": 39152,
      "name": "STADT_SCHLOSS_HS_FOLLOWER",
      "isEjn": false
    },
    {
      "pk": 14755,
      "name": "Stadt- und Überlandwerke GmbH Luckau-Lübbenau",
      "isEjn": false
    },
    {
      "pk": 32635,
      "name": "Stadtgemeinde Dürnstein Dürnstein 25 A-3601 Dürnstein office@duernstein.gv.at",
      "isEjn": false
    },
    {
      "pk": 18783,
      "name": "Stadtgemeinde DürnsteinDürnstein 25A-3601 Dürnsteinoffice@duernstein.gv.at",
      "isEjn": false
    },
    {
      "pk": 36373,
      "name": "Stadtgemeinde Neumarkt",
      "isEjn": false
    },
    {
      "pk": 36293,
      "name": "Stadtgemeinde St. Johann",
      "isEjn": false
    },
    {
      "pk": 32426,
      "name": "Stadtwerke Aalen",
      "isEjn": false
    },
    {
      "pk": 38334,
      "name": "Stadtwerke Amberg, Bäder und Park GmbH",
      "isEjn": false
    },
    {
      "pk": 38744,
      "name": "Stadtwerke Arnstadt",
      "isEjn": false
    },
    {
      "pk": 32320,
      "name": "Stadtwerke Augsburg Energie",
      "isEjn": false
    },
    {
      "pk": 32411,
      "name": "Stadtwerke Bad Harzburg",
      "isEjn": false
    },
    {
      "pk": 32468,
      "name": "Stadtwerke Bad Kreuznach",
      "isEjn": false
    },
    {
      "pk": 32423,
      "name": "Stadtwerke Bad Langensalza",
      "isEjn": false
    },
    {
      "pk": 15858,
      "name": "Stadtwerke Bad Pyrmont",
      "isEjn": false
    },
    {
      "pk": 38741,
      "name": "Stadtwerke Bad Säckingen",
      "isEjn": false
    },
    {
      "pk": 16960,
      "name": "Stadtwerke Balingen",
      "isEjn": false
    },
    {
      "pk": 38752,
      "name": "Stadtwerke Bernau",
      "isEjn": false
    },
    {
      "pk": 32507,
      "name": "Stadtwerke Brilon Energie GmbH",
      "isEjn": false
    },
    {
      "pk": 19212,
      "name": "Stadtwerke Brühl",
      "isEjn": false
    },
    {
      "pk": 16938,
      "name": "Stadtwerke Delmenhorst",
      "isEjn": false
    },
    {
      "pk": 30644,
      "name": "Stadtwerke Dinslaken",
      "isEjn": false
    },
    {
      "pk": 16975,
      "name": "Stadtwerke Dreieich",
      "isEjn": false
    },
    {
      "pk": 342,
      "name": "Stadtwerke Düsseldorf AG",
      "isEjn": false
    },
    {
      "pk": 16999,
      "name": "Stadtwerke Eilenburg",
      "isEjn": false
    },
    {
      "pk": 16997,
      "name": "Stadtwerke Eisenberg Energie",
      "isEjn": false
    },
    {
      "pk": 16989,
      "name": "Stadtwerke Elbtal",
      "isEjn": false
    },
    {
      "pk": 17341,
      "name": "Stadtwerke Emmendingen GmbH",
      "isEjn": false
    },
    {
      "pk": 32449,
      "name": "Stadtwerke Emsdetten",
      "isEjn": false
    },
    {
      "pk": 32463,
      "name": "Stadtwerke Engen",
      "isEjn": false
    },
    {
      "pk": 20234,
      "name": "Stadtwerke Erft GmbH",
      "isEjn": false
    },
    {
      "pk": 15097,
      "name": "Stadtwerke Erkrath",
      "isEjn": false
    },
    {
      "pk": 16978,
      "name": "Stadtwerke EVB Huntetal",
      "isEjn": false
    },
    {
      "pk": 3545,
      "name": "Stadtwerke Finsterwalde",
      "isEjn": false
    },
    {
      "pk": 32440,
      "name": "Stadtwerke Frankfurt Oder",
      "isEjn": false
    },
    {
      "pk": 16926,
      "name": "Stadtwerke Freudenstadt",
      "isEjn": false
    },
    {
      "pk": 19948,
      "name": "Stadtwerke Fürstenfeldbruck",
      "isEjn": false
    },
    {
      "pk": 16939,
      "name": "Stadtwerke Georgsmarienhütte",
      "isEjn": false
    },
    {
      "pk": 31502,
      "name": "Stadtwerke Gronau",
      "isEjn": false
    },
    {
      "pk": 30083,
      "name": "Stadtwerke Haan GmbH",
      "isEjn": false
    },
    {
      "pk": 13682,
      "name": "Stadtwerke Hameln Weserbergland GmbH",
      "isEjn": false
    },
    {
      "pk": 12791,
      "name": "Stadtwerke Hamm GmbH",
      "isEjn": false
    },
    {
      "pk": 32492,
      "name": "Stadtwerke Hannover",
      "isEjn": false
    },
    {
      "pk": 18370,
      "name": "Stadtwerke Heilbad Heiligenstadt",
      "isEjn": false
    },
    {
      "pk": 18664,
      "name": "Stadtwerke Heilbronn",
      "isEjn": false
    },
    {
      "pk": 17346,
      "name": "Stadtwerke Hilden",
      "isEjn": false
    },
    {
      "pk": 20226,
      "name": "Stadtwerke Hockenheim",
      "isEjn": false
    },
    {
      "pk": 36671,
      "name": "Stadtwerke Ingolstadt Beteiligungen",
      "isEjn": false
    },
    {
      "pk": 38774,
      "name": "Stadtwerke Klingenberg",
      "isEjn": false
    },
    {
      "pk": 16955,
      "name": "Stadtwerke Konstanz",
      "isEjn": false
    },
    {
      "pk": 32484,
      "name": "Stadtwerke Kulmbach",
      "isEjn": false
    },
    {
      "pk": 30390,
      "name": "Stadtwerke Kusel GmbH",
      "isEjn": false
    },
    {
      "pk": 23410,
      "name": "Stadtwerke Leinfelden-Echterdingen Vertriebs",
      "isEjn": false
    },
    {
      "pk": 16429,
      "name": "Stadtwerke Lengerich",
      "isEjn": false
    },
    {
      "pk": 954,
      "name": "Stadtwerke Lübeck GmbH",
      "isEjn": false
    },
    {
      "pk": 18661,
      "name": "Stadtwerke Lüdenscheid",
      "isEjn": false
    },
    {
      "pk": 38735,
      "name": "Stadtwerke Meiningen",
      "isEjn": false
    },
    {
      "pk": 17730,
      "name": "Stadtwerke Menden",
      "isEjn": false
    },
    {
      "pk": 18662,
      "name": "Stadtwerke Mosbach",
      "isEjn": false
    },
    {
      "pk": 18670,
      "name": "Stadtwerke Mühlacker",
      "isEjn": false
    },
    {
      "pk": 31153,
      "name": "Stadtwerke Mühlhausen",
      "isEjn": false
    },
    {
      "pk": 35940,
      "name": "Stadtwerke Mühlheim am Main",
      "isEjn": false
    },
    {
      "pk": 16814,
      "name": "Stadtwerke München",
      "isEjn": false
    },
    {
      "pk": 17523,
      "name": "Stadtwerke Neuruppin",
      "isEjn": false
    },
    {
      "pk": 17342,
      "name": "Stadtwerke Neuss Energie und Wasser",
      "isEjn": false
    },
    {
      "pk": 32432,
      "name": "Stadtwerke Neustadt an der Orla",
      "isEjn": false
    },
    {
      "pk": 24106,
      "name": "Stadtwerke Neuwied",
      "isEjn": false
    },
    {
      "pk": 36490,
      "name": "Stadtwerke Niesky",
      "isEjn": false
    },
    {
      "pk": 27659,
      "name": "Stadtwerke Nürtingen",
      "isEjn": false
    },
    {
      "pk": 17345,
      "name": "Stadtwerke Oberkirch",
      "isEjn": false
    },
    {
      "pk": 38776,
      "name": "Stadtwerke Offenbach",
      "isEjn": false
    },
    {
      "pk": 12741,
      "name": "Stadtwerke Olbernhau",
      "isEjn": false
    },
    {
      "pk": 17451,
      "name": "Stadtwerke Olching",
      "isEjn": false
    },
    {
      "pk": 38784,
      "name": "Stadtwerke Oranienburg",
      "isEjn": false
    },
    {
      "pk": 22217,
      "name": "Stadtwerke Pforzheim",
      "isEjn": false
    },
    {
      "pk": 26438,
      "name": "Stadtwerke Pirmasens Versorgungs",
      "isEjn": false
    },
    {
      "pk": 29261,
      "name": "Stadtwerke Radolfzell",
      "isEjn": false
    },
    {
      "pk": 18663,
      "name": "Stadtwerke Rosenheim",
      "isEjn": false
    },
    {
      "pk": 16607,
      "name": "StadtWerke Rösrath - Energie",
      "isEjn": false
    },
    {
      "pk": 31147,
      "name": "Stadtwerke Saalfeld",
      "isEjn": false
    },
    {
      "pk": 16818,
      "name": "Stadtwerke Sangerhausen GmbH",
      "isEjn": false
    },
    {
      "pk": 15855,
      "name": "Stadtwerke Schaumburg-Lippe",
      "isEjn": false
    },
    {
      "pk": 38750,
      "name": "Stadtwerke Schramberg",
      "isEjn": false
    },
    {
      "pk": 31145,
      "name": "Stadtwerke Schüttorf-Emsbüren",
      "isEjn": false
    },
    {
      "pk": 16998,
      "name": "Stadtwerke Schwäbisch Gmünd",
      "isEjn": false
    },
    {
      "pk": 38751,
      "name": "Stadtwerke Schwerin",
      "isEjn": false
    },
    {
      "pk": 30473,
      "name": "Stadtwerke Sondershausen",
      "isEjn": false
    },
    {
      "pk": 18384,
      "name": "Stadtwerke Stade",
      "isEjn": false
    },
    {
      "pk": 29572,
      "name": "Stadtwerke Stadtroda",
      "isEjn": false
    },
    {
      "pk": 19880,
      "name": "Stadtwerke Steinburg GmbH",
      "isEjn": false
    },
    {
      "pk": 18668,
      "name": "Stadtwerke Suhl / Zella-Mehlis",
      "isEjn": false
    },
    {
      "pk": 22169,
      "name": "Stadtwerke Tecklenburger Land",
      "isEjn": false
    },
    {
      "pk": 26120,
      "name": "Stadtwerke Traunstein",
      "isEjn": false
    },
    {
      "pk": 17073,
      "name": "Stadtwerke Ulm/Neu-Ulm Netze",
      "isEjn": false
    },
    {
      "pk": 22062,
      "name": "Stadtwerke Viernheim",
      "isEjn": false
    },
    {
      "pk": 32322,
      "name": "Stadtwerke Voerde GmbH",
      "isEjn": false
    },
    {
      "pk": 26753,
      "name": "Stadtwerke Waldkirch",
      "isEjn": false
    },
    {
      "pk": 35291,
      "name": "Stadtwerke Waldkraiburg",
      "isEjn": false
    },
    {
      "pk": 32407,
      "name": "Stadtwerke Waldshut-Tiengen",
      "isEjn": false
    },
    {
      "pk": 32412,
      "name": "Stadtwerke Weilheim i. OB Energie",
      "isEjn": false
    },
    {
      "pk": 21128,
      "name": "Stadtwerke Weimar Stadtversorgungs",
      "isEjn": false
    },
    {
      "pk": 32603,
      "name": "Stadtwerke Werdau GmbH",
      "isEjn": false
    },
    {
      "pk": 38748,
      "name": "Stadtwerke Werl",
      "isEjn": false
    },
    {
      "pk": 18371,
      "name": "Stadtwerke Wesel",
      "isEjn": false
    },
    {
      "pk": 29053,
      "name": "Stadtwerke Winsen Luhe",
      "isEjn": false
    },
    {
      "pk": 12853,
      "name": "Stadtwerke Zerbst",
      "isEjn": false
    },
    {
      "pk": 38078,
      "name": "STADTWERKE_STEINBURG",
      "isEjn": false
    },
    {
      "pk": 12902,
      "name": "Staffeld&Schumann Immobilien Gbr",
      "isEjn": false
    },
    {
      "pk": 36300,
      "name": "Stainer Schriften u. Siebdruck GmbH & Co KG",
      "isEjn": false
    },
    {
      "pk": 38790,
      "name": "Standard Norge",
      "isEjn": false
    },
    {
      "pk": 41158,
      "name": "Star Charger",
      "isEjn": false
    },
    {
      "pk": 18786,
      "name": "Statdwerke Steinburg GmbH",
      "isEjn": false
    },
    {
      "pk": 32596,
      "name": "Station nicht öffentlich",
      "isEjn": false
    },
    {
      "pk": 20261,
      "name": "Stay-N-Charge",
      "isEjn": false
    },
    {
      "pk": 32640,
      "name": "Stefan Heisterkamp",
      "isEjn": false
    },
    {
      "pk": 13986,
      "name": "Stefan Kreitmayr",
      "isEjn": false
    },
    {
      "pk": 17106,
      "name": "Steffen Horn",
      "isEjn": false
    },
    {
      "pk": 15105,
      "name": "Steiner",
      "isEjn": false
    },
    {
      "pk": 31193,
      "name": "Stella Power",
      "isEjn": false
    },
    {
      "pk": 32511,
      "name": "Stephan Kraus",
      "isEjn": false
    },
    {
      "pk": 13055,
      "name": "Stiftung Schloss Neuhardenberg GmbH",
      "isEjn": false
    },
    {
      "pk": 15357,
      "name": "Stijlhoeve B.V.",
      "isEjn": false
    },
    {
      "pk": 36287,
      "name": "STÖGER+PARTNER Steuerberatung GmbH",
      "isEjn": false
    },
    {
      "pk": 30274,
      "name": "Stokker",
      "isEjn": false
    },
    {
      "pk": 16816,
      "name": "Stokker SIA",
      "isEjn": false
    },
    {
      "pk": 13441,
      "name": "Storch-Ciret Business Services GmbH",
      "isEjn": false
    },
    {
      "pk": 36064,
      "name": "Stranzinger Helmut",
      "isEjn": false
    },
    {
      "pk": 33054,
      "name": "Strom Germering",
      "isEjn": false
    },
    {
      "pk": 12834,
      "name": "Strom Meier GbR",
      "isEjn": false
    },
    {
      "pk": 16110,
      "name": "Stuart Energy",
      "isEjn": false
    },
    {
      "pk": 12748,
      "name": "Stuart Hummel",
      "isEjn": false
    },
    {
      "pk": 36106,
      "name": "Stuart Porter",
      "isEjn": false
    },
    {
      "pk": 27425,
      "name": "Stuibenfall Chalets",
      "isEjn": false
    },
    {
      "pk": 12781,
      "name": "StWL – Städtische Werke Lauf a. d. Pegnitz GmbH",
      "isEjn": false
    },
    {
      "pk": 15480,
      "name": "SUENCO",
      "isEjn": false
    },
    {
      "pk": 38523,
      "name": "SUMIDA_FOLLOWER",
      "isEjn": false
    },
    {
      "pk": 32663,
      "name": "Sumitomo (SHI) Cyclo Drive Germany GmbH",
      "isEjn": false
    },
    {
      "pk": 24738,
      "name": "Summit Seefeld",
      "isEjn": false
    },
    {
      "pk": 1927,
      "name": "Sun Country Highway",
      "isEjn": false
    },
    {
      "pk": 39916,
      "name": "SUN_TWO_CHARGE",
      "isEjn": false
    },
    {
      "pk": 1257,
      "name": "Supercharge",
      "isEjn": false
    },
    {
      "pk": 17308,
      "name": "SureCharge",
      "isEjn": false
    },
    {
      "pk": 12817,
      "name": "SV-DJK Götting e.V.",
      "isEjn": false
    },
    {
      "pk": 12843,
      "name": "Sven Friedrichsdorf",
      "isEjn": false
    },
    {
      "pk": 32479,
      "name": "SVO Vertrieb",
      "isEjn": false
    },
    {
      "pk": 18671,
      "name": "sw-forchheim",
      "isEjn": false
    },
    {
      "pk": 41268,
      "name": "SWARCO Traffic Austria GmbH",
      "isEjn": false
    },
    {
      "pk": 16992,
      "name": "SWE Energie",
      "isEjn": false
    },
    {
      "pk": 36806,
      "name": "Swedbank AS",
      "isEjn": false
    },
    {
      "pk": 12777,
      "name": "SWI",
      "isEjn": false
    },
    {
      "pk": 38012,
      "name": "SWIO LIVE LIFE ELECTRIC",
      "isEjn": false
    },
    {
      "pk": 39497,
      "name": "SWL Energie Oeffentliche",
      "isEjn": false
    },
    {
      "pk": 30343,
      "name": "SY ELECTRIC GmbH",
      "isEjn": false
    },
    {
      "pk": 3379,
      "name": "Syndicat Départemental d’Énergies de la Dordogne",
      "isEjn": false
    },
    {
      "pk": 3450,
      "name": "Syndicat Départemental des Energies de la Creuse (SDEC23)",
      "isEjn": false
    },
    {
      "pk": 3671,
      "name": "Syndicat Mixte Départemental d'Équipement des Communes des Landes (SYDEC)",
      "isEjn": false
    },
    {
      "pk": 38746,
      "name": "Syrstadeng Bil",
      "isEjn": false
    },
    {
      "pk": 33292,
      "name": "TAK_RENEWABLE_ENERGY_UG",
      "isEjn": false
    },
    {
      "pk": 16043,
      "name": "Talma",
      "isEjn": false
    },
    {
      "pk": 32575,
      "name": "Tankstellen-Team vor Ort",
      "isEjn": false
    },
    {
      "pk": 32599,
      "name": "Tauern Golf GmbH",
      "isEjn": false
    },
    {
      "pk": 36298,
      "name": "Tauroa GmbH (Winterstellgut)",
      "isEjn": false
    },
    {
      "pk": 32546,
      "name": "TC-Backend",
      "isEjn": false
    },
    {
      "pk": 38520,
      "name": "TECHNAGON_FOLLOWER",
      "isEjn": false
    },
    {
      "pk": 26573,
      "name": "TECHNAGON_OPERATIONS_FOLLOWER",
      "isEjn": false
    },
    {
      "pk": 16958,
      "name": "Technische Werke Schussental",
      "isEjn": false
    },
    {
      "pk": 36305,
      "name": "Technisches AusbildungsZentrum TAZ Mitterberghütten",
      "isEjn": false
    },
    {
      "pk": 36281,
      "name": "Techno-Z Pongau-Pinzgau-Lungau GmbH",
      "isEjn": false
    },
    {
      "pk": 36400,
      "name": "Techno-Z Urstein GmbH",
      "isEjn": false
    },
    {
      "pk": 32594,
      "name": "Tegelbergbahn GmbH &Co KG",
      "isEjn": false
    },
    {
      "pk": 15854,
      "name": "Tekloth Solar",
      "isEjn": false
    },
    {
      "pk": 32552,
      "name": "Telesys Kommunikationstechnik GmbH",
      "isEjn": false
    },
    {
      "pk": 13045,
      "name": "Telšių rajono savivaldybės administracija",
      "isEjn": false
    },
    {
      "pk": 13936,
      "name": "Terpel Voltex (Columbia)",
      "isEjn": false
    },
    {
      "pk": 27033,
      "name": "Terrassencamping",
      "isEjn": false
    },
    {
      "pk": 3566,
      "name": "Territoire d'Energie Lot-et-Garonne",
      "isEjn": false
    },
    {
      "pk": 3618,
      "name": "Territoire d'Energie Pyrénées-Atlantiques",
      "isEjn": false
    },
    {
      "pk": 51,
      "name": "Tesla",
      "isEjn": false
    },
    {
      "pk": 41520,
      "name": "Tesla Supercharger",
      "isEjn": false
    },
    {
      "pk": 36079,
      "name": "Tessa Thieme/Pötting",
      "isEjn": false
    },
    {
      "pk": 32566,
      "name": "test 2",
      "isEjn": false
    },
    {
      "pk": 37989,
      "name": "Test-Eintrag Templates",
      "isEjn": false
    },
    {
      "pk": 37326,
      "name": "Testeintrag Betreiber",
      "isEjn": false
    },
    {
      "pk": 30862,
      "name": "Teststation",
      "isEjn": false
    },
    {
      "pk": 32811,
      "name": "TEVGO",
      "isEjn": false
    },
    {
      "pk": 36110,
      "name": "TGHZ Betriebs GmbH & Co KG",
      "isEjn": false
    },
    {
      "pk": 2289,
      "name": "The REV Project",
      "isEjn": false
    },
    {
      "pk": 16437,
      "name": "The Solar Charging Company B.V.",
      "isEjn": false
    },
    {
      "pk": 41452,
      "name": "Theris GmbH",
      "isEjn": false
    },
    {
      "pk": 36404,
      "name": "Thermal-Sport-Hotel Bad Gastein Gesmbh",
      "isEjn": false
    },
    {
      "pk": 33493,
      "name": "Thermalhotel Fontana Hotelbetriebsgesellschaft mbH",
      "isEjn": false
    },
    {
      "pk": 19354,
      "name": "Thomas Grundner",
      "isEjn": false
    },
    {
      "pk": 17732,
      "name": "Thomas Huber\nGasthof Zacherlbräu",
      "isEjn": false
    },
    {
      "pk": 32657,
      "name": "Thomas Huber Gasthof Zacherlbräu",
      "isEjn": false
    },
    {
      "pk": 36114,
      "name": "Thomas Krump",
      "isEjn": false
    },
    {
      "pk": 16954,
      "name": "Thüga Energie",
      "isEjn": false
    },
    {
      "pk": 14020,
      "name": "Thundergrid Ltd",
      "isEjn": false
    },
    {
      "pk": 16995,
      "name": "Thurner Immobilien GmbH",
      "isEjn": false
    },
    {
      "pk": 38800,
      "name": "Tillga Glück",
      "isEjn": false
    },
    {
      "pk": 17000,
      "name": "Tillga Glück ",
      "isEjn": false
    },
    {
      "pk": 12896,
      "name": "Timeout Stiftung gGmbH",
      "isEjn": false
    },
    {
      "pk": 35874,
      "name": "Tiroler Bildungsinstitut - Grillhof",
      "isEjn": false
    },
    {
      "pk": 19874,
      "name": "Tirolerhof",
      "isEjn": false
    },
    {
      "pk": 38530,
      "name": "Tischlerei Eckart",
      "isEjn": false
    },
    {
      "pk": 36336,
      "name": "Tischlerei Hannes Dankl",
      "isEjn": false
    },
    {
      "pk": 18378,
      "name": "Tischlerei Maderebner",
      "isEjn": false
    },
    {
      "pk": 15354,
      "name": "Tischlerei Temmler",
      "isEjn": false
    },
    {
      "pk": 20607,
      "name": "Tivak BV",
      "isEjn": false
    },
    {
      "pk": 40324,
      "name": "TIWAG Tiroler Wasserkraft AG",
      "isEjn": false
    },
    {
      "pk": 34205,
      "name": "TLC",
      "isEjn": false
    },
    {
      "pk": 20230,
      "name": "TNK",
      "isEjn": false
    },
    {
      "pk": 790,
      "name": "Toka",
      "isEjn": false
    },
    {
      "pk": 20243,
      "name": "Tora Şarj",
      "isEjn": false
    },
    {
      "pk": 12789,
      "name": "Torsten Ellendt",
      "isEjn": false
    },
    {
      "pk": 32184,
      "name": "Toulouse Métropole",
      "isEjn": false
    },
    {
      "pk": 26261,
      "name": "Tourismus Grossauer",
      "isEjn": false
    },
    {
      "pk": 32523,
      "name": "Tower Market",
      "isEjn": false
    },
    {
      "pk": 32633,
      "name": "Trägerverein Craheim e.V.",
      "isEjn": false
    },
    {
      "pk": 20233,
      "name": "Triveda GmbH",
      "isEjn": false
    },
    {
      "pk": 15028,
      "name": "Trugo",
      "isEjn": false
    },
    {
      "pk": 20272,
      "name": "TUE",
      "isEjn": false
    },
    {
      "pk": 21131,
      "name": "Turmstrom",
      "isEjn": false
    },
    {
      "pk": 23302,
      "name": "TurnOnGreen",
      "isEjn": false
    },
    {
      "pk": 11549,
      "name": "U-Power",
      "isEjn": false
    },
    {
      "pk": 15606,
      "name": "U-POWER",
      "isEjn": false
    },
    {
      "pk": 20786,
      "name": "UBC",
      "isEjn": false
    },
    {
      "pk": 32510,
      "name": "Überlandwerk Leinetal",
      "isEjn": false
    },
    {
      "pk": 31149,
      "name": "ÜE Überlandwerk Erding",
      "isEjn": false
    },
    {
      "pk": 12956,
      "name": "UESA",
      "isEjn": false
    },
    {
      "pk": 13972,
      "name": "UGV Chargers",
      "isEjn": false
    },
    {
      "pk": 19210,
      "name": "Uhlemayr Michael",
      "isEjn": false
    },
    {
      "pk": 17735,
      "name": "Ulrike Heinemann",
      "isEjn": false
    },
    {
      "pk": 39567,
      "name": "Umbrella Solar Investment",
      "isEjn": false
    },
    {
      "pk": 20315,
      "name": "Unco GmbH",
      "isEjn": false
    },
    {
      "pk": 13669,
      "name": "Ungeheuer Automobile GmbH",
      "isEjn": false
    },
    {
      "pk": 36604,
      "name": "UNI ELEKTRO Dortmund",
      "isEjn": false
    },
    {
      "pk": 32591,
      "name": "UNI ELEKTRO Eberswalde",
      "isEjn": false
    },
    {
      "pk": 17001,
      "name": "UNI ELEKTRO Fachgroßhandel",
      "isEjn": false
    },
    {
      "pk": 15893,
      "name": "UNI ELEKTRO Fachgroßhandel GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 32593,
      "name": "UNI ELEKTRO Woltersdorf",
      "isEjn": false
    },
    {
      "pk": 13043,
      "name": "Unipark",
      "isEjn": false
    },
    {
      "pk": 13548,
      "name": "UnitCharge (RU)",
      "isEjn": false
    },
    {
      "pk": 34934,
      "name": "United Company",
      "isEjn": false
    },
    {
      "pk": 1950,
      "name": "Universal EV",
      "isEjn": false
    },
    {
      "pk": 13628,
      "name": "Universal EV Charging",
      "isEjn": false
    },
    {
      "pk": 36024,
      "name": "Universitäts-u. Landessportzentrum Salzburg",
      "isEjn": false
    },
    {
      "pk": 38738,
      "name": "Universitetet i Tromsø",
      "isEjn": false
    },
    {
      "pk": 36311,
      "name": "Unseld Hotel GmbH",
      "isEjn": false
    },
    {
      "pk": 32464,
      "name": "Unterberger Immobilien GmbH",
      "isEjn": false
    },
    {
      "pk": 36065,
      "name": "Unterschwarzach GmbH",
      "isEjn": false
    },
    {
      "pk": 21753,
      "name": "Urbeletric",
      "isEjn": false
    },
    {
      "pk": 36331,
      "name": "Urs Tanner",
      "isEjn": false
    },
    {
      "pk": 32433,
      "name": "Vaggeryds Kommun",
      "isEjn": false
    },
    {
      "pk": 36346,
      "name": "Valamar Obertauern GmbH",
      "isEjn": false
    },
    {
      "pk": 32666,
      "name": "Valdres Energi",
      "isEjn": false
    },
    {
      "pk": 36059,
      "name": "van Halem KG/Pieter van Halem",
      "isEjn": false
    },
    {
      "pk": 36386,
      "name": "Van Leeuwen Jozef und Irene",
      "isEjn": false
    },
    {
      "pk": 38000,
      "name": "Van t hoffstraat",
      "isEjn": false
    },
    {
      "pk": 13047,
      "name": "Varėnos rajono savivaldybės administracija",
      "isEjn": false
    },
    {
      "pk": 2230,
      "name": "Vector",
      "isEjn": false
    },
    {
      "pk": 12831,
      "name": "Veit Stephan",
      "isEjn": false
    },
    {
      "pk": 32626,
      "name": "Vemo",
      "isEjn": false
    },
    {
      "pk": 690,
      "name": "VendElectric",
      "isEjn": false
    },
    {
      "pk": 22170,
      "name": "Vereinigte Stadtwerke",
      "isEjn": false
    },
    {
      "pk": 36118,
      "name": "Vermietgemeinschaft Kehr",
      "isEjn": false
    },
    {
      "pk": 19201,
      "name": "Vermietung & Pension Weglarz",
      "isEjn": false
    },
    {
      "pk": 13308,
      "name": "Veron",
      "isEjn": false
    },
    {
      "pk": 31146,
      "name": "Versorgungsbetriebe Bordesholm Gm",
      "isEjn": false
    },
    {
      "pk": 32469,
      "name": "Versorgungsbetriebe Hoyerswerda",
      "isEjn": false
    },
    {
      "pk": 12963,
      "name": "Verwaltungsgesellschaft Alte Strandvogtei mbH",
      "isEjn": false
    },
    {
      "pk": 15109,
      "name": "VGW Konz",
      "isEjn": false
    },
    {
      "pk": 37400,
      "name": "ViaLynk",
      "isEjn": false
    },
    {
      "pk": 25740,
      "name": "Villa Liesl",
      "isEjn": false
    },
    {
      "pk": 13686,
      "name": "Vils Residenz",
      "isEjn": false
    },
    {
      "pk": 32553,
      "name": "Vinci Facilities",
      "isEjn": false
    },
    {
      "pk": 14753,
      "name": "Vincorion Power Systems GmbH",
      "isEjn": false
    },
    {
      "pk": 37984,
      "name": "Visnet Huizen",
      "isEjn": false
    },
    {
      "pk": 16428,
      "name": "vit",
      "isEjn": false
    },
    {
      "pk": 32581,
      "name": "Vita real estate GmbH",
      "isEjn": false
    },
    {
      "pk": 36125,
      "name": "Vital- & Familienhotel Angerwirt",
      "isEjn": false
    },
    {
      "pk": 32496,
      "name": "VNW",
      "isEjn": false
    },
    {
      "pk": 14484,
      "name": "Volker Döpp",
      "isEjn": false
    },
    {
      "pk": 37470,
      "name": "Volta",
      "isEjn": false
    },
    {
      "pk": 1920,
      "name": "Volta Charging USA",
      "isEjn": false
    },
    {
      "pk": 25139,
      "name": "Voltgo",
      "isEjn": false
    },
    {
      "pk": 30189,
      "name": "Voltiva",
      "isEjn": false
    },
    {
      "pk": 37545,
      "name": "Voltta",
      "isEjn": false
    },
    {
      "pk": 16937,
      "name": "Vonovia Energie Service",
      "isEjn": false
    },
    {
      "pk": 14762,
      "name": "VOOL",
      "isEjn": false
    },
    {
      "pk": 37331,
      "name": "VORNDRAN Metallbau GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 13678,
      "name": "VR Bank",
      "isEjn": false
    },
    {
      "pk": 40361,
      "name": "VR EnergieGenossenschaft Oberbayern Südost eG",
      "isEjn": false
    },
    {
      "pk": 20920,
      "name": "VR-Bank Starnberg-Herrsching-Landsberg eG",
      "isEjn": false
    },
    {
      "pk": 40051,
      "name": "Vuglec breg",
      "isEjn": false
    },
    {
      "pk": 32435,
      "name": "Vulkanlandstrom Kiendler",
      "isEjn": false
    },
    {
      "pk": 15592,
      "name": "Vulletic",
      "isEjn": false
    },
    {
      "pk": 24973,
      "name": "VvE De Pompenburg",
      "isEjn": false
    },
    {
      "pk": 25056,
      "name": "VZX",
      "isEjn": false
    },
    {
      "pk": 36071,
      "name": "W2 Manufaktur GmbH",
      "isEjn": false
    },
    {
      "pk": 41451,
      "name": "Wabitsch Immobilien",
      "isEjn": false
    },
    {
      "pk": 32579,
      "name": "wag",
      "isEjn": false
    },
    {
      "pk": 13268,
      "name": "Wagner Energie",
      "isEjn": false
    },
    {
      "pk": 32669,
      "name": "Wagner GmbH Kaindorf-Süd 77/2 8861 St. Georgen am Kreischberg",
      "isEjn": false
    },
    {
      "pk": 25222,
      "name": "Wagner GmbHKaindorf-Süd 77/28861 St. Georgen am Kreischberg",
      "isEjn": false
    },
    {
      "pk": 32525,
      "name": "Wally's",
      "isEjn": false
    },
    {
      "pk": 20236,
      "name": "Walmart",
      "isEjn": false
    },
    {
      "pk": 12825,
      "name": "Walter AG",
      "isEjn": false
    },
    {
      "pk": 32326,
      "name": "Walter Albert",
      "isEjn": false
    },
    {
      "pk": 20608,
      "name": "Walter Kluxen GmbH",
      "isEjn": false
    },
    {
      "pk": 32650,
      "name": "Walter Kluxen GmbH Mo-Fr 07:00-17:00",
      "isEjn": false
    },
    {
      "pk": 32651,
      "name": "Walter Kluxen GmbH Mo.-Fr. 07:00-17:00",
      "isEjn": false
    },
    {
      "pk": 38785,
      "name": "Walter Schneider",
      "isEjn": false
    },
    {
      "pk": 12890,
      "name": "Walter Werkzeuge Salzburg GmbH",
      "isEjn": false
    },
    {
      "pk": 12778,
      "name": "warendorf.net",
      "isEjn": false
    },
    {
      "pk": 36032,
      "name": "Wasserkraftwerk Urslau",
      "isEjn": false
    },
    {
      "pk": 14950,
      "name": "WASSERVERBAND NORD",
      "isEjn": false
    },
    {
      "pk": 38443,
      "name": "waterrecreatie syperda",
      "isEjn": false
    },
    {
      "pk": 34553,
      "name": "WATT",
      "isEjn": false
    },
    {
      "pk": 25064,
      "name": "WattExtra",
      "isEjn": false
    },
    {
      "pk": 21309,
      "name": "WattHub",
      "isEjn": false
    },
    {
      "pk": 20253,
      "name": "WAVE",
      "isEjn": false
    },
    {
      "pk": 32444,
      "name": "Waybler",
      "isEjn": false
    },
    {
      "pk": 14199,
      "name": "WCG GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 1946,
      "name": "Webasto",
      "isEjn": false
    },
    {
      "pk": 20227,
      "name": "WeCharge",
      "isEjn": false
    },
    {
      "pk": 15029,
      "name": "WeCharge (BR)",
      "isEjn": false
    },
    {
      "pk": 6140,
      "name": "Weev",
      "isEjn": false
    },
    {
      "pk": 15577,
      "name": "WEG",
      "isEjn": false
    },
    {
      "pk": 29014,
      "name": "WEG Hochstraße 830",
      "isEjn": false
    },
    {
      "pk": 20752,
      "name": "WEG Robert-Koch-Straße 25",
      "isEjn": false
    },
    {
      "pk": 36496,
      "name": "WEG TAMSWEG, Sauerfeld 44p.A. Hausverwaltung Oberndorfer GmbH",
      "isEjn": false
    },
    {
      "pk": 32647,
      "name": "WEG- Jeggen - Rokossa GbR Gewerbepark 18 49143 Bissendorf",
      "isEjn": false
    },
    {
      "pk": 36497,
      "name": "Weißsee Gletscherwelt GmbH",
      "isEjn": false
    },
    {
      "pk": 33222,
      "name": "Weko",
      "isEjn": false
    },
    {
      "pk": 31154,
      "name": "WEKO Wohnen Rosenheim GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 38522,
      "name": "WELLNESS_UND_VITALHOTEL_BOEHMHOF",
      "isEjn": false
    },
    {
      "pk": 12961,
      "name": "WEN",
      "isEjn": false
    },
    {
      "pk": 36500,
      "name": "Werner Bernhard",
      "isEjn": false
    },
    {
      "pk": 20297,
      "name": "Westfalen",
      "isEjn": false
    },
    {
      "pk": 6144,
      "name": "Westfalen Weser Netz",
      "isEjn": false
    },
    {
      "pk": 39149,
      "name": "Westfalen Wind Stromk Follower",
      "isEjn": false
    },
    {
      "pk": 32420,
      "name": "WEVG Salzgitter",
      "isEjn": false
    },
    {
      "pk": 34206,
      "name": "Wevolt",
      "isEjn": false
    },
    {
      "pk": 24557,
      "name": "WF Mechanik",
      "isEjn": false
    },
    {
      "pk": 36495,
      "name": "WG Immobilien GmbH & Co.KG",
      "isEjn": false
    },
    {
      "pk": 17003,
      "name": "WHB Metalldesign & Charging GmbH",
      "isEjn": false
    },
    {
      "pk": 38527,
      "name": "Wibmer Robert",
      "isEjn": false
    },
    {
      "pk": 12898,
      "name": "Wiederkehr Pneuhaus AG",
      "isEjn": false
    },
    {
      "pk": 28450,
      "name": "Wiesbacher GmbH",
      "isEjn": false
    },
    {
      "pk": 29124,
      "name": "Wildfreizeitpark Oberreith GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 19915,
      "name": "Wilhelm Rink GmbH & Co. KG",
      "isEjn": false
    },
    {
      "pk": 15176,
      "name": "Wilhelm Schafnitzel",
      "isEjn": false
    },
    {
      "pk": 25494,
      "name": "Willys Vimmerby",
      "isEjn": false
    },
    {
      "pk": 36046,
      "name": "Winkler KG - Hotel Gamshang",
      "isEjn": false
    },
    {
      "pk": 36491,
      "name": "Winterpark Postalm GmbH & Co KG",
      "isEjn": false
    },
    {
      "pk": 40188,
      "name": "Wirelane",
      "isEjn": false
    },
    {
      "pk": 39499,
      "name": "WISAG Elektrotechnik Holding",
      "isEjn": false
    },
    {
      "pk": 17727,
      "name": "Wiskerke Onions BV",
      "isEjn": false
    },
    {
      "pk": 36502,
      "name": "Witting GmbH",
      "isEjn": false
    },
    {
      "pk": 15426,
      "name": "Wittlinger Therapiezentrum GmbH",
      "isEjn": false
    },
    {
      "pk": 36705,
      "name": "Wohleser Immobilien GmbH - Oberndorf bei Salzburg",
      "isEjn": false
    },
    {
      "pk": 14949,
      "name": "Wohneigentümergemeinschaft Seestraße 9a",
      "isEjn": false
    },
    {
      "pk": 32118,
      "name": "Wolf Elektro",
      "isEjn": false
    },
    {
      "pk": 32654,
      "name": "Wolfgang Schmidt OHG Reißiger Gewerbering 15 08525 Plauen",
      "isEjn": false
    },
    {
      "pk": 21237,
      "name": "Wolfgang Schmidt OHGReißiger Gewerbering 1508525 Plauen",
      "isEjn": false
    },
    {
      "pk": 33494,
      "name": "WSA Wohnungswirtschaftliche Treuhand Sachsen-Anhalt GmbH",
      "isEjn": false
    },
    {
      "pk": 36501,
      "name": "Wurnitsch Karin",
      "isEjn": false
    },
    {
      "pk": 20323,
      "name": "Xavaro Immobilien GmbH",
      "isEjn": false
    },
    {
      "pk": 32613,
      "name": "YASNO",
      "isEjn": false
    },
    {
      "pk": 40506,
      "name": "YAWAY Recharge",
      "isEjn": false
    },
    {
      "pk": 15489,
      "name": "YES Energy",
      "isEjn": false
    },
    {
      "pk": 18369,
      "name": "YesChargeme",
      "isEjn": false
    },
    {
      "pk": 15589,
      "name": "ypf",
      "isEjn": false
    },
    {
      "pk": 15469,
      "name": "YSPOT",
      "isEjn": false
    },
    {
      "pk": 2274,
      "name": "Yurika",
      "isEjn": false
    },
    {
      "pk": 41151,
      "name": "Z",
      "isEjn": false
    },
    {
      "pk": 2143,
      "name": "Z Energy",
      "isEjn": false
    },
    {
      "pk": 33565,
      "name": "Zakład Energetyki Cieplnej",
      "isEjn": false
    },
    {
      "pk": 41270,
      "name": "Zalmhuys Smokery",
      "isEjn": false
    },
    {
      "pk": 41271,
      "name": "Zalmhuys Smokery - Parking backside",
      "isEjn": false
    },
    {
      "pk": 36498,
      "name": "Zauchensee Liftgesellschaft Benedikt Scheffer GmbH",
      "isEjn": false
    },
    {
      "pk": 36069,
      "name": "Zauchensee Liftgeselschaft Benedikt Scheffer GmbH",
      "isEjn": false
    },
    {
      "pk": 33393,
      "name": "Zero",
      "isEjn": false
    },
    {
      "pk": 15749,
      "name": "Zero Energy",
      "isEjn": false
    },
    {
      "pk": 1309,
      "name": "ZeroNet",
      "isEjn": false
    },
    {
      "pk": 1748,
      "name": "Zes",
      "isEjn": false
    },
    {
      "pk": 835,
      "name": "Zest",
      "isEjn": false
    },
    {
      "pk": 32604,
      "name": "Zimmermann Vermögensberatung",
      "isEjn": false
    },
    {
      "pk": 26966,
      "name": "Zinn Vermietungs GmbH",
      "isEjn": false
    },
    {
      "pk": 16005,
      "name": "ZIPER",
      "isEjn": false
    },
    {
      "pk": 18161,
      "name": "Zur Post Wellness & Sporthotel GmbH",
      "isEjn": false
    },
    {
      "pk": 12962,
      "name": "Zweckverband Erholungsgebiet Unterbacher See",
      "isEjn": false
    },
    {
      "pk": 19877,
      "name": "ZWO",
      "isEjn": false
    },
    {
      "pk": 36499,
      "name": "Zwölferhornbahn Seilbahn GmbH",
      "isEjn": false
    },
    {
      "pk": 13902,
      "name": "ЭЗС СК",
      "isEjn": false
    }
  ]
}

